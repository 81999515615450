$small: 600px;
$medium: 900px;

.header-filter {
    .filter-data {
        display: flex;
        gap: 12px;
        overflow-x: auto;
        @media only screen and (max-device-width: $small) {
            max-width: 340px;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .filter-text {
            width: auto;
            height: 27px;
            border: 1px solid #323334;
            padding: 5px 16px 5px 16px;
            border-radius: 30px;
            cursor: pointer;

            &.active {
                background-color: #387AFF;

                h4 {
                    color: #ffffff;
                }
            }

            h4 {
                font-family: Inter;
                font-size: 14px;
                font-weight: 300;
                color: #8E8E93;
                font-style: normal;
            }
        }

    }

    .posts-header {
        padding: 20px 0;

        .all-post {
            h1 {
                font-family: Inter;
                font-size: 18px;
                font-weight: 400;
                color: #ffffff;
                // @media only screen and (max-device-width: $small) {
                //     margin-left: 10px;
                // }
            }
        }

        .wrape-posts {
            margin-bottom: 10px;

            .technical-posts {
                // width: 690px;
                // height: 90px;
                background-color: #212225;
                border-radius: 10px;
                padding: 15px;
                margin: 20px 0;
                @media only screen and (max-device-width: $small) {
                    // max-width: 340px;
                    padding: 10px 15px;
                    // margin-left: 10px;
                }

                .main-data {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .wrap-innerData {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        .post-img {
                            width: 50px;
                            height: 50px;
                            position: relative;

                            img {
                                width: 50px;
                                height: 50px;
                                object-fit: cover;
                                border-radius: 6px;
                            }

                            video {
                                width: 50px;
                                height: 50px;
                                object-fit: cover;
                                border-radius: 6px;
                            }

                            .video-overlay {
                                position: absolute;
                                top: 10px;
                                left: 16px;
                            }
                        }

                        .wrap-innerposts {
                            .username {
                                display: flex;
                                align-items: center;
                                gap: 5px;

                                .posted {
                                    h6 {
                                        font-family: Inter;
                                        font-weight: 400;
                                        font-size: 13px;
                                        color: #387AFF;
                                        margin: unset;

                                        @media only screen and (max-device-width: $small) {
                                            font-size: 10px;
                                        }
                                    }
                                }

                                .width {
                                    width: 6px;
                                    height: 6px;
                                    background-color: #8E8E93;
                                    border-radius: 10px;
                                }

                                .posted-by {
                                    p {
                                        font-family: Inter;
                                        font-weight: 400;
                                        font-size: 13px;
                                        color: #8E8E93;

                                        @media only screen and (max-device-width: $small) {
                                            font-size: 8px;
                                        }

                                        strong {
                                            font-family: Inter;
                                            font-weight: 700;
                                            font-size: 13px;
                                            color: #8E8E93;

                                            @media only screen and (max-device-width: $small) {
                                                font-size: 10px;
                                            }
                                        }
                                    }
                                }
                            }

                            .title-inner {
                                h3 {
                                    font-family: Inter;
                                    font-weight: 400;
                                    font-size: 16px;
                                    color: #f5f5f5;
                                    margin: unset;

                                    @media only screen and (max-device-width: $small) {
                                        font-size: 10px;
                                    }
                                }
                            }

                            .replies-count {
                                p {
                                    font-family: Inter;
                                    font-weight: 400;
                                    font-size: 13px;
                                    color: #8E8E93;

                                    @media only screen and (max-device-width: $small) {
                                        font-size: 10px;
                                    }
                                }
                            }
                        }

                    }
                }


            }
        }
    }
}