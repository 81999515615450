$small: 599px;
$tablet: 600px;
$landscapetab: 768px;
$largeTablet: 1023px;

// .row{
//   margin-left: unset !important;
// }

.row {
  @media (min-device-width: $largeTablet) {
    margin-left: unset !important;
  }
}
.landing-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .landing-header {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    @media (max-device-width: $small) {
      text-align: center;
    }
  }

  .show {
    opacity: 1;
  }
}

.content {
  flex: 1;
  background-color: #121313;
  // background-image: linear-gradient(to left, #212225, #121313);

  .hero-section {
    .every-car {
      @media (max-width: $small) {
        align-items: center;
        padding-top: unset;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        align-items: center;
      }

      .title {
        h1 {
          font-size: 48px;
          font-weight: 700;
          font-family: Inter;
          line-height: 58px;

          @media (max-width: $small) {
            text-align: center;
            font-size: 36px;
          }

          @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
            text-align: center;
            font-size: 36px;
          }
        }

        p {
          font-size: 20px;
          font-weight: 300;
          font-family: Inter;

          @media (max-width: $small) {
            text-align: center;
            font-size: 16px;
          }

          @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
            text-align: center;
            font-size: 16px;
          }
        }
      }

      // .watch-ved {
      //     .play-icon {
      //         background-color: #387AFF;
      //         padding: 10px 25px;
      //         font-size: 18px;
      //         font-family: Inter;
      //         font-weight: 500;
      //         border-radius: 10px;
      //         display: flex;
      //         align-items: center;
      //         gap: 5px;
      //     }

      //     // @media (max-width: $small) {
      //     //     // justify-content: center;
      //     // }
      // }
      .video-responsive {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 aspect ratio */
        height: 0;
        overflow: hidden;
        @media (max-width: $landscapetab) {
          // position: unset;
          // padding-bottom: 56.25%;

          // padding: unset;
          height: 100%;
          width: 100%;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @media (max-width: $landscapetab) {
            // position: unset;
            // padding: unset;
          }
        }
      }
    }
  }

  // .modal {
  //     .modal-80 {
  //         width: 70%;
  //         height: 70%;
  //         max-width: 100%;
  //         max-height: 100%;
  //         margin: 0 auto;
  //         display: flex;
  //         justify-content: center;

  //         @media (max-device-width: $small) {
  //             width: 100%;
  //             height: 100%;
  //         }
  //                      @media (max-device-width: $landscapetab) {
  //                          width: 100%;
  //                          height: 100%;
  //                      }

  //         .video-responsive {
  //             position: relative;
  //             padding-bottom: 56.25%;
  //             /* 16:9 aspect ratio */
  //             height: 0;
  //             overflow: hidden;

  //             iframe {
  //                 position: absolute;
  //                 top: 0;
  //                 left: 0;
  //                 width: 100%;
  //                 height: 100%;
  //             }
  //         }

  //         .modal-content {
  //             background: #212225;

  //             .modal-header {
  //                 border-bottom: none;
  //             }

  //             .closeModal {
  //                 position: absolute;
  //                 right: -12px;
  //                 top: -12px;
  //                 z-index: 99;
  //                 border: none;
  //                 background: #000;
  //                 border-radius: 50%;
  //                 width: 32px;
  //                 height: 32px;
  //                 line-height: 0;

  //                 svg {
  //                     width: 12px;
  //                     height: 12px;
  //                 }
  //             }

  //         }
  //     }
  // }

  // .video-responsive {
  //     position: relative;
  //     padding-bottom: 56.25%;
  //     /* 16:9 aspect ratio */
  //     height: 0;
  //     overflow: hidden;

  //     iframe {
  //         position: absolute;
  //         top: 0;
  //         left: 0;
  //         width: 100%;
  //         height: 100%;
  //     }
  // }
}

.slider {
  background-image: linear-gradient(#8e8e931a, #2122251a);

  .slider-communities {
    overflow: hidden;
    width: 100%;
    .active-navLink {
      width: 100%;
      max-width: 1080px;
      background-color: #18191a;
      border-radius: 10px;
      padding: 30px 0 0 0;
      display: flex;
      justify-content: space-evenly;
      filter: drop-shadow(#0a0a0a 10px 5px 10px);

      .icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          @media (max-width: $small) {
            width: 20px;
            height: 20px;
          }
        }

        span {
          font-size: 14px;
          font-family: Inter;
          font-weight: 600;
          @media (max-width: $small) {
            font-size: 12px;
          }
        }
      }
    }
    .slider-wrapper {
      display: flex;
      width: 100%;

      .panel {
        // width: 100vw;
        // height: 100vh;
        position: relative;

        // Ensure panels stay side by side
        flex-shrink: 0;
      }
    }

    .container-share {
      width: 100%;
    }

    .home-post {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        max-width: 517px;
        height: auto;
      }
    }

    .wrap-links {
      // margin-left: -70px;

      @media (max-width: $small) {
        margin-left: unset;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        margin-left: unset;
      }

      .share {
        h3 {
          font-size: 28px;
          font-weight: 600;
          font-family: Inter;

          // line-height: 58px;
          @media (max-width: $small) {
            font-size: 22px;
          }

          @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
            font-size: 22px;
          }
        }

        p {
          font-size: 20px;
          font-weight: 600;
          font-family: Inter;
          color: #8e8e93;

          @media (max-width: $small) {
            font-size: 16px;
          }

          @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.garage-upgrade {
  .Virtual {
    h1 {
      font-size: 48px;
      font-weight: 600;
      font-family: Inter;
      text-align: center;
      padding-top: 80px;
      line-height: unset;

      @media (max-width: $small) {
        font-size: 32px;
        padding-top: 40px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 32px;
        padding-top: 40px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      font-family: Inter;
      text-align: center;

      @media (max-width: $small) {
        font-size: 16px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 16px;
      }
    }
  }

  .Unparalleled {
    .detail {
      h3 {
        font-size: 28px;
        font-weight: 600;
        font-family: Inter;

        @media (max-width: $small) {
          font-size: 22px;
        }

        @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
          font-size: 22px;
        }
      }

      p {
        font-size: 18px;
        font-weight: 400;
        font-family: Inter;

        @media (max-width: $small) {
          font-size: 16px;
        }

        @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
          font-size: 16px;
        }
      }
    }
  }

  .Unprecedented {
    .car-garage {
      position: relative;

      @media (max-width: $small) {
        left: 12px;
        margin-bottom: 15px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        left: 12px;
        margin-bottom: 15px;
      }

      img {
        max-width: 440px;
        width: 100%;

        @media (max-width: $small) {
          max-width: 330px;
        }

        @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
          max-width: 330px;
        }
      }

      .upgrade {
        display: flex;
        justify-content: center;
        margin-top: -100px;

        img {
          max-width: 440px;
          width: 100%;

          @media (max-width: $small) {
            max-width: 330px;
          }

          @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
            max-width: 330px;
          }
        }
      }
    }
  }

  .build-detail {
    .build {
      img {
        max-width: 570px;
        width: 100%;
      }
    }
  }

  .performance {
    h3 {
      font-size: 28px;
      font-weight: 600;
      font-family: Inter;

      @media (max-width: $small) {
        font-size: 22px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 22px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      font-family: Inter;

      @media (max-width: $small) {
        font-size: 16px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 16px;
      }
    }
  }
}

.seamless {
  background-image: linear-gradient(to right, #212225, #121313);

  .updates {
    h1 {
      font-size: 48px;
      font-weight: 600;
      font-family: Inter;
      text-align: center;
      line-height: 58px;
      padding-top: 60px;

      @media (max-width: $small) {
        font-size: 32px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 32px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      font-family: Inter;
      text-align: center;
    }
  }

  .new-comm {
    img {
      margin-left: 50px;

      @media (max-width: $small) {
        margin-left: unset;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        margin-left: unset;
      }
    }
  }

  .comments-img {
    img {
      margin-left: -50px;
      margin-top: -50px;

      @media (max-width: $small) {
        margin-left: unset;
        margin-top: unset;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        margin-left: unset;
        margin-top: unset;
      }
    }
  }

  .text-comments {
    margin-bottom: 50px;
    margin-left: -50px;

    @media (max-width: $small) {
      margin-left: unset;
    }

    @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
      margin-left: unset;
    }

    h4 {
      font-size: 22px;
      font-weight: 600;
      font-family: Inter;
    }

    p {
      font-size: 18px;
      font-weight: 600;
      font-family: Inter;
      color: #8e8e93;

      @media (max-width: $small) {
        font-size: 16px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 16px;
      }
    }
  }
}

.new-communities {
  .communities {
    h1 {
      font-size: 48px;
      font-weight: 600;
      font-family: Inter;
      text-align: center;
      padding-top: 80px;

      @media (max-width: $small) {
        font-size: 32px;
        padding-top: 40px;
        line-height: unset !important;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 32px;
        padding-top: 40px;
        line-height: unset !important;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      font-family: Inter;
      text-align: center;

      @media (max-width: $small) {
        font-size: 16px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 16px;
      }
    }

    .features {
      display: flex;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;

      .text-svg {
        display: flex;
        align-items: center;
        gap: 5px;
        background-color: #212225;
        border-radius: 30px;
        padding: 3px 10px 3px 4px;
        // max-width: 105px;
        // width: 100%;

        p {
          font-size: 16px;
          font-weight: 400;
          font-family: Inter;
        }
      }
    }
  }

  .instant {
    background-color: #18191a;
    padding: 45px;
    border-radius: 16px;

    @media (max-width: $small) {
      padding: 30px;
      // margin: 0 20px;
    }

    @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
      padding: 30px;
    }

    .real-time {
      h1 {
        font-size: 28px;
        font-weight: 600;
        font-family: Inter;
        text-align: center;
        line-height: 33px;

        @media (max-width: $small) {
          font-size: 22px;
        }

        @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
          font-size: 22px;
        }
      }

      p {
        font-size: 18px;
        font-weight: 400;
        font-family: Inter;
        text-align: center;

        // line-height: 58px;
        @media (max-width: $small) {
          font-size: 16px;
        }

        @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
          font-size: 16px;
        }
      }
    }

    .chat-img {
      display: flex;
      justify-content: center;
    }
  }

  .crews-public {
    background-color: #18191a;
    padding: 40px 60px;
    border-radius: 16px;

    @media (max-width: $small) {
      padding: 30px;
      // margin: 0 20px;
    }

    @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
      padding: 30px;
    }

    .public {
      h3 {
        font-size: 28px;
        font-weight: 600;
        font-family: Inter;
        text-align: center;

        @media (max-width: $small) {
          font-size: 22px; // margin: 0 20px;
        }

        @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
          font-size: 22px;
        }
      }

      p {
        font-size: 18px;
        font-weight: 400;
        font-family: Inter;
        text-align: center;

        @media (max-width: $small) {
          font-size: 16px; // margin: 0 20px;
        }

        @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
          font-size: 16px;
        }
      }
    }

    .ride-die {
      display: flex;
      justify-content: center;
      padding-top: 20px;
    }
  }

  .organize {
    background-color: #18191a;
    border-radius: 16px;
    .routes {
      picture {
        display: flex;
        align-items: end;
      }
    }
    .organize-event {
      padding: 0 60px;

      @media (max-width: $small) {
        padding: 30px;
        // margin: 0 20px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        padding: 30px;
      }

      h3 {
        font-size: 28px;
        font-weight: 600;
        font-family: Inter;

        @media (max-width: $small) {
          text-align: center;
        }

        @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
          text-align: center;
        }
      }

      p {
        font-size: 18px;
        font-weight: 400;
        font-family: Inter;

        @media (max-width: $small) {
          text-align: center;
        }

        @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
          text-align: center;
        }
      }
    }
  }
}

.meet-up {
  .meet-events {
    padding-top: 40px;

    h1 {
      font-size: 48px;
      font-weight: 600;
      font-family: Inter;
      text-align: center;

      @media (max-width: $small) {
        font-size: 32px;
        text-align: left;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 32px;
        text-align: left;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      font-family: Inter;
      text-align: center;

      @media (max-width: $small) {
        font-size: 18px;
        text-align: left;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 18px;
        text-align: left;
      }
    }
  }

  .schedule {
    padding: 40px;

    @media (max-width: $small) {
      padding: 20px;
    }

    @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
      padding: 20px;
    }

    h3 {
      font-size: 28px;
      font-weight: 600;
      font-family: Inter;

      @media (max-width: $small) {
        font-size: 22px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 22px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      font-family: Inter;

      @media (max-width: $small) {
        font-size: 16px;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        font-size: 16px;
      }
    }
  }
}
