$small: 599px;
$medium: 600px;
$tablet: 990px;

.Overview {
    .features {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .specs {
            margin: 10px 0;

            h1 {
                font-size: 20px;
                font-weight: 400;
                color: #8E8E93;
                margin: unset;
                @media only screen and (max-device-width: $tablet) {
                    font-size: 16px;
                  }
            }
        }

        .anchor {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            h6 {
                font-size: 15px;
                font-weight: 400;
                color: #387AFF;
                margin: unset;
                @media only screen and (max-device-width: $tablet) {
                    font-size: 12px;
                  }
            }
        }
    }

    .msrp {
        background-color: #18191A;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
            display: flex;
            align-items: center;
            gap: 10px;

            .text {
                p {
                    font-size: 16px;
                    font-weight: 400;
                    font-family: Inter;
                    color: #F5F5F5;
                    @media only screen and (max-device-width: $tablet) {
                        font-size: 12px;
                      }
                }

                .moderator {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    span {
                        font-size: 13px;
                        font-weight: 400;
                        font-family: Inter;
                        color: #8E8E93;
                        @media only screen and (max-device-width: $tablet) {
                            font-size: 11px;
                          }
                    }

                    .online {
                        width: 5px;
                        height: 5px;
                        background-color: #52FF00;
                        border-radius: 10px;
                    }
                }
            }
        }

        .mods-msrp {
            span {
                color: #8E8E93;
                font-size: 12px;
                font-family: Inter;
                font-weight: 400;
            }

            p {
                color: #8E8E93;
                font-size: 14px;
                font-family: Inter;
                font-weight: 400;
            }
        }
    }

    .highlight {
        background-color: #121313;
        margin: 10px 0;
    }

}

.mods {
    margin: 40px 0 40px 0;
    cursor: pointer;

    .heading {
        margin-bottom: 10px;
        border-bottom: 1px solid #323334;

        h1 {
            font-size: 22px;
            font-weight: 300;
            color: #ffffff;
            margin: unset;
            padding-bottom: 8px;
            @media only screen and (max-device-width: $tablet) {
                font-size: 18px;
              }
        }
    }

    .parent-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        @media only screen and (max-device-width: $small) {
            gap: 5px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }

        .categoties {
            background-color: #212225;
            width: 123px;
            height: 70px;
            padding: 10px;
            border-radius: 10px;
            @media only screen and (max-device-width: $tablet) {
                width: auto;
              }

            .inner-categoties {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    font-size: 11px;
                    font-weight: 400;
                    color: #8E8E93;
                    @media only screen and (max-device-width: $tablet) {
                        font-size: 10px;
                      }
                }
            }

            .count {
                h6 {
                    font-size: 20px;
                    font-weight: 400;
                    // color: #387AFF;
                    text-align: left;
                }
            }
        }
    }

}

.select-mods {
    @media only screen and (max-device-width: $small) {
        margin-bottom: 10%;
    }

    .main-heading {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;

        .heading {
            h4 {
                font-size: 18px;
                font-weight: 500;
                color: #F5F5F5;
                margin: unset;
            }
        }
    }

    .wrap-categories {
        max-height: 50vh;
        overflow-y: scroll;
        overflow-x: hidden;

        .inner-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #212225;
            margin-bottom: 10px;
            padding: 10px 0;

            .wrap-mods {

                .image-mods {
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 10px;
                    }

                    video {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 10px;
                    }

                    .engine {
                        p {
                            font-size: 13px;
                            font-weight: 400;
                            // color: #387AFF;
                        }

                        h6 {
                            font-size: 16px;
                            font-weight: 400;
                            color: #F5F5F5;
                            margin: unset;
                        }

                        span {
                            font-size: 11px;
                            font-weight: 400;
                            color: #8E8E93;
                        }
                    }
                }
            }

        }
    }

}