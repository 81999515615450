$small: 599px;
.default-btn-chat {
  background: #387aff;
  color: #fff;
  display: inline-block;
  border-radius: 8px;
  padding: 2px 24px;
  text-decoration: none;
  border: #387aff solid 1px;
  width: 100%;
  max-width: 106px;
  font-size: 15px;
  font-weight: 500;
  font-family: Inter;
  display: flex;
  align-items: center;
  gap: 6px;
  @media only screen and (max-device-width: $small) {
    padding: 3px 18px;

  }
}
