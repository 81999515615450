$small: 480px;
.signup-container {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 15px;

  .signup-wrapper {
    background: #121313;
    max-width: 600px;
    margin: 0 auto;
    flex: 0 0 520px;
    .logo {
      display: block;
      margin: 20px auto;
    }

    .lets-go {
      font-size: 30px;
      font-style: normal;
      font-weight: 300;
      margin-bottom: 20px;
      text-align: center;
    }

    .space10 {
      padding: 20px 0px;
      @media only screen and(max-device-width:$small) {
        padding: 10px 0px;
      }
    }

    .pass-validation {
      display: grid;
      grid-template-columns: 1fr 1fr;
      list-style: inside;

      li {
        color: #8e8e93;
        font-size: 14px;
      }
    }

    .form-group {
      margin-bottom: 15px;
      position: relative;

      .fa-eye {
        color: #8e8e93;
        right: 0;
        cursor: pointer;
        // border-bottom: #8e8e93 solid 1px;
        display: flex;
        align-items: center;
      }

      label {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .form-control {
        border: none;
        background: none;
        color: #fff;
        border-bottom: #8e8e93 solid 1px;
        border-radius: 0;
        outline: none !important;
        box-shadow: none !important;
        padding-left: unset;
        @media only screen and(max-device-width:$small) {
          font-size: 12px;
          padding-left: 3px;
        }

        &::placeholder {
          color: #8e8e93;
        }

        &:-ms-input-placeholder {
          color: #8e8e93;
        }

        &::-ms-input-placeholder {
          color: #8e8e93;
        }

        &:active,
        &:focus {
          border-color: #387aff;
        }
      }
      .country-code {
        span {
          font-size: 12px;
          font-weight: 400;
          font-family: Inter;
          color: #387aff;
          // cursor: pointer;
        }
      }
    }
  }

  .verification-code {
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #8e8e93;
  }

  .next-btn {
    cursor: pointer;
    width: 100%;
    display: block;
    background: #387aff;
    border-radius: 10px;
    // line-height: 45px;
    padding: 10px;
    color: #fff;
    transition: all ease 0.4s;
    border: none;
  }

  // .next-btn:hover {
  //     background: #8E8E93;
  //     color: #fff;
  // }
  button:disabled {
    background: #8e8e93;
    cursor: not-allowed;
  }

  .enter-text {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .back-btn {
    display: flex;
    gap: 5px;
    color: #8e8e93;
    cursor: pointer;
    margin: 10px 0px;
    align-items: center;

    #arrow-down path {
      stroke: #8e8e93;
    }
  }

  .two-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .three-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    .react-datepicker {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 0.8rem;
      background-color: #387aff;

      color: #000;
      border: 0px;
      border-radius: 0;
      display: inline-block;
      position: relative;
    }

    .react-datepicker__header {
      text-align: center;
      background-color: #e56424;
      border-bottom: 0px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-top: 8px;
      position: relative;
    }

    .react-datepicker__today-button {
      background: #3e82cf;
      border-top: 0px;
      cursor: pointer;
      text-align: center;
      font-weight: bold;
      padding: 5px 0;
      clear: left;
    }

    label {
      position: relative;

      span {
        border-radius: 10px;
        border: 1px solid #323334;
        display: block;
        padding: 8px 10px;
        text-align: center;
        @media only screen and(max-device-width:$small) {
          font-size: 12px;
        }
      }

      input {
        position: absolute;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100;
        visibility: hidden;
        opacity: 0;

        &:checked ~ span {
          background: #323334;
        }
      }
    }

    .in-label {
      text-align: center;
      margin-bottom: 8px;
      color: #989999;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
    }

    .fld {
      border-radius: 10px !important;
      border: 1px solid #323334 !important;
      display: block;
      padding: 8px 10px;
      text-align: center;
      background: none;
      color-scheme: white;
      color: #fff !important;
      option {
        background-color: #323334;
      }
    }
  }

  .phone_input {
    width: 93% !important;
    border: none;
    padding: 5px !important;
    margin-left: 40px !important;
  }
  .react-tel-input,
  .flag-dropdown {
    border: unset !important;
  }

  .flag-dropdown,
  .open {
    // display: none;
    border-bottom: #8e8e93 solid 1px !important;
    background-color: transparent !important;
    border-radius: unset !important;
    .selected-flag {
      background-color: #121313 !important;
      &:hover {
        background-color: #121313 !important;
      }
    }
    .country-list,
    .highlight,
    .country {
      background-color: #121313 !important;
      &:hover {
        background-color: #121313 !important;
      }
    }
  }

  .upload-file-input {
    position: relative;
    text-align: center;
    margin: 0 auto;
    max-width: 220px;
    display: block;
    cursor: pointer;

    input {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
    }

    span {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: #387aff;
    }

    .icon {
      width: 110px;
      height: 110px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0 auto;
    }
  }

  .form-control.text-area {
    border: #8e8e93 solid 1px !important;
    height: 120px;
    padding-top: 10px;
    border-radius: 10px !important;
  }

  .ft-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .interests-list {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 10px;

    label {
      position: relative;
      cursor: pointer;

      span {
        border-radius: 50px;
        border: 1px solid #323334;
        display: block;
        padding: 8px 20px;
        text-align: center;
        font-size: 14px;
      }

      input {
        position: absolute;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100;
        visibility: hidden;
        opacity: 0;

        &:checked ~ span {
          background: #387aff;
          color: #fff;
        }
      }
    }
  }

  .follow-accounts {
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 10px;

    label {
      position: relative;
      cursor: pointer;
      width: 100px;

      .follow {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
      }

      .name {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #fff;
        text-align: center;
        margin: 10px 0px;
      }

      input {
        position: absolute;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100;
        visibility: hidden;
        opacity: 0;

        &:checked ~ .follow {
          display: block;
        }
      }
    }

    .account-img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: #60646d;
      overflow: hidden;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }
  }

  .validation-error {
    color: #cc0000;
  }
}
.waitingListWrapper {
  display: flex;
  justify-content: center;
  .waiting-list {
    padding: 35px 15px 15px 15px;
    max-width: 512px;
    background-image: url("../../../assets/WaitlistBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .close-waiting-List {
      width: 100%;
      text-align: end;
      svg {
        cursor: pointer;
      }
    }
    .header-image {
      width: 250px;
      margin: 40px 0px;
    }
    .waiting-list-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .helmetImage {
        width: 150px;
        height: 150x;
        margin-bottom: 40px;
      }
      .waitingListDescription {
        .waitingListHeading {
          text-align: center;
          margin-bottom: 40px;
        }
        .waitingListPara {
          margin-bottom: 40px;
          text-align: center;
        }
      }
      .Queue {
        margin-bottom: 40px;
        color: #387aff;
        .queuePara {
          text-align: center;
        }
        .queue-no {
          text-align: center;
        }
      }
      .footer-para {
        text-align: center;
        color: #8e8e93;
      }
    }
  }
}
.waitingListCompletedWrapper {
  display: flex;
  justify-content: center;
  .waiting-list {
    padding: 15px;
    max-width: 512px;
    background-image: url("../../../assets/WaitlistBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header-image {
      width: 250px;
      margin: 40px 0px;
    }
    .waiting-list-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .helmetImage {
        width: 150px;
        height: 150x;
        margin-bottom: 40px;
      }
      .waitingListDescription {
        .waitingListHeading {
          text-align: center;
          margin-bottom: 40px;
        }
        .waitingListPara {
          margin-bottom: 40px;
          text-align: center;
        }
      }

      .CompleteButton {
        background-color: #387aff;
        color: #fff;
        border-radius: 10px;
        padding: 10px 15px;
        border: none;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .signup-container .signup-wrapper {
    max-width: 100%;
    margin: 0 auto;
    flex: 0 0 100%;
  }

  .signup-container .interests-list,
  .signup-container .follow-accounts {
    grid-template-columns: repeat(2, auto);
    justify-content: center;
  }
}

.eye-icon-confirm {
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 3;
  /* Add a higher z-index value */
}

button:disabled {
  cursor: not-allowed;
}
