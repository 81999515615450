$small: 600px;

.label {
    margin: 4% 0;

    .text-wrapper {
        color: #ffffff;
        font-size: 28px;
        font-weight: 300;
        white-space: nowrap;
    }
}

.crew-accounts {
    display: flex;
    // grid-template-columns: repeat(20, auto);
    column-gap: 20px;
    margin: 20px 10px;
    // width: 100%;
    // overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: none;

    // @media only screen and (max-device-width: $small) {
    //         margin: 0 10px;
    // }

    &::-webkit-scrollbar {
        display: none;
    }

    label {
        position: relative;
        cursor: pointer;
        width: 80px;

        .follow {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
        }

        .name {
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            color: #fff;
            text-align: center;
            margin: 10px 0px;
        }
    }

    .account-img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: #60646D;
        overflow: hidden;

        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
        }
    }
}

.crews {
    margin: 4% 0;
    text-align: center;

    h1 {
        text-align: center;
        font-weight: 700;
        font-size: 36px;
        color: #ffffff;
        margin-bottom: 5px;
    }

    p {
        // text-align: center;

        font-weight: 300;
        font-size: 18px;
        color: #ffffff;
    }


}

.quick-question {
    display: flex;
    // justify-content: space-between;
    gap: 15px;

    .trending {
        background-color: #212225;
        width: auto;
        height: auto;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 6px 12px 6px 6px;
        gap: 12px;
        cursor: pointer;

        span {
            font-size: 14px;
            font-weight: 300;
            color: #ffffff;
        }
    }
}