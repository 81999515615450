.sideBarMain {
    background-color: #121313;
    height: 100%;
    left: -10px;
    position: fixed;
    top: 0;
    transform: translateX(10px);
    transition: transform .3s ease-in-out;
    width: 250px;
    z-index: 1000;

    .profile-links-side {
        left: 0;
        // position: -webkit-sticky;
        padding: 5px 15px;
        position: sticky;
        top: 110px;
    }

    li a {
        align-items: center;
        color: #8e8e93;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        padding: 10px 0;

        img {
            border-radius: 20px;
            margin-right: 10px;
        }
    }

    .active {
        color: #ffffff !important;
        /* Add other styles as needed */
    }
}
