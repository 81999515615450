$small: 599px;
$medium: 600px;
/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
$landscapetab: 801px;

body.modal-open {
    overflow: hidden;
    padding-right: 10px;
}

.overlay {
    background: #18191acf;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 9;
}

.wrapper-modal {
    position: fixed;
    left: 35%;
    top: 30%;
    display: block;
    justify-content: center;
    align-items: center;
    width: 500px;
    border-radius: 10px;
    height: 60%;
    z-index: 1000;
    overflow: hidden;

    @media only screen and (max-device-width: $small) {
        width: 350px;
    }
}

.modal__close {
    border: 0;
    position: absolute;
    line-height: normal;
    right: 10px;
    background: none;
    top: 0;
    color: #404040 !important;
    font-size: 28px;
    z-index: 1;
}

.modal-user-profile {
    right: 15px;
    z-index: 999;
}

.modal-login-profile {
    right: 15px;
    top: 50px;
    z-index: 999;
}

.other-class {
    border: 0;
    position: absolute;
    line-height: normal;
    left: 15px;
    background: none;
    top: 15%;
    color: #404040 !important;
    font-size: 28px;
    z-index: 1;
}

.hidden {
    display: none;
}

@keyframes slide-in {
    0% {
        transform: translateX(-50%) translateY(-20px);
    }

    100% {
        transform: translateX(-50%) translateY(0);
    }
}
// @media (max-width: 995px) {
//     .modal {
//         top: 55% !important;
//         height: 30%;
//         overflow-y: auto; /* Enables vertical scrolling */
//     }
// }
