.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url(../../../assets/authImgs/login-bg.jpg) no-repeat center center;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    opacity: 0.3;
    top: 0;
    width: 100%;
    // background: linear-gradient(180deg,
    //     rgba(4, 5, 6, 0.6) 0%,
    //     rgba(4, 5, 6, 0.8) 57.81%,
    //     #040506 91.67%),
    //   lightgray 50% / cover no-repeat;
    height: 100%;
  }

  .wrapper {
    padding: 30px;
    max-width: 520px;
    flex: 0 0 520px;
    position: relative;
    z-index: 5;
    text-align: center;
  }

  .find-text {
    color: #8e8e93;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }

  .logo {
    width: 350px;
    height: 50px;
    margin-bottom: 30px;
  }

  .form-control {
    background: #323334;
    height: 44px;
    border: none;
    color: #fff;
  }
  .field-inp {
    background-color: transparent !important;
    height: 44px;
    border: none;
    color: #fff;
  }

  .forgot {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    color: #387aff;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    margin-bottom: 30px;
  }

  .login-btn {
    width: 100%;
    display: block;
    background: #387aff;
    border-radius: 10px;
    line-height: 45px;
    border: none;
    color: #fff;
    transition: all ease 0.4s;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #8e8e93;
    //   color: #fff;
    // }
  }

  .or {
    color: #8e8e93;
    margin: 15px 0px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      background: #3c3c43;
      height: 1px;
      width: 42%;
      right: 0;
      top: 12px;
    }

    &::before {
      content: "";
      position: absolute;
      background: #3c3c43;
      height: 1px;
      width: 42%;
      left: 0;
      top: 12px;
    }
  }

  .signup-options {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    li {
      background: #1f2022;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 5px;
      border-radius: 50%;
      cursor: pointer;
      transition: all ease 0.3s;

      svg {
        fill: #8e8e93;
      }

      &:hover {
        background: #387aff;
        transform: translateY(-5px);

        svg {
          fill: #fff;
        }
      }
    }
  }

  .terms-text {
    text-align: center;
    color: #8e8e93;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    margin-top: 10px;

    a {
      color: #387aff;
      font-weight: 700;
    }
  }

  .signup-text {
    text-align: center;
    color: #929292;

    a {
      color: #387aff;
      font-weight: 700;
    }
  }
}

.error-message {
  text-align: left;
  margin-top: 0.5rem;
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.position {
  position: relative;
}

.eye-lash {
  position: absolute;
  right: 0;
  bottom: 7px;
  cursor: pointer;
}
.resetModal{
  
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  padding: 50px 0px;
  background-color: #121313;



  .wrapper {
    width: 298px;
    max-width: 520px;
    flex: 0 0 520px;
    position: relative;
    z-index: 5;
    text-align: center;
    .reset-sec{
      font-size: larger;
    }
    .shaded-text{
      font-size: small;
      color: #929292;
    }
  }


  .login-btn {
    width: 100%;
    display: block;
    background: #387aff;
    border-radius: 10px;
    line-height: 45px;
    border: none;
    color: #fff;
    transition: all ease 0.4s;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #8e8e93;
    //   color: #fff;
    // }
  }
  .form-control {
    background: #323334;
    height: 44px;
    border: none;
    color: #fff;
  }


}
.OtpModal{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  padding: 50px 0px;
  background-color: #121313;



  .wrapper {
    width: 298px;
    max-width: 520px;
    flex: 0 0 520px;
    position: relative;
    z-index: 5;
    text-align: center;
    .reset-sec{
      font-size: larger;
    }
    .shaded-text{
      font-size: small;
      color: #929292;
    }
  }


  .login-btn {
    width: 100%;
    display: block;
    background: #387aff;
    border-radius: 10px;
    line-height: 45px;
    border: none;
    color: #fff;
    transition: all ease 0.4s;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #8e8e93;
    //   color: #fff;
    // }
  }
  .form-control {
    background: #323334;
    height: 44px;
    border: none;
    color: #fff;
  }
}

.confirmPasswordModal{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  padding: 50px 0px;
  background-color: #121313;



  .wrapper {
    width: 298px;
    max-width: 520px;
    flex: 0 0 520px;
    position: relative;
    z-index: 5;
    text-align: center;
    .reset-sec{
      font-size: larger;
    }
    .shaded-text{
      font-size: small;
      color: #929292;
    }
  }


  .login-btn {
    width: 100%;
    display: block;
    background: #387aff;
    border-radius: 10px;
    line-height: 45px;
    border: none;
    color: #fff;
    transition: all ease 0.4s;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #8e8e93;
    //   color: #fff;
    // }
  }
  .form-control {
    background: #323334;
    height: 44px;
    border: none;
    color: #fff;
  }
}