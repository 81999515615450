.status-indicator {
    width: 5px;
    height: 5px;
    border-radius: 10px;
  
    &.online {
      background-color: #52FF00;
    }
  
    &.offline {
      background-color: #8E8E93;
    }
  }
  