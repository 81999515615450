.crews-builds {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 60px;

    .crews-block {
        background: url(./../../assets/banner-bg.jpg) no-repeat center center;
        background-size: cover;
        height: 427px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        padding: 20px;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.7;
            transition: all ease 0.4s;
        }

        .info {
            position: relative;
            z-index: 1;
            text-align: center;
            max-width: 400px;
            margin: 0 auto;

            h3 {
                font-weight: 700;
                font-size: 42px;
                line-height: 40px;
                letter-spacing: 0.374px;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                margin: 20px 0px;
            }

            a {
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                justify-content: center;
                &:hover{
                    color: #fff;
                }
            }
        }

        &:hover {
            &::after {
                background: #000;
                opacity: 0.9;
            }
        }
    }
}