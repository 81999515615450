$tablet: 992px;
$mobile: 599px;

// .searchResults-container {
//   @media only screen and(max-device-width:$mobile) {
//     grid-column: 1;
//   }
// }
.search-form-explore {
  width: 100%;
  position: relative;

  .search-fld {
    border: 1px solid #323334;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #212225;
    height: 100%;
    max-height: 40px;

    .form-control {
      background: none;
      border: none;
      // height: 36px;
      padding-left: 15px;
      color: #fff;

      &:focus {
        box-shadow: none;
      }

      // @media only screen and(max-device-width:$tablet) {
      //   width: 100px;
      // }
    }
    .custom-search{
       @media only screen and(max-device-width:650px) {
       font-size: 0.75rem;
      }
    }

    button {
      height: 36px;
      line-height: 36px;
      background: none;
      border: none;
      padding: 0px 10px;
      border-left: 1px #323334 solid;
      border-radius: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: unset;

      &:hover {
        background: none;
      }
    }
  }

  .search-dropdown {
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    padding: 10px 0px;
    background: #212225;
    overflow-x: auto;
    max-height: 300px;
    z-index: 999999;

    .spliter {
      border-bottom: #323334 solid 1px;
      margin: 5px 0px;
      width: 100%;
    }

    .result-loop {
      img {
        @media only screen and (max-device-width: 600px) {
          width: 15px;
        }
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      padding: 5px 15px;
      cursor: pointer;

      .search-name {
        display: flex;
        gap: 15px;
        // img{
        //     @media only screen and (max-device-width: 600px) {
        //         width: 15px;
        //       }
        // }
      }

      .arrow-svg {
        margin-left: auto;
      }

      p {
        display: flex;
        align-items: center;
        gap: 20px;

        &.light-color {
          color: #ddd;
        }

        span.tag {
          background: #387aff;
          color: #fff;
          padding: 4px 15px;
          border-radius: 40px;
          font-size: 14px;

          @media only screen and (max-device-width: 600px) {
            padding: 4px 10px;
            font-size: 10px !important;
          }
        }

        @media only screen and (max-device-width: 600px) {
          font-size: 10px !important;
        }
      }
    }
  }
}

.serachResults-container {
  display: grid;
  grid-template-columns: 15% 55% calc(30% - 75px);
  // gap: 30px;
  max-width: 1280px;
  margin: 0 auto;

  @media only screen and (max-device-width: $mobile) {
    grid-template-columns: 1fr;
  }

  &>div:first-child {
    border-right: 1px solid #323334;
  }

  .profile-links-side li a {
    display: flex;
    align-items: center;
    color: #8e8e93;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    padding: 10px 0;

    img {
      border-radius: 20px;
      margin-right: 10px;
    }
  }
}

.full-search {
  padding-top: 30px;

  .search-form {
    max-width: 100%;
  }

  .search-form .search-fld .form-control {
    height: 40px;
  }

  .search-form .search-fld {
    background: #1e1e1f;
    border-color: #8e8e93;

    .form-control {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .search-form .search-fld button {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      height: 26px;
      width: 1px;
      background: #50555c;
    }
  }

  .search-cate {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;

    .active-category {
      border-radius: 40px;
      border: #8e8e93 solid 1px;
      color: #fff;
      font-size: 12px;
      padding: 5px 16px;
      cursor: pointer;

      &.active {
        background: #387aff;
        border-color: #387aff;
        color: #fff;
      }
    }
  }

  .active-tag {
    position: absolute;
    right: 60px;
    display: flex;
    align-items: center;
    background: #387aff;
    border-color: #387aff;
    color: #fff;
    font-size: 12px;
    padding: 5px 12px;
    cursor: pointer;
    border-radius: 40px;
    gap: 5px;
    top: 5px;
     @media only screen and(max-device-width:650px) {
        top: 2px;
      }
  }
}

.UserName {
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #f5f5f5;
  letter-spacing: 0.374px;
  font-weight: 300;
  font-size: 16px;
}

.UserLocation {
  color: #8e8e93;
  letter-spacing: 0.374px;
  font-weight: 300;
  font-size: 16px;
}

.Builds-car {
  padding: 25px;
}

.garage-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
  gap: 8px;
  margin-bottom: 2%;

  @media only screen and (max-device-width: $tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-device-width: $mobile) {
    grid-template-columns: 1fr 1fr;
  }

  .garage-block {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    color: #f5f5f5;

    .stars {
      margin-bottom: auto;

      i {
        filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.25));
        font-size: 18px;

        @media only screen and (max-device-width: 600px) {
          font-size: 12px;
        }
      }
    }

    .model {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      margin-bottom: 10px;

      @media only screen and (max-device-width: $mobile) {
        margin-bottom: 0;
      }
    }

    .name {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 10px;

      @media only screen and (max-device-width: $mobile) {
        font-size: 12px !important;
        margin-bottom: 0;
      }
    }

    .current {
      background: #212225;
      border-radius: 40px;
      color: #fff;
      display: inline-block;
      font-size: 13px;
      line-height: 10px;
      margin-bottom: 10px;
      margin-right: auto;
      padding: 10px 32px;
      width: 90px;
    }

    .previous {
      background: #e56424;
      border-radius: 40px;
      color: #fff;
      font-size: 13px;
      // line-height: 10px;
      padding: 4px 6px;
      display: inline-block;
      margin-bottom: 10px;
      // margin-right: auto;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(18.24deg,
          rgba(4, 5, 6, 0.8) 22.97%,
          rgba(4, 5, 6, 0) 71.94%);
      width: 100%;
      height: 50%;
    }

    img {
      width: 100%;
      height: 180px;
      object-fit: cover;
      transition: all ease 0.4s;
      border-radius: 8px;
    }

    .info {
      position: absolute;
      z-index: 2;
      bottom: 0;
      padding: 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @media only screen and (max-device-width: $mobile) {
        padding: 10px;
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  &.garage-row-two-clm {
    grid-template-columns: 1fr 1fr;
  }
}

.view-all-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 30px;

  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;

    @media only screen and (max-device-width: $mobile) {
      font-size: 20px !important;
    }
  }

  a {
    color: #387aff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;

    &:hover {
      color: #fff;

      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
}

.crew-list-content-search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 45px;

  @media only screen and (max-device-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-device-width: $mobile) {
    grid-template-columns: 1fr;
  }

  h1 {
    font-size: 30px;
    text-align: center;
  }

  .button-confirmation {
    display: flex;
    justify-content: space-around;
    padding-top: 5%;

    .No {
      button {
        width: 200px;
        height: 40px;
        background: #323334;
        color: #fff;
        border: 1px solid #8e8e93;
        border-radius: 10px;
      }
    }

    .Yes {
      button {
        width: 200px;
        height: 40px;
        background: #dc3545;
        color: #fff;
        border: 1px solid #8e8e93;
        border-radius: 10px;
      }
    }
  }

  .list-block {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 12px 0px;
    border-bottom: 1px solid #212225;
    margin-bottom: 0px;
    @media only screen and (max-device-width: 1200px) {
      gap: 10px;
    }
    img {
      border-radius: 10px;
      border: 1px solid #323334;
      width: 65px;
      height: 65px;
      object-fit: cover;
      @media only screen and (max-device-width: 1200px) {
        width: 45px;
        height: 45px;
      }
      
    }

    .default-btn {
      border-radius: 6px;
      font-size: 13px;
      padding: 6px 20px;
      transition: all ease 0.4s;
      margin-left: auto;
      @media only screen and (max-device-width: 1200px) {
        font-size: 10px;
        padding: 4px 10px;
      }
    }

    .list-info {
      h4 {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0;
        display: flex;
        gap: 10px;
        align-items: self-start;
        @media only screen and (max-device-width: 1200px) {
          font-size: 12px;
          gap: 5px;
        }
      }

      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        @media only screen and (max-device-width: 1200px) {
          font-size: 10px;
        }
      }
    }

    &.user-list-block {
      img {
        border-radius: 50px;
        border: 1px solid #323334;
        width: 65px;
        height: 65px;
        object-fit: cover;
      }

      .list-info {
        p {
          color: #8e8e93;
        }
      }
    }
  }
}

.Trendingparts {
  padding-bottom: 60px;

  h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 30px;
  }

  .trending-part-block {
    margin-bottom: 20px;

    a {
      color: #fff;

      &:hover {
        color: #387aff;
      }
    }

    .post-title-trending {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      opacity: 0.9;
      margin-bottom: 10px;
    }

    .post-short-info {
      padding: 10px 0px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .main-img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 0px;
    }

    .vehicle-avtar {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      .img-holders {
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid #8e8e93;
          position: relative;
          z-index: 2;

          &:last-child {
            margin-left: -20px;
            z-index: 1;
          }
        }
      }

      .name-info {
        .name {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }

        p {
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          color: #8e8e93;
        }
      }
    }
  }

  .trending-post-btns {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;

    &>div {
      display: flex;
      gap: 6px;
    }
  }

  .comments-info {
    display: flex;
    align-items: center;

    .green-text {
      color: #52ff00;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14.4px;
    }

    .blue-text {
      color: #387aff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14.4px;
    }

    .time {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: #8e8e93;
      position: relative;
      padding-left: 10px;
      margin-left: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -0px;
        top: 50%;
        transform: translateY(-50%);
        background: #8e8e93;
        border-radius: 50%;
        width: 5px;
        height: 5px;
      }
    }
  }
}

.search-filters {
  position: sticky;
  right: 0;
  top: 30px;

  h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    padding-top: 30px;
    margin: 0px 0px 10px;
  }

  #accordion {
    h2 {
      margin: 0px;

      button {
        font-size: 18px;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        border-bottom: #212225 solid 1px;
        width: 100%;
        background: none;

        i {
          font-size: 18px;
        }
      }
    }
  }

  #accordion-sub {
    h2 {
      button {
        font-size: 14px;
        color: #fff;
        font-style: normal;
        font-weight: 400;
        display: flex;
        justify-content: start;
        gap: 5px;
        align-items: center;
        border: none;
        border-bottom: #212225 solid 1px;
        width: 100%;
        background: none;

        i {
          font-size: 12px;
        }
      }
    }
  }

  .make-checkbox {
    position: relative;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    display: block;
    padding-left: 20px;
    margin: 5px 0px;
    cursor: pointer;

    input {
      visibility: hidden;
      position: absolute;
      left: 0;
      opacity: 0;
    }

    span {
      border-radius: 4px;
      border: 1px solid #8e8e93;
      background: #212225;
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      top: 5px;

      i {
        position: absolute;
        font-size: 8px;
        left: 2px;
        top: 1px;
        display: none;
      }
    }

    input:checked+span {
      background: #387aff;
      border-color: #387aff;

      i {
        display: block;
      }
    }
  }
}

.Tabs {
  gap: 10px;
  display: flex;
  margin-top: 8px;

  span {
    border: 1px solid rgba(80, 85, 92, 0.6);
    border-radius: 20px;
    padding: 5px 16px;
    font-size: 12px;
    line-height: 14px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 10px;
  }
}

.BuildFilters {
  border-left: 1px solid #323334;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 599px) {
  .crew-list-content-search {
    h1 {
      font-size: large;
    }

    .list-block {
      img {
        width: 45px !important;
        height: 45px !important;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 600px) and (max-width: 991px) {
  .crew-list-content-search {
    h1 {
      font-size: large;
    }

    .list-block {
      img {
        width: 45px !important;
        height: 45px !important;
      }
    }
  }
}