$tablet:992px;
$mobile:700px;
.banner-search-explore {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    .search-form {
        .search-fld {
            border: 1px solid #8E8E93;
            background: none;
            input {
                width: 460px;
                height: 40px;
                @media only screen and (max-device-width: $tablet) {
                    width: 250px;
                    height: 40px;
                }
            }
           
        }
    }
    .search-filter{
        width: 155px;
        // @media only screen and (max-device-width: $tablet) {
        //     width: 120px;
        // }
    }
    @media only screen and (max-device-width: $mobile) {
        flex-direction: column;
    }
}

.search-form {
    max-width: 370px;
    position: relative;

    .search-fld {
        border: 1px solid #323334;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #212225;
        height: 100%;
        max-height: 40px;
        .form-control {
            background: none;
            border: none;
            // height: 36px;
            padding-left: 15px;
            color: #fff;
            &:focus{
                box-shadow: none;
            }
            //  @media only screen and(max-device-width:$tablet){
            //         width: 100px;
            //     }
            
        }

        button {
            height: 36px;
            line-height: 36px;
            background: none;
            border: none;
            padding: 0px 10px;
            border-left: 1px #323334 solid;
            border-radius: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: unset;
            &:hover{
                background: none;
            }
          
        }
    }

    .search-dropdown {
        position: absolute;
        left: 0;
        top: 40px;
        width: 100%;
        padding: 10px 0px;
        background: #212225;
        overflow-x: auto;
        max-height: 300px;
        z-index: 999999;
        .spliter {
            border-bottom: #323334 solid 1px;
            margin: 5px 0px;
            width: 100%;
        }

        .result-loop {
            img{
                @media only screen and (max-device-width: 600px) {
                    width: 15px;
                  }
            }
            display: flex;
            align-items: center;
            justify-content: space-between  ;
            gap: 15px;
            padding: 5px 15px;
            cursor: pointer;
            .search-name{
                display: flex;
                gap: 15px;
                // img{
                //     @media only screen and (max-device-width: 600px) {
                //         width: 15px;
                //       }
                // }
            }

            .arrow-svg {
                margin-left: auto;
            }



            p {
                display: flex;
                align-items: center;
                gap: 20px;

                &.light-color {
                    color: #ddd;
                    ;
                }

                span.tag {
                    background: #387AFF;
                    color: #fff;
                    padding: 4px 15px;
                    border-radius: 40px;
                    font-size: 14px;
                    @media only screen and (max-device-width: 600px) {
                        padding: 4px 10px;
                        font-size: 10px !important;
                      }
                }
                @media only screen and (max-device-width: 600px) {
                    font-size: 10px !important;
                  }
            }
        }
    }
}
.explore-search-name{
gap: 10px;
.search-filter{
    .default-select{
        border: 1px solid #8E8E93;
        color: #8E8E93;
        box-shadow: none;
        background: none;
        height: 40px;
    }
    
}
.search-btn-explore{
    .default-btn {
        background: #387aff;
        color: #fff;
        display: inline-block;
        border-radius: 5px;
        padding: 7.5px 30px;
        text-decoration: none;
        border: #387aff solid 1px;
         width: 100% ;
         max-width: 125px;
        @media only screen and (max-device-width: $tablet) {
            padding: 8px 12px ;
            width: 80px ;
        }
    }
    
    .default-btn:hover {
        background-color: #000;
        color: #fff;
    }
}
}

// input[type="search"]::-webkit-search-decoration:hover,
// input[type="search"]::-webkit-search-cancel-button:hover {
//     cursor: pointer;
// }