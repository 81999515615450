$small: 599px;
$medium: 600px;
$tablet: 990px;

.garage-container {
  display: grid;
  grid-template-columns: 15% 85%;

  @media only screen and (max-device-width: $small) {
    grid-template-columns: 100%;
  }

  // gap: 30px;
  max-width: 1280px;
  margin: 0 auto;

  & > div:first-child {
    border-right: 1px solid #323334;

    @media only screen and (max-device-width: $small) {
      border: none;
    }
  }
  .profile-links-side li a {
    display: flex;
    align-items: center;
    color: #8e8e93;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    padding: 10px 0;
    img{
      border-radius: 20px;
      margin-right: 10px;
    }
  }
  .profile-links-side {
    padding: 5px 15px;
    position: sticky;
    top: 110px;
    left: 0;
}

  .wrap-container {
    position: relative;
    bottom: 60px;
    padding: 0 45px;

    @media only screen and (max-device-width: $small) {
      bottom: 0;
      padding: unset;
    }
  }

  .top-post {
    text-align: center;
    margin-top: 10px;

    h6 {
      font-size: 14px;
      font-weight: 400;
      color: #f5f5f5;
      margin: unset;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      color: #387aff;
    }
  }

  .related-post {
    display: flex;
    align-items: center;
  }

  .car-banner {
    background-color: #18191a;
    padding: 5px 30px;
    border-radius: 10px;
    border-bottom: 2px solid #323334;

    @media only screen and (max-device-width: $small) {
      padding: 5px 10px;
      margin: -10px 12px;
    }

    .rating-wrapper-btns {
      .owner-ship {
        background-color: #212225;
        border-radius: 30px;
        padding: 3px 10px 3px 4px;
        display: flex;
        align-items: center;
        gap: 10px;

        .cuurently-legacy {
          width: 17px;
          height: 17px;
          border-radius: 30px;
        }

        .text {
          p {
            color: #f5f5f5;
            font-size: 13px;
            font-weight: 400;
            font-family: Inter;
            @media only screen and (max-device-width: $tablet) {
              font-size: 10px;
              font-weight: 100;
            }
          }
        }
      }
    }

    .build-name {
      .name {
        position: relative;
        margin: 10px 5px;
        @media only screen and (max-device-width: $tablet) {
          font-size: 16px;
          font-weight: 300;
        }
        sup{
          position: absolute;
          top: 0;
        }
      }

      .verify {
        position: absolute;
        top: -15px;
        right: 0;
      }
    }

    .tags {
      margin: unset;
    }

    .sub-section {
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media only screen and (max-device-width: $small) {
        justify-content: space-between;
      }

      .anchor-links {
        h6 {
          // margin: unset;
          color: #8e8e93;
          font-size: 13px;
          font-weight: 400;
          font-family: Inter;
          cursor: pointer;
        }
      }
    }
  }

  .heading-featured {
    padding: 10px;

    h1 {
      color: #8e8e93;
      font-size: 20px;
      font-weight: 400;
      font-family: Inter;
    }
  }

  .featured-gallery {
    img {
      object-fit: cover;
      height: 340px;
      display: block;

      @media only screen and (max-device-width: $small) {
        height: 200px;
      }
    }
  }

  .cars {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .gallery {
      img {
        object-fit: cover;

        @media only screen and (max-device-width: $small) {
          height: 95px;
        }
      }
    }

    .gallery-overlay {
      position: relative;

      img {
        object-fit: cover;
        height: 165px;

        @media only screen and (max-device-width: $small) {
          height: 95px;
        }
      }

      .overlay-last {
        position: absolute;
        bottom: 0;
        // background: #387AFF;
        width: 100%;
        transition: 0.5s ease;
        opacity: 0;
        color: #f5f5f5;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        height: 165px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @media only screen and (max-device-width: $small) {
          height: 95px;
        }
      }

      &.gallery-overlay .overlay-last {
        opacity: 0.9;
      }
    }
  }

  .extra-info {
    .summary {
      border-bottom: 1px solid #323334;
      padding-bottom: 5px;

      h1 {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
        font-family: Inter;
        margin: unset !important;
        @media only screen and (max-device-width: $tablet) {
          font-size: 18px;
        }
      }
    }

    .specs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .hp {
        h1 {
          font-size: 20px;
          font-weight: 400;
          color: #8e8e93;
          font-family: Inter;
          @media only screen and (max-device-width: $tablet) {
            font-size: 16px;
          }
        }
      }

      .modified {
        display: flex;
        align-items: center;
        gap: 5px;

        .online {
          width: 5px;
          height: 5px;
          background-color: #52ff00;
          border-radius: 10px;
        }

        .text {
          color: #f5f5f5;
          font-size: 13px;
          font-weight: 400;
          font-family: Inter;
        }
      }
    }

    .wrap-specs {
      background-color: #212225;
      border-radius: 10px;
      padding: 10px;

      .info-block {
        .wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .icon {
            display: flex;
            align-items: center;
            gap: 4px;

            p {
              font-size: 11px;
              color: #f5f5f5;
              font-weight: 400;
              font-family: Inter;
            }
          }

          .online {
            width: 5px;
            height: 5px;
            background-color: #52ff00;
            border-radius: 10px;
          }
        }

        .hp {
          // border-bottom: 1px solid #323334;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 18px;
            font-weight: 500;
            font-family: Inter;
            color: #f5f5f5;
            @media only screen and (max-device-width: $tablet) {
              font-size: 15px;
            }

            span {
              font-size: 13px;
              font-weight: 400;
              font-family: Inter;
              color: #f5f5f5;
            }
          }

          h6 {
            color: #f5f5f5;
            font-size: 11px;
            font-weight: 400;
            font-family: Inter;
            margin: unset;
          }
        }

        .moderator {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 13px;
            font-weight: 400;
            font-family: Inter;
            color: #8e8e93;
          }

          h6 {
            color: #8e8e93;
            font-size: 11px;
            font-weight: 400;
            font-family: Inter;
            margin: unset;
          }
        }
      }
    }
  }

  .posts {
    h2 {
      font-size: 22px;
      border-bottom: 1px solid #323334;
    }
  }

  .user-profile {
    .sticky-clm-2 {
      .user-detail-wrapper {
        margin: unset;
        border-radius: 10px;
      }
    }
  }
}

.sticky-clm .user-detail-wrapper-main {
  margin-top: 0;
  padding: 30px 0px 0px 0px;
}

.profile-links li a img {
  width: 25px;
  height: 25px;
  object-fit: cover;
}

.profile-links li a svg {
  width: 25px;
  height: 25px;
  object-fit: cover;
}
