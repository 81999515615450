.multiple-users {
  display: inline-flex;
  position: relative;

  .avatar {
    position: relative;
  }

  .img {
    position: relative;
  }

  .member-count {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;

    .text-wrapper {
      color: #ffffff;
      font-family: "Inter-Regular", Helvetica;
      font-weight: 400;
      letter-spacing: -0.41px;
      line-height: 22px;
      margin-top: -1px;
      position: relative;
      text-align: center;
      white-space: nowrap;
      width: fit-content;
    }
  }

  &.user-count {
    align-items: center;

    .avatar {
      height: 40px;
      width: 40px;
    }

    .img {
      height: 40px;
      margin-left: -13px;
      width: 40px;
    }

    .member-count {
      margin-left: -13px;
      padding: 0px 0px 0px 20px;

      .text-wrapper {
        font-size: 16px;
      }
    }
  }

  &.user-count-sm {
    align-items: flex-start;

    .avatar {
      height: 20px;
      width: 20px;
      border-radius: 12px;
    }

    .img {
      height: 20px;
      // margin-left: -8px;
      width: 20px;
      border-radius: 12px;
    }

    .member-count {
      margin-left: -8px;
      padding: 0px 0px 0px 12px;

      .text-wrapper {
        font-size: 14px;
      }
    }
  }

  &.default {
    align-items: center;

    .avatar {
      height: 40px;
      width: 40px;
    }

    .img {
      height: 40px;
      margin-left: -13px;
      width: 40px;
    }

    .member-count {
      margin-left: -8px;
      padding: 0px 0px 0px 12px;

      .text-wrapper {
        font-size: 14px;
      }
    }
  }

  &.users-sm {
    align-items: flex-start;

    .avatar {
      height: 20px;
      width: 20px;
      @media only screen and (max-device-width: 600px) {
        width: 16px;
        height: 16px;
      }
    }

    .img {
      height: 20px;
      margin-left: -8px;
      width: 20px;
    }

    .member-count {
      margin-left: -8px;
      padding: 0px 0px 0px 12px;

      .text-wrapper {
        font-size: 14px;
      }
    }
  }
}
