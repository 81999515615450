$tablet: 990px;
$small:650px;
.comments-wrapper {
  // padding: 10px 0 20px 0;

  .view-comments {
    border-bottom: 1px solid #323334;
    padding: 8px 0px;
    color: #8e8e93;
    cursor: pointer;
  }

  .total-coments {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .wrap-buttons {
      display: flex;
      align-items: center;
      gap: 2px;
      background-color: #212225;
      padding: 5px 7px;
      border-radius: 30px;

      @media only screen and (max-device-width: $tablet) {
        padding: 3px 5px;
      }
    }

    .comments-section,
    .share {
      padding: 5px 7px;
      background-color: #212225;
      border-radius: 30px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      gap: 2px;

      @media only screen and (max-device-width: $tablet) {
        padding: 3px 5px;
      }
    }

    svg {
      margin-right: 2px;
      width: 20px;
      height: 20px;
    }
        .comments-section,
        .share {
            padding: 5px 7px;
            background-color: #212225;
            border-radius: 30px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            gap: 1px;
            color: #8E8E93;

            @media only screen and (max-device-width: $tablet) {
                padding: 3px 5px;
            }
    
            svg {
                margin-right: 2px;
            }
    
            color: #8E8E93;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
        }

    color: #8e8e93;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .post-submission-info-2 {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      position: relative;
      color: #8e8e93;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      gap: 5px;
      align-items: center;

      @media only screen and (max-device-width: $tablet) {
        font-size: 10px;
      }

      &::after {
        background: #8e8e93;
        width: 4px;
        height: 4px;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        content: "";
        position: absolute;
      }

      &:first-child {
        color: #387aff;

        @media only screen and (max-device-width: $tablet) {
          font-size: 10px;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
  .post-type{
    display: flex;
    margin:10px 0px;
    gap:8px;
    span{
    color: #8E8E93;
    line-height: 14.4px;
    letter-spacing: -0.08px;
    font-size: 12px;
    }

  }

  .comment-box-modal {
    border: 1px solid #323334;
    position: relative;
    border-radius: 8px;
    // height: 40px;
    // margin: 20px 0;
    width: 100%;
    .mentions-user,
    .mentions-user--multiLine {
      .mentions-user__control{
        .mentions-user__highlighter{
          display: none;
        }
      }
      height: 44px;
      width: 90%;
    }
    .sending {
      // color: #387AFF;
      // border: #387AFF solid 1px;
      display: inline-block;
      position: absolute;
      right: 15px;
      top: 7px;
      width: 10%;
      text-align: end;
      // padding: 8px 30px;
      // border-radius: 40px;
      background: none;
      outline: none;
      cursor: pointer;
    }

    .btn-reply {
      padding: 5px 12px;
    }

    textarea {
      background: none;
      // height: 40px;
      padding-left: 10px;
      padding-top: 10px;
      // width: calc(100% - 60px);
      border: none;
      resize: none;
      outline: none !important;
      color: #fff;
      overflow: auto !important;
      // &::-webkit-input-placeholder {
      //   padding: 7px;
      // }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    button:disabled {
      cursor: not-allowed;
    }
  }
 
}

.add-paginated {
  .comment-text {
    // background: #18191A;
    padding: 10px 20px;
    // margin-bottom: 20px;
    position: relative;

    .user-text {
      padding-left: 80px;

      .show-more-title {
        .description-buttons {
          background-color: #323334;
          border-radius: 0px 10px 10px 10px;
          padding: 12px;
        }
      }
    }

    .actions-btns {
      margin: 15px 0px;

      .likeUnLike {
        background-color: #212225;
        padding: 5px 10px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        p {
          color: #323334;
        }
      }

      span {
        font-size: 18px;
      }
    }

    .actions-btns-reply {
      margin: 15px 74px;
      margin-right: 0 !important;

      span {
        font-size: 18px;
      }
    }

    .dropdown-menu-replies {
      background-color: #18191a;
      position: absolute;
      inset: 0px auto auto 0px;
      margin: unset;
      transform: translate(552px, 55px);
      z-index: 999999;
      background-color: #18191a;
      width: 400px;
      height: 172px;
      border-radius: 10px;
      padding: 20px;
      row-gap: 12px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .wrap-comment-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .avtar-in-post {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        gap: 10px;

        .dot {
          background-color: #8e8e93;
          width: 8px;
          height: 8px;
          border-radius: 10px;
        }

        img {
          width: 60px;
          height: 60px;
          border-radius: 32px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 15px;
          border: 1px solid #000000;
        }

        .name {
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
        }

        .d-flex {
          color: #8e8e93;
        }
      }
    }

    .view-reply {
      color: #387aff;
      font-weight: 400;
      font-size: 13px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 10px;
      // &:hover {
      //     color: #fff;
      // }
    }

    .hide-reply {
      p {
        color: #8e8e93;
        font-weight: 400;
        font-size: 13px;
        cursor: pointer;
        margin-top: 10px;
      }
    }

    .reply {
      // border-left: 1px solid #323334;
      padding-left: unset;

      // p {
      //     padding-left: 70px;
      // }
      .wrapper-replies-header {
        .avtar-in {
          align-items: center;
          display: flex;
          gap: 10px;
          margin-bottom: 15px;

          .profile {
            .main-img {
              border: 1px solid #000;
              border-radius: 32px;
              border-radius: 50%;
              height: 40px;
              // margin-right: 15px;
              object-fit: cover;
              width: 40px;
            }
          }
        }
      }

      .actions-btns-reply-for-box {
        margin: 15px 74px;

        span {
          font-size: 18px;
        }
      }
    }

    .dropdown-menu-comment {
      background-color: #18191a;
      position: absolute;
      inset: 0px auto auto 0px;
      margin: unset;
      transform: translate(652px, 72px);
      z-index: 999999;
      background-color: #18191a;
      width: 400px;
      height: 172px;
      border-radius: 10px;
      padding: 20px;
      row-gap: 12px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .drop-edit {
      position: absolute;
      top: 30%;
      right: 0;
      background-color: #18191a;
      border-radius: 10px;
      padding: 10px 20px;

      .edit {
        display: flex;
        align-items: center;
        gap: 10px;

        h2 {
          font-size: 16px;
          color: #fff;
          margin: unset;
          cursor: pointer;
        }
      }
    }
  }
}

.show-more-comments {
  color: #387aff;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 30px;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}

.pointer {
  cursor: pointer;
  position: relative;
  .box {
    position: absolute;
    padding: 20px;
    // top: 0%;
    right: 0;
    background-color: #212225;
    box-shadow: 10px 10px #12131399;
    // box-shadow: #12131399;
    // max-width: 113px;
    // width: 100%;
    border-radius: 10px;
    z-index: 1;

    p {
      font-size: 13px;
      font-weight: 400;
      font-family: Inter;
      color: #f5f5f5;
    }
  }
}

.show-more-title {
  max-width: "100%";
  word-break: break-all;
  color: var(--themeWhite);

  p {
    font-size: 16px;
    line-height: 22px;

    span {
      color: #387aff;
      cursor: pointer;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 599px) {
  .comments-wrapper {
    .add-paginated {
      .reply {
        .wrapper-replies-header {
          .avtar-in {
            .name {
              font-size: x-small !important;
            }

            div {
              margin: unset !important;
            }

            .profile-link {
              flex: 0 0 40px !important;
            }

            .d-flex {
              font-size: x-small !important;
            }
          }
        }
      }

      .comment-text {
        .actions-btns-reply {
          margin: unset;
        }

        .actions-btns {
          margin: 7px 0;
        }

        .user-text {
          padding-left: 30px;
        }

        .wrap-comment-header {
          a {
            padding-bottom: 7px;
          }

          .avtar-in-post {
            .profile-link {
              flex: 0 0 50px;
            }

            margin-bottom: 5px;

            .d-flex {
              font-size: x-small;
            }

            img {
              width: 45px;
              height: 45px;
            }

            .name {
              font-size: x-small;
            }
          }
        }
      }
    }

    .comment-box-modal {
      margin: 10px 0;
      height: 60px;

      textarea {
        font-size: small;
        width: calc(100% - 80px);
        padding: 5px 10px;
      }

      button {
        font-size: x-small;
      }
    }

    .total-coments {
      .wrap-buttons,
      .comments-section {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 600px) and (max-width: 991px) {
  .comments-wrapper {
    .add-paginated {
      .reply {
        .wrapper-replies-header {
          .avtar-in {
            .name {
              font-size: x-small !important;
            }

            div {
              margin: unset !important;
            }

            .profile-link {
              flex: 0 0 40px !important;
            }

            .d-flex {
              font-size: x-small !important;
            }
          }
        }
      }

      .comment-text {
        .actions-btns-reply {
          margin: unset;
        }

        .actions-btns {
          margin: 7px 0;
        }

        .user-text {
          padding-left: 30px;
        }

        .wrap-comment-header {
          a {
            padding-bottom: 7px;
          }

          .avtar-in-post {
            .profile-link {
              flex: 0 0 50px;
            }

            margin-bottom: 5px;

            .d-flex {
              font-size: x-small;
            }

            img {
              width: 45px;
              height: 45px;
            }

            .name {
              font-size: x-small;
            }
          }
        }
      }
    }

    .comment-box-modal {
      margin: 10px 0;
      height: 60px;

      textarea {
        font-size: small;
        width: calc(100% - 80px) !important;
        padding: 10px 10px;
        // width: 90% !important;
      }

      button {
        font-size: x-small;
      }
    }

    .total-coments {
      .wrap-buttons,
      .comments-section {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
