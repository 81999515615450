$small: 599px;
$tablet: 600px;
$largeTablet: 1023px;

header {
  background: #18191a;
  padding: 0;
  position: sticky !important;
  z-index: 999999;
  top: 0;
}

// footer {
//     background: #18191a;

//     .footer {
//         padding: 30px 10px !important;

//         .footerContent {
//             margin-left: 15% !important;

//             li {
//                 margin-top: 15px;
//             }
//         }

//         a {
//             color: white;
//             font-weight: 100;
//         }
//     }

//     .copyRights {
//         background: #121313 !important;
//         color: #8e8e93;
//         padding: 30px 50px !important;

//         .copyRightContent {
//             @media only screen and(max-device-width:$tablet) {
//                 font-size: 12px;
//                 font-weight: 400;
//                 text-align: center;
//                 padding-bottom: 10px;
//             }
//         }

//         .termConditions {
//             li {
//                 margin-left: 20px;

//                 a {
//                     color: #8e8e93;
//                     font-size: 14px;
//                 }
//             }

//             @media only screen and(max-device-width:$tablet) {
//                 font-size: 12px;
//                 font-weight: 400;
//                 justify-content: center !important;
//                 float: none !important;
//             }
//         }
//     }
// }

.headerContentLanding {
  // max-width: 1280px;

  .side-rightbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

    h5 {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 600;
      font-family: Inter;
    }

    .Social-platform {
      display: flex;
      gap: 10px;
    }
  }
}

.wrap-all-footer {
  background-image: linear-gradient(to top, #18191ae5, #212225e5);

  @media (max-width: $small) {
    text-align: center;
  }

  @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
    text-align: center;
  }

  .main-logo {
    // margin-left: 60px;
    padding: 100px 0;

    @media (max-width: $small) {
      padding: unset;
    }

    @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
      padding: unset;
    }
  }

  .testing {
    min-height: 580px;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: $small) {
      min-height: unset;
    }

    @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
      text-align: center;
      min-height: unset;
    }

    // img {
    //     @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
    //         width: 100%;
    //     }
    // }
  }
}
.contact-bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
  @media (max-device-width: $small) {
    position: unset;
  }
  @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
    position: unset;
  }
}

.condition {
  .copyright {
    h4 {
      font-size: 12px;
      font-family: inter;
      color: #8e8e93;
    }
  }

  .anchor {
    text-align: end;

    a {
      text-decoration: none;
      color: #8e8e93;
      font-size: 12px;
      font-family: inter;
      margin: 5px;
    }
  }
}
