.how-to-install-header {
    .sm-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }

    .installation-info {
        .tags {
            gap: 10px;
        }

        .install-info {
            border: 1px solid #323334;
            border-radius: 10px;
            display: grid;
            text-align: center;
            grid-template-columns: 1fr 1fr 1fr;
            padding: 15px;

            &>div {
                border-right: 1px solid #F5F5F5;
                ;
            }

            &>div:last-child {
                border: none;
            }

            h6 {
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                margin: 0;
            }

            p {
                font-weight: 400;
                font-size: 12px;
            }
        }
    }
}

.show-more-title {
    max-width: "100%";
    word-break: break-all;
    color: var(--themeWhite);

    p {

        font-size: 15px;

        span {
            color: #387AFF;
            cursor: pointer;
        }
    }
}

.video-section {
    .vid-img-how {
        max-height: 600px;
        width: 100%;
        object-fit: contain;
    }

    .slick-list {
        height: 600px;
    }

    .slick-slide {
        div>div> {
            img {
                height: 600px;
            }
        }
    }


    .owl-carousel .owl-item div img {
        display: block;
        width: 100%;
        max-height: 600px;
        resize: auto;
        object-fit: contain;
    }

    // .player-wrapper {
    //     width: auto; // Reset width
    //     height: auto; // Reset height
    // }
    .slick-slider {
        button {
            // position: absolute;
            z-index: 4;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0px 10px;
        }

        .slick-next,
        .slick-prev {
            border-radius: 50px;
            background: rgba(31, 32, 32, 0.493);
            width: 50px;
            height: 50px;
            font-size: 32px;

        }

    }



    .steps {
        font-weight: 300;
        font-size: 30px;
        line-height: 35px;
        padding: 20px 0px;
    }
}

.tools-wrapper {
    border: 1px solid #323334;
    border-radius: 10px;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    padding: 30px 20px;
    margin-bottom: 30px;

    h3 {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        display: flex;
        margin-bottom: 15px;
        align-items: center;

        svg {
            margin-right: 8px;
        }
    }

    ul {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        li {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            border: 1px solid #323334;
            border-radius: 30px;
            padding: 8px 20px;
        }
    }
}

// .step-p {
//     font-weight: 400;
//     font-size: 15px;
//     line-height: 20px;
//     color: #F5F5F5;
// }

.next-step-btn {
    display: flex;
    background: #387AFF;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #F5F5F5;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    width: 190px;
    height: 50px;
    margin: 30px 0px;
    cursor: pointer;

    i {
        padding-left: 15px;
    }

    &:hover {
        background: #323334;

    }
}

.back-step-btn {
    display: flex;
    background: #387AFF;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #F5F5F5;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    width: 190px;
    height: 50px;
    margin: 30px 0px;
    cursor: pointer;

    i {
        padding-right: 15px;
    }

    &:hover {
        background: #323334;

    }
}

video {
    width: 100%;
}