$small: 599px;
$medium: 600px;
/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
$landscapetab: 801px;

body.modal-open {
  overflow: hidden;
  padding-right: 10px;
}

.comments-overlay {
  background: #18191acf;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 999999;
}

.modal-comments {
  position: fixed;
  // left: 35%;
  // top: 30%;
  // display: block;
  justify-content: center;
  align-items: center;
  max-width: 1270px;
  width: 100%;
  border-radius: 10px;
  // height: 60%;
  z-index: 1000;
  overflow: hidden;

  .close-btn {
    padding: 0;
  }

  .child-sec {
    @media only screen and (max-device-width: 600px) {
      padding: 0px;
    }
    .list-section {
      background-color: #121313;
      padding: unset !important;

      @media only screen and (max-device-width: 600px) {
        height: min-content;
      }
      .user-name-comment {
        border-bottom: 1px solid #323334;

        .wrap-users {
          img {
            width: 26px;
            height: 26px;
            border-radius: 20px;
          }
          h6 {
            font-family: Inter;
            font-weight: 600;
            font-size: 13px;
            padding-top: 5px;
            color: #f5f5f5;
          }
        }
      }

      .wrap-all-comments {
        overflow: auto;
        height: 100%;
        max-height: 55vh;
        // @media only screen and (max-device-width: 1100px) {
        //   max-height: 435px;
        // }
        @media (min-device-width: 1371px) and (max-device-width: 1440px) {
          max-height: 68vh;
        }
        @media (min-device-width: 992px) and (max-device-width: 1370px) {
          max-height: 65vh;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        .popup-title {
          p {
            font-size: 18px;
            font-weight: 400;
            font-family: Inter;
            color: #f5f5f5;
          }
        }

        .popup-desc {
          p {
            font-size: 14px;
            font-weight: 400;
            font-family: Inter;
            color: #f5f5f5;
            padding-top: 5px;
            word-break: break-word;

            span {
              color: #387aff;
              cursor: pointer;
            }
          }
        }

        .type-post {
          span {
            font-size: 12px;
            font-weight: 400;
            font-family: Inter;
            color: #8e8e93;
            // padding-top: 5px;
          }

          .width {
            width: 3px;
            height: 3px;
            background-color: #8e8e93;
            border-radius: 5px;
          }
        }

        .user-comments {
          // padding: 0 0 0 15px;
          background-color: #18191a;
          .replies {
            padding-left: 40px;
            .horizontol {
              position: relative;
              top: -4px;
            }

            span {
              font-size: 12px;
              font-weight: 400;
              font-family: Inter;
              color: #8e8e93;
              padding-left: 4px;
            }
          }
          .signle-comment {
            position: relative;

            .user_name {
              img {
                border-radius: 20px;
              }
              h5 {
                font-size: 15px;
                font-weight: 600;
                font-family: Inter;
                color: #f5f5f5;
                padding-top: 5px;
              }

              .width {
                width: 3px;
                height: 3px;
                background-color: #8e8e93;
                border-radius: 5px;
              }

              span {
                font-size: 12px;
                font-weight: 400;
                font-family: Inter;
                color: #8e8e93;
              }
            }

            .comment-desc {
              width: 100%;
              .para {
                width: 92%;
                padding: 5px 0px 5px 40px;
                word-break: break-word;
                p {
                  font-size: 15px;
                  font-weight: 400;
                  font-family: Inter;
                  color: #f5f5f5;
                  // text-align: justify;
                }

                span {
                  font-size: 15px;
                  font-weight: 400;
                  font-family: Inter;
                  color: #387aff;
                }

           
              }
              .svg-like {
                position: relative;
                width: 8%;
                align-items: center;
                span {
                  font-size: 12px;
                  font-weight: 400;
                  font-family: Inter;
                  color: #f5f5f5;
                  // padding-right: 9px;
                }
                .ThumbSimple {
                  border-radius: 50%;
                  border: 1px solid white;
                }
              }
            }

            // .box {
            //   position: absolute;
            //   padding: 20px;
            //   top: 0%;
            //   right: 0;
            //   background-color: #212225;
            //   box-shadow: 10px 10px #12131399;
            //   // box-shadow: #12131399;
            //   max-width: 113px;
            //   // width: 100%;
            //   border-radius: 10px;
            //   z-index: 1;

            //   p {
            //     font-size: 13px;
            //     font-weight: 400;
            //     font-family: Inter;
            //     color: #f5f5f5;
            //   }
            // }
          }

          // replies style
          .wrap-all-replies {
            padding: 12px 0 5px 30px;
            position: relative;

            .user_reply {
              img {
                width: 32px;
                height: 32px;
                border-radius: 20px;
              }
              h5 {
                font-size: 15px;
                font-weight: 600;
                font-family: Inter;
                color: #f5f5f5;
                padding-top: 5px;
              }

              .width {
                width: 3px;
                height: 3px;
                background-color: #8e8e93;
                border-radius: 5px;
              }

              span {
                font-size: 12px;
                font-weight: 400;
                font-family: Inter;
                color: #8e8e93;
              }
            }

            .comment-reply {
              width: 100%;
              .reply {
                width: 92%;
                padding-left: 40px;
                padding-top: 5px;

                p {
                  font-size: 15px;
                  font-weight: 400;
                  font-family: Inter;
                  color: #f5f5f5;
                }

                span {
                  font-size: 15px;
                  font-weight: 400;
                  font-family: Inter;
                  color: #387aff;
                }

                .replies {
                  .horizontol {
                    position: relative;
                    top: -2px;
                  }

                  span {
                    font-size: 12px;
                    font-weight: 400;
                    font-family: Inter;
                    color: #8e8e93;
                    padding-left: 4px;
                  }
                }
              }
            }

            .svg-like-reply {
              width: 8%;
              align-items: end;
              span {
                font-size: 12px;
                font-weight: 400;
                font-family: Inter;
                color: #f5f5f5;
                padding-right: 9px;
              }
            }

            .box {
              position: absolute;
              top: 45%;
              right: 0;
              background-color: #212225;
              box-shadow: 10px 10px #12131399;
              // box-shadow: #12131399;
              max-width: 113px;
              width: 100%;
              border-radius: 10px;
              z-index: 99999999;

              p {
                font-size: 13px;
                font-weight: 400;
                font-family: Inter;
                color: #f5f5f5;
              }
            }
          }
        }
      }
      .mention-comments {
        background-color: #121313;
      }
    }
    .img-section {
      display: flex;
      align-items: center;
      background-color: #121313;
      max-block-size: -webkit-fill-available;
      @media only screen and (max-device-width: $small) {
        align-items: start;
        //   height: fit-content;
      }
    }
  }

  @media only screen and (max-device-width: $small) {
    width: 350px;
  }
}

.modal__close {
  border: 0;
  position: absolute;
  line-height: normal;
  right: 10px;
  background: none;
  top: 0;
  color: #404040 !important;
  font-size: 28px;
  z-index: 1;
}

.modal-user-profile {
  right: 15px;
  z-index: 999;
}

.modal-login-profile {
  right: 15px;
  top: 50px;
  z-index: 999;
}

.other-class {
  border: 0;
  position: absolute;
  line-height: normal;
  left: 15px;
  background: none;
  top: 15%;
  color: #404040 !important;
  font-size: 28px;
  z-index: 1;
}

.hidden {
  display: none;
}

@keyframes slide-in {
  0% {
    transform: translateX(-50%) translateY(-20px);
  }

  100% {
    transform: translateX(-50%) translateY(0);
  }
}
