.exploreParts {
    margin-bottom:35px;

    .large-a {
        font-size: 20px;
        line-height: 25px;
    }

    .search-filter {
        display: flex;
        justify-content: space-evenly;
        gap: 15px;
        align-items: center;
        margin-top: 20px;

        button {
            width: 100%;
        }
    }

    .explore{
        width: 80% !important;
    }
    .quick-search {
        margin: 0 auto;
        display: flex;
        // flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        gap: 15px;
        // justify-content: space-evenly;


        span {
            border: 1px solid rgba(80, 85, 92, 0.6);
            border-radius: 40px;
            display: flex;
            padding: 12px;
            align-items: center;
            // gap: 8px;
            cursor: pointer;
            margin-bottom: 10px;
        }
    }

    .listing {
        display: grid;
        margin-top: 30px;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 15px;

        .list-block {
            background: #212225;
            border: 1px solid #323334;
            border-radius: 8px;
            padding: 20px;
            display: flex;
            align-items: center;

            img {
                margin-right: 15px;
            }

            &:hover {
                background: #000;
            }
        }
    }
}