$tablet: 1023px;
$mobile: 650px;

.messenger-container {
  // grid-gap: 30px;
  display: grid;
  // gap: 30px;
  grid-template-columns: 15% 57% calc(35% - 75px);
  margin: 0 auto;
  max-width: 1280px;
  min-height: 58vh;

  &>div:nth-child(1),
  &>div:nth-child(2) {
    border-right: 1px solid #323334;
  }

  .profile-links-side {
    padding: 5px 15px;
    // padding: 30px 15px;
    position: sticky;
    top: 110px;
    left: 0;

    .svg-container:hover path {
      fill: #fff;
      /* Change the fill color on hover */
    }

    li a {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #8e8e93;

      &:hover {
        color: #fff;
      }

      img {
        margin-right: 10px;
      }

      svg {
        margin-right: 9px;
      }
    }
  }

  @media only screen and (max-width: $tablet) {
    grid-template-columns: 15% 85%;
  }

  @media only screen and (max-width: $mobile) {
    grid-template-columns: 100%;
  }
}

.Messenger {
  padding: 10px 0 20px 0;

  .messnger-header {
    border-bottom: 1px solid #323334;

    .messnger-header-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: transparent;
      z-index: 999;
      width: 100%;

      padding: 10px 20px;

      h1 {
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        color: #f5f5f5;
        align-self: end;
      }

      // .back-button svg{
      //   width: 18px !important;
      //   height: 18px !important;
      // }

      .dott svg path {
        fill: #f5f5f5 !important;
        stroke: #f5f5f5 !important;
      }

      .dott {
        img {
          cursor: pointer;
        }
      }
    }
  }

  .user-inbox {

    // padding: 20px 30px;
    // min-height: 676px;
    // max-height: 676px;
    // overflow-y: auto;
    // @media only screen and (max-width: 1024px) {
    //   padding: 20px 10px;
    //   min-height: 616px;
    //   max-height: 616px;
    // }
    // @media only screen and (max-width: $mobile) {
    //   padding: 20px 10px;
    //   min-height: 666px;
    //   max-height: 666px;
    // }
    .nested-list {
      min-height: 100%;
      max-height: 500px;
      overflow-y: scroll;
      margin: 0 20px 15px 20px;

      &::-webkit-scrollbar {
        display: none;
      }

      // @media only screen and (max-width: 1024px) {
      //   padding: 20px 10px;
      //   min-height: 616px;
      //   max-height: 616px;
      // }
      // @media only screen and (max-width: $mobile) {
      //   padding: 20px 10px;
      //   min-height: 666px;
      //   max-height: 666px;
      // }
    }

    .search-container {
      position: relative;
      margin: 0 20px 20px 20px;

      // width: 100%;
      .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }

      input.form-control {
        background-color: #323334;
        color: #fff;
        padding-left: 35px;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    .users {
      display: flex;
      column-gap: 20px;
      margin: 20px;
      // overflow-x: auto;
      overflow-x: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      label {
        position: relative;
        cursor: pointer;
        // width: 80px;
        height: 83px;

        .follow {
          display: none;
          position: absolute;
          right: 0;
          top: 0;
        }

        .name {
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          color: #fff;
          text-align: center;
          margin: 5px 0px;
        }
      }

      .account-img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background: #60646d;
        overflow: hidden;

        .notify-icon {
          width: 10px;
          height: 10px;
          position: absolute;
          top: 0;
          right: 8px;
          display: flex;
          border: 1px solid #121313;
          border-radius: 12px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .list-users {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;

    .list-item {
      display: flex;
      align-items: center;
      gap: 15px;
      border-bottom: none;
      color: #fff;
      padding: unset;
      margin-bottom: unset;
      width: 80%;

      .main-img {
        border-radius: 50%;
        border: 1px solid #323334;
        width: 35px;
        height: 35px;
        object-fit: cover;
      }

      .default-btn {
        border-radius: 6px;
        font-size: 13px;
        padding: 6px 20px;
        transition: all ease 0.4s;
        margin-left: auto;

        @media only screen and (max-device-width: 1100px) {
          width: 80px;
        }
      }

      .list-info {
        h4 {
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
          display: flex;
          gap: 10px;
          align-items: self-start;
          word-break: break-word;

          .status-image {
            width: 18px;
            height: 18px;
          }
        }

        p {
          word-break: break-word;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
        }
      }

      &.user-list-item {
        img {
          border-radius: 50px;
          border: 1px solid #323334;
          width: 65px;
          height: 65px;
          object-fit: cover;
        }

        .list-info {
          p {
            color: #8e8e93;
          }
        }
      }
    }

    .ThreeDostInbox {
      display: flex;
      gap: 10px;

      .message-count {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #387aff;
        text-align: center;
        color: #f5f5f5;
        font-size: 13px;
        font-family: Inter;
        font-weight: 400;

        p {
          padding-top: 3px;
        }
      }

      .drop-wrapper {

        .three-dots {
          background-color: transparent;
          padding: 0;
          outline: none;
          border: none;

          &::after {
            display: none;
          }

          svg {
            visibility: hidden;
            @media only screen and (max-width: 650px) {
              visibility: visible;
            }
          }
        }

        .show svg {
          visibility: visible;
        }

        .wrapp-list {
          background-color: #18191a;
          border-radius: 10px;

          .dropdown-item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            .dropdown-label {
              font-weight: 400;
              font-size: 14px;
              color: #f5f5f5;
            }
          }
        }
      }

      .messageThreeDots {
        display: flex;
        align-items: center;
        position: relative;
        flex-direction: row-reverse;

        svg {
          // display: none;
          visibility: hidden;
          cursor: pointer;
        }

        &.active svg {
          display: block !important;
        }

        // &:hover .messageThreeDotsModal,
        // .messageThreeDotsModal:hover {
        //   display: block;
        // }

        .messageThreeDotsModal {
          // display: none;
          width: 113px;
          position: absolute;
          top: -100%;
          right: 100%;
          background-color: #212225;
          padding: 20px;
          border-radius: 10px;
          z-index: 8;

          .messageThreeDotsModalItem {
            cursor: pointer;
            display: flex;
            gap: 10px;
          }
        }
      }
    }
  }

  .list-users:hover {
    .ThreeDostInbox {
      .drop-wrapper {
        .three-dots svg {
          visibility: visible;
        }

      }
    }
  }

  .user-chat {
    position: relative;

    .message-box {
      width: 100%;
      position: relative;
      // bottom: 0;
      // padding-left: 15px;
      display: flex;
      justify-content: center;
      margin-bottom: 6px;

      textarea {
        width: 95%;
        padding: 10px 40px 10px 10px;
        background-color: transparent;
        color: white;
        border-radius: 6px;
        border: 1px solid #323334;
        resize: none;
        // &::-webkit-input-placeholder{
        //   display: none;
        // }
      }

      textarea:focus {
        outline: none;
        // border: 1px solid #323334;
      }

      .wrap-send-btn {
        position: absolute;
        top: 15%;
        right: 5%;

        .messageSendBtn {
          border: none;
          background-color: transparent;
        }
      }
    }

    .user-messages {
      width: 100%;
      // overflow: auto;

      padding: 15px 20px 0 20px;

      // @media only screen and (max-width: 1024px) {
      //   min-height: 540px;
      //   max-height: 540px;
      // }
      // @media only screen and (max-width: $mobile) {
      //   padding: 15px;
      //   min-height: 590px;
      //   max-height: 590px;
      // }
      .no-message-content {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        // width: 260px;
        margin-bottom: auto;
        padding-top: 20px;

        img {
          width: 85px;
          height: 85px;
          margin-bottom: 20px;
          border-radius: 10px;
          object-fit: cover;
        }

        .followers-post {
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          gap: 10px;
          width: 100%;

          .follower {
            display: flex;
            flex-direction: column;
            text-align: center;

            p {
              color: #f5f5f5;
              font-weight: 400;
              font-size: 16px;
            }

            span {
              color: #f5f5f5;
              font-weight: 400;
              font-size: 11px;
            }
          }
        }

        .username {
          color: #f5f5f5;
          font-weight: 400;
          font-size: 20px;
        }

        .location {
          display: flex;
          margin-bottom: 20px;
          gap: 2px;

          img {
            width: 16px;
            height: 16px;
          }

          p {
            font-size: small;
            color: #8e8e93;
          }
        }

        .viewProfileBtn {
          background-color: #212225;
          padding: 2px 10px;
          color: #fff;
          border-radius: 5px;
          border: none;
          font-size: smaller;
        }
      }

      .chat-messages {
        // overflow: auto;
        // width: 100%;
        // display: flex;
        // flex-direction: column-reverse;
        // // justify-content: flex-end;
        // max-height: 100%;
        display: flex;
        flex-direction: column-reverse;
        overflow: auto;
        height: 600px;

        &::-webkit-scrollbar {
          display: none;
        }

        .infinte-custom-scroll {
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .dateTime {
        p {
          font-weight: 400;
          font-size: 13px;
          font-family: Inter;
          text-align: center;
          padding: 10px 0;
        }
      }

      .message-content-exist {
        display: flex;
        flex-direction: column;
        // padding: 15px 30px;
        width: 100%;
        // overflow-y: auto;

        .dateTime {
          align-self: center;

          p {
            color: #8e8e93;
          }
        }

        .userMessage {
          // align-self: end;
          margin-bottom: 15px;
          display: flex;
          width: 100%;
          gap: 5px;

          .user-message-text {
            display: flex;
            flex-direction: column;

            // padding: 10px;
            .Chat-date {
              color: #8e8e93;
              width: 100px;
              // margin-bottom: 10px;
              // text-align: start;
            }

            .replied-story {
              span {
                font-weight: 400;
                font-size: 11px;
                color: #f5f5f5;
                font-family: Inter;
              }

              .story-img {
                border-left: 1px dashed #323334;
                padding-left: 10px;

                img {
                  width: 100%;
                  max-width: 90px;
                  max-height: 160px;
                  height: 100%;
                  border-radius: 10px;
                }
              }
            }

            .message-text {
              position: relative;

              p {
                padding: 10px;
                background-color: #323334;
                color: white;
                border-radius: 0px 10px 10px 10px;
                max-width: 275px;
                margin-bottom: 10px;
                word-wrap: break-word;
                white-space: pre-line;

                span {
                  font-size: 10px;
                }
              }

              .like-button {
                position: absolute;
                bottom: 0px;
                left: 95%;
                border-radius: 50%;
                width: 20px;
                background-color: #323334;
                border: 1px solid #121313;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                  cursor: pointer;
                  width: 14px;
                  height: 14px;
                }
              }
            }
          }

          .userImage {
            min-width: 30px;
            height: 42px;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }

          .messageThreeDots {
            display: flex;
            align-items: center;
            position: relative;

            .drop-wrapper {
              .three-dots {
                background-color: transparent;
                padding: 0;
                outline: none;
                border: none;

                &::after {
                  display: none;
                }
              }

              .wrapp-list {
                background-color: #18191a;
                border-radius: 10px;

                .dropdown-item {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  cursor: pointer;

                  .dropdown-label {
                    font-weight: 400;
                    font-size: 14px;
                    color: #f5f5f5;
                  }
                }
              }
            }

            //    img:hover + .messageThreeDotsModal {
            //     display: block;
            //   }
            // .messageThreeDotsModal:hover {
            //     display: block;
            //   }
            img {
              display: none;
              cursor: pointer;
            }

            &.active img {
              display: block !important;
            }

            .messageThreeDotsModal {
              // display: none;
              width: 113px;
              position: absolute;
              top: 0;
              left: 100%;
              background-color: #212225;
              padding: 20px;
              border-radius: 10px;
              z-index: 8;

              .messageThreeDotsModalItem {
                display: flex;
                gap: 10px;
                cursor: pointer;
              }
            }
          }
        }

        .userMessage:hover {
          .messageThreeDots {
            img {
              display: block;
            }
          }
        }

        .selfMessage {
          align-self: end;
          margin-bottom: 15px;
          margin-bottom: 15px;
          display: flex;
          justify-content: end;
          flex-direction: row-reverse;
          width: 100%;
          gap: 5px;

          .user-message-text {
            display: flex;
            flex-direction: column;
            align-items: end;

            // padding: 10px;
            .Chat-date {
              color: #8e8e93;
              width: 100px;
              margin-bottom: 10px;
              text-align: end;
            }

            .message-text {
              padding: 10px;
              background-color: #387aff;
              color: white;
              border-radius: 10px 0px 10px 10px;
              max-width: 275px;
              margin-bottom: 10px;
              word-wrap: break-word;

              p {
                white-space: pre-line;
                span {
                  font-size: 10px;
                }
              }
            }
          }

          .userImage {
            min-width: 30px;
            height: 42px;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }

          .messageThreeDots {
            display: flex;
            align-items: center;
            position: relative;
            flex-direction: row-reverse;

            .drop-wrapper {
              .three-dots {
                background-color: transparent;
                padding: 0;
                outline: none;
                border: none;

                &::after {
                  display: none;
                }
              }

              .wrapp-list {
                background-color: #18191a;
                border-radius: 10px;

                .dropdown-item {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  cursor: pointer;

                  .dropdown-label {
                    font-weight: 400;
                    font-size: 14px;
                    color: #f5f5f5;
                  }
                }
              }
            }

            img {
              display: none;
              cursor: pointer;
            }

            &.active img {
              display: block !important;
            }

            // img:hover +  .messageThreeDotsModal  {
            //     display: block;
            //   }
            //   .messageThreeDotsModal:hover   {
            //     display: block;
            //   }
            .messageThreeDotsModal {
              // display: none;
              width: 113px;
              position: absolute;
              top: 0;
              right: 100%;
              background-color: #212225;
              padding: 20px;
              border-radius: 10px;
              z-index: 8;

              .messageThreeDotsModalItem {
                cursor: pointer;
                display: flex;
                gap: 10px;
              }
            }
          }
        }

        .selfMessage:hover {
          .messageThreeDots {
            img {
              display: block;
            }
          }
        }
      }
    }

    .who-typing {
      height: auto;
      min-height: 22px;

      .typing-user {
        .typing-name {
          p {
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
          }
        }
      }
    }
  }
}