.head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 20px;
    background-color: transparent;
    position: absolute;
    top: 20px;
    z-index: 999;
    width: 100%;
    padding: 0px 15px;
  
}

h1 {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #f5f5f5;
}

// .back-button svg{
//   width: 18px !important;
//   height: 18px !important;
// }


.dott svg path{
 fill: #f5f5f5 !important;
 stroke: #f5f5f5 !important;
}
