:root {
    --trans: rgba(0, 0, 0, 0);
    --noColor: rgba(0, 0, 0);
    --white: #ffffff;
    --offWhite: rgba(245, 245, 245, 1);
    --themewhite: rgba(255, 255, 255, 1);
    --whiteWarning: rgba(227, 230, 234, 1);
    --themeblue: rgba(70, 131, 222, 1);
    --btnBlue_Bg: rgba(56, 122, 255, 1);
    --iconDarkblue: rgba(37, 92, 152, 1);
    --tabBlue: rgba(0, 122, 255, 1);

    --black: rgba(0, 0, 0, 1);

    --shadow: rgba(0, 0, 0, 0.7);
    --btnDark_Bg: rgba(255, 255, 255, 0.03);
    --selectedGrey: rgba(152, 153, 153, 1);

    --grey: rgba(61, 61, 61, 1);
    --iconGrey: rgba(96, 100, 109, 1);
    --labelGrey: rgba(146, 146, 146, 1);
    --header: rgba(24, 25, 26, 1);
    --vlighgrey: rgba(196, 196, 196, 1);

    --themered: rgba(225, 77, 77, 1);
    --iconRed: rgba(232, 52, 52, 1);

    --iconPink: rgba(225, 77, 166, 1);

    --green: rgba(120, 209, 76, 1);
    --darkGreen: rgba(29, 158, 38, 1);
    --parrot: rgba(97, 214, 45, 1);
    --brightGreen: rgba(135, 219, 40, 1);
    --greenNotify: rgba(82, 255, 0, 1);
    --greenDrive: rgba(58, 155, 91, 1);

    --darkYellow: rgba(174, 223, 33, 1);
    --liteYellow: rgba(255, 199, 0, 1);

    --orange: rgba(255, 189, 89, 1);
    --darkOrange: rgba(250, 154, 42, 1);
    --OrageIcon: rgba(229, 100, 36, 1);

    --purple: rgba(144, 112, 230, 1);


    --pink: rgba(221, 75, 173, 1);

    --Bg_New: rgb(44, 44, 44);
    --input: rgba(50, 51, 52, 1);
    --placeholder: rgba(142, 142, 147, 1);
    --lightDark: #27292E;
    --line: rgba(60, 60, 67, 1);
    --icon_Bg: rgba(116, 116, 128, 0.08);
    --icon: #8E8E93;
    --cameraIcon: rgba(60, 60, 67, 0.6);
    --iconSky: rgba(77, 148, 225, 1);
    --background: rgba(18, 19, 19, 1);
    --nonActiveIcon: rgba(33, 34, 37, 1);
    --time: rgba(107, 111, 116, 1);
    --wrapper: rgba(0, 0, 0, 0.7);

    --error: rgba(243, 60, 20, 1);
}