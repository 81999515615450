.login-wrapper {
    min-height: 100vh;
    // background: url(@assets/authImgs/login-bg.jpg) no-repeat center center;
    background-size: cover;

    &::before {
        content: "";
        left: 0;
        opacity: 0.3;
        top: 0;
        width: 100%;
        // background: linear-gradient(180deg,
        //         rgba(4, 5, 6, 0.6) 0%,
        //         rgba(4, 5, 6, 0.8) 57.81%,
        //         #040506 91.67%),
        //     lightgray 50% / cover no-repeat;
        height: 100%;
    }

    .wrapper-parent {
        display: flex;
        background-color: var(--background);
        // height: 650px;
        flex-direction: row;

        a {
            text-align: right;
        }

        a>svg {
            margin: 20px;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 70px;

            .reset-sec {
                color: var(--themewhite);
                font-size: 30px;
                font-style: normal;
                font-weight: 300px;
                line-height: 35px;
                margin-bottom: 10px;
            }

        }
    }

    .find-text {
        color: #8e8e93;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
    }

    .logo {
        margin-bottom: 30px;
    }

    .form-control {
        background: #323334;
        height: 44px;
        border: none;
        color: #fff;
        padding: 12px 16px;
        align-items: flex-start;

    }

    .forgot {
        display: flex;
        justify-content: end;
        margin-top: 10px;
        color: #387aff;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        cursor: pointer;
        margin-bottom: 30px;
    }

    .login-btn {
        width: 100%;
        display: block;
        background: #387aff;
        border-radius: 10px;
        line-height: 45px;
        border: none;
        color: #fff;
        transition: all ease 0.4s;
        border: none;
        cursor: pointer;

        // &:hover {
        //     background: #8e8e93;
        //     color: #fff;
        // }
    }

    .login-btn:disabled {
        background: #8e8e93;
        cursor: not-allowed;
    }




}

.error-message {
    text-align: left;
    margin-top: 0.5rem;
}

form i {
    margin-left: -30px;
    cursor: pointer;
}

.btn-style {
    outline: none;
    border: none;
    background: transparent;
    color: var(--btnBlue_Bg);
}

.btn-style:disabled {
    color: var(--vlighgrey);
}

.count-down {
    margin-top: 10px;
    color: var(--btnBlue_Bg);
}