$small: 599px;
$x-small: 400px;
$medium: 600px;
/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
$landscapetab: 801px;

/* tablet, landscape iPad, lo-res laptops ands desktops */
.user-profile-section {
  position: relative;

  .container {
    padding: 0 25px;

    @media only screen and (max-device-width: $small),
    (max-device-width: $landscapetab) {
      padding: unset;
    }
  }

  .banner-img {
    width: 100%;
    object-fit: cover;
    height: 385px;

    @media only screen and (max-device-width: $small),
    (max-device-width: $landscapetab) {
      margin-top: unset;
      height: 285px;
    }
  }

  .user-data {
    background: #18191a;
    border-radius: 10px 10px 0 0;
    padding: 40px 30px;
    position: relative;
    margin-top: -50px;
    display: flex;

    .user-data-dot {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    @media only screen and (max-device-width: $small),
    (max-device-width: $landscapetab) {
      padding: 15px 10px;
      flex-direction: column;
    }

    .avtar {
      margin-right: 30px;

      @media only screen and (max-device-width: $small),
      (max-device-width: $landscapetab) {
        position: absolute;
        top: -50px;
      }

      .avtar-img {
        background-color: #121313;
        border: 2px solid #aedf21;
        border-radius: 10px;
        min-width: 120px;
        max-width: 120px;
        height: 120px;
        object-fit: cover;

        @media only screen and (max-device-width: $small),
        (max-device-width: $landscapetab) {
          min-width: 85px !important;
          max-height: 85px !important;
          max-width: 85px;
        }
      }
    }

    .chat-btn-wrap {
      .follow-button {
        @media only screen and (max-device-width: $small) {
          background-color: #387aff;
          border-radius: 8px;
          padding: 5px 18px !important;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border: none;
        }
      }

      // @media only screen and (max-device-width: $small) {
      //   margin-top: -60px;
      // }
    }

    .dott {
      text-align: end;
      position: relative;
      cursor: pointer;

      .drop-wrapper {
        .three-dots {
          background-color: transparent;
          padding: 0;
          outline: none;
          border: none;

          &::after {
            display: none;
          }
        }

        .wrapp-list {
          width: 285px;
          background-color: #18191a;
          border-radius: 10px;
          padding: 20px;
          .dropdown-item {
            padding: 0;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            .dropdown-label {
              font-weight: 400;
              font-size: 14px;
              color: #f5f5f5;
            }
          }
        }
      }
    }

    .helmat-row {
      display: flex;
      align-items: center;
    }

    span {

      @media only screen and (max-device-width: $small),
      (max-device-width: $landscapetab) {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .UserName {
      margin-top: 6px;

      @media only screen and (max-device-width: $small),
      (max-device-width: $landscapetab) {
        position: relative;
        justify-content: flex-start;
        top: 30px;
        font-size: 15px;
      }
    }

    .shots {
      padding-left: 10px;

      p {
        font-weight: 400;
        color: #aedf21;
        font-size: 11px;
        line-height: 10px;
      }

      .bullets {
        display: flex;
        margin-top: 8px;

        span {
          border: 1px solid #323334;
          border-radius: 3px;
          width: 12px;
          height: 12px;
          margin-right: 1px;
          background: none;

          &:nth-child(1) {
            background: #1d9e26;
          }

          &:nth-child(2) {
            background: #61d62d;
          }

          &:nth-child(3) {
            background: #87db28;
          }

          &:nth-child(4) {
            background: #aedf21;
          }
        }
      }
    }

    .helmat {
      background: #aedf21;
      padding: 10px 8px;
      margin-left: 12px;
      text-align: center;

      p {
        font-weight: 600;
        font-size: 10px;
        line-height: 10px;
        color: #18191a;
      }
    }
  }

  .userInfo {
    flex: auto;

    @media only screen and (max-device-width: $small) {
      margin-top: -12px;
    }

    .name {
      display: flex;
      // justify-content: space-between;
      text-align: start;

      word-break: break-all;
      font-weight: 300;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.374px;

      // margin-bottom: 5px;
      @media only screen and (max-device-width: $small),
      (max-device-width: $landscapetab) {
        text-align: justify;
        position: relative;
        top: 30px;
        font-size: 20px;
      }

      .chat-btn-wrap {
        @media only screen and (max-device-width: $small) {
          margin-top: -60px;
        }
      }

      .follow-button {
        background-color: #387aff;
        // height: 38px;
        // width: 125px;
        padding: 3px 24px !important;
        font-size: 15px;
        border-radius: 8px;
        // margin-top: 15px;
        color: #fff;
        border: none;

        @media only screen and (max-device-width: $small) {
          // position: absolute;
          // right: 18px;
          // height: 34px;
          // width: 100px;
          // top: 0px;
          padding: 3px 18px !important;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: unset;
        }
      }
    }

    .userName {
      color: #8e8e93;
      letter-spacing: 0.374px;
      font-weight: 300;
      font-size: 16px;
    }

    p {
      span {
        color: #387aff;
        cursor: pointer;
      }
    }

    .followers {
      position: absolute;
      top: -60px;
      right: 0px;
      gap: 8px;
      width: 100%;
      justify-content: flex-end;
      display: flex;

      @media only screen and (max-device-width: $landscapetab) {
        top: -50px;
        gap: 15px;
      }

      @media only screen and (max-device-width: $landscapetab) {
        gap: 0px;
      }

      .clm {
        border-right: 0.5px solid rgba(245, 245, 245, 0.26);
        padding-right: 30px;
        margin-right: 30px;
        text-align: center;
        cursor: pointer;

        // @media only screen and (max-device-width: $landscapetab) {
        //   padding-right: 0px;
        // }
        @media only screen and (max-device-width: $landscapetab) {
          margin-right: 20px;
          padding-right: 15px;
        }

        &:last-child {
          border-right: 0;
        }

        .number {
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 0px;

          @media only screen and (max-device-width: $landscapetab) {
            font-size: 16px;
          }

          @media only screen and (max-device-width: $landscapetab) {
            font-size: 14px;
          }
        }

        p {
          font-weight: 400;
          font-size: 16px;

          @media only screen and (max-device-width: $small) {
            font-size: 12px;
          }

          @media only screen and (max-device-width: $x-small) {
            font-size: 10px;
          }
        }
      }
    }
  }

  .default-btn {
    margin-left: auto;
    margin-bottom: auto;
    // max-width: 300px;
    display: inline-block;
    // flex: 0 0 300px;
    border-radius: 10px;
  }
}

.garage {
  border-bottom-left-radius: 10px;
}

.active {
  border-bottom-right-radius: 10px;
}
.activeStatus{
  border-bottom-right-radius: 10px;
  color: white !important;
}
.scrollingBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  height: 40px;
  width: 100%;
  color: #8e8e93;
  font-weight: 400;
  font-size: 13px;
  // border-bottom-left-radius: 10px;
}

.scrollingButton {
  background-color: #18191a;
  border: none;
  color: #8e8e93;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #97979a;

  @media only screen and (max-device-width: $small) {
    border-radius: 0px;
  }
}

.scrollingButton:hover {
  border-bottom: 3px solid #387aff;
  color: #f5f5f5;
}

.UserBio {
  margin-top: 15px;
  text-align: start;
  max-width: 100%;
  font-size: 14px;
  font-weight: 400;

  // @media only screen and(min-device-width:$small),
  //   (max-device-width: $landscapetab) {
  //   position: relative;
  //   margin-bottom: 2rem !important;
  //   top: 30px;
  //   font-size: 14px;
  //   font-weight: 400;
  //   max-width: 100%;
  // }
  @media only screen and(max-device-width:$small) {
    margin-top: 35px;
  }

  @media only screen and(max-device-width:$landscapetab) {
    margin-top: 35px;
  }
}

.joinedCrews {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 300 !important;
  line-height: 1.2;
  font-size: 18px !important;
  color: #f5f5f5 !important;
  margin-left: 10px;
  margin-bottom: -20px;

  @media only screen and(max-device-width:$small) {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

.locationIcon {
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    // width: 15px;
    height: 15px;
  }

  margin-left: 5px;
  margin-right: -5px;

  span {
    text-align: start;
    font-size: 14px;
  }
}

.ownership {
  position: absolute;
  top: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;

  span {
    width: 110px;
    height: 30px;
  }
}

.bluebullet {
  width: 20px;
  height: 20px;
  background-color: #387aff;
  border-radius: 50%;
  margin-right: 5px;
  /* Adjust spacing as needed */
  position: absolute;
  top: 10px;
  left: 15px;
}

.orangebullet {
  width: 20px;
  height: 20px;
  background-color: #e56424;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  top: 10px;
  left: 15px;
}

.notification {
  background-color: #e56424;
  border-radius: 40px;
  margin-left: auto !important;
  padding: 5px 12px;
}

.wrap-all {
  margin: 30px 10px;

  .spotLights-parent {

    // padding: 0px 16px;
    .spotlight {
      margin-bottom: 25px;

      .spotlight-header {
        display: flex;
        gap: 5px;
        align-items: center;
        margin-bottom: 20px;
      }

      .spotlight-items {

        // padding: 15px;
        // gap: 20px;
        .spotlight-item {
          position: relative;
          padding-right: 10px;
          cursor: pointer;

          .thumbnail {
            border-radius: 10px;
            width: 130px;
            width: 100%;
            height: 230px;
          }

          .user {
            width: 100%;
            gap: 5px;
            position: absolute;
            display: flex;
            align-items: center;
            padding-left: 5px;
            bottom: 10px;

            img {
              width: 25px;
              border-radius: 50%;
            }

            p {
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: $small) {
    margin: 15px 0;
  }

  .garage-section {
    h2 {
      font-weight: 300;
      font-size: 24px;
      line-height: 35px;
      margin-bottom: 10px;
    }

    h3 {
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      display: flex;
      align-items: center;

      @media only screen and (max-device-width: $small) {
        font-size: 16px;
        font-weight: 500;
      }

      span {
        background: #387aff;
        border-radius: 50%;
        margin-left: 5px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-device-width: $small) {
          font-weight: 400;
        }
      }
    }

    .garage-row {
      grid-template-columns: 1fr 1fr;
      display: grid;
      gap: 30px;
      margin-top: 15px;

      @media only screen and (max-device-width: $small) {
        grid-template-columns: 1fr;
        gap: 15px;
      }

      .garage-block {
        position: relative;
        overflow: hidden;

        .stars {
          margin-bottom: 15px;

          i {
            filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.25));
            font-size: 22px;
          }
        }

        .model {
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          color: #ffffff;
        }

        .name {
          display: flex;
          font-weight: 400;
          font-size: 20px;
          align-items: flex-start;
          line-height: 25px;
          margin-top: 5px;
          color: #ffffff;

          span {
            background: #387aff;
            border-radius: 40px;
            color: #fff;
            font-size: 10px;
            line-height: 10px;
            padding: 5px 10px;
            margin-left: 10px;
          }
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          background: linear-gradient(18.24deg,
              rgba(4, 5, 6, 0.8) 22.97%,
              rgba(4, 5, 6, 0) 71.94%);
          width: 100%;
          height: 50%;
        }

        img {
          width: 100%;
          height: 320px;
          object-fit: cover;
          transition: all ease 0.4s;
        }

        .info {
          position: absolute;
          z-index: 2;
          bottom: 0;
          padding: 30px;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .crews-section {
    margin: 60px 0px;

    @media only screen and (max-device-width: $small) {
      margin: 20px 0px;
    }

    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 35px;

      @media only screen and (max-device-width: $small) {
        font-size: 16px;
        font-weight: 500;
      }

      span {
        color: #387aff;
        text-align: center;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .crews-row {
      grid-template-columns: 1fr 1fr 1fr;
      display: grid;
      gap: 30px;
      margin-top: 15px;

      @media only screen and (max-device-width: $small) {
        grid-template-columns: 1fr;
      }
    }

    .crews-block {
      position: relative;
      overflow: hidden;
      border-radius: 8px;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(18.24deg,
            rgba(4, 5, 6, 0.8) 22.97%,
            rgba(4, 5, 6, 0) 71.94%);
        width: 100%;
        height: 50%;
      }

      .main-img {
        width: 100%;
        height: 220px;
        object-fit: cover;
        transition: all ease 0.4s;
      }

      .info {
        position: absolute;
        z-index: 2;
        bottom: 0;
        padding: 30px;
        color: #ffffff;

        .name {
          font-weight: 400;
          font-size: 20px;
          line-height: 25px;
          display: flex;
          align-items: self-start;

          img {
            margin-left: 10px;
          }
        }

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          margin-top: 10px;
        }
      }

      &:hover {
        .main-img {
          transform: scale(1.1);
        }
      }
    }
  }

  .crews-joined {
    h2 {
      display: flex;
      align-items: center;
      font-size: 12px !important;
      justify-content: space-between;
      font-weight: 500 !important;
      color: #8e8e93;

      span {
        color: #387aff;
        text-align: center;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .crew-list-content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;

      @media only screen and (max-device-width: $small) {
        grid-template-columns: 1fr;
        padding: unset;
      }

      .list-block {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 0px 0px 20px 0px;
        border-bottom: 1px solid #212225;
        margin-bottom: 0px;

        img {
          border-radius: 10px;
          border: 1px solid #323334;
          width: 65px;
          height: 65px;
          object-fit: cover;

          @media only screen and (max-device-width: $small) {
            width: 40px;
            height: 40px;
          }
        }

        .list-info {
          h4 {
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
            display: flex;
            gap: 15px;
            align-items: self-start;

            @media only screen and(max-device-width:$small) {
              font-size: 16px;
              font-weight: 600;
            }
          }

          p {
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
          }
        }
      }
    }
  }

  .container {
    .posts-data {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 15px;
      margin-bottom: 30px;

      @media only screen and (max-device-width: $small) {
        grid-template-columns: 1fr;
        gap: 8px;
      }

      .img-holder {
        overflow: hidden;
        transition: all ease 0.3s;
        position: relative;

        picture {
          width: 100%;
          min-height: 320px !important;
          object-fit: cover;
          position: unset !important;

          img {
            width: 100%;
            height: 320px;
            object-fit: cover;
            transition: all ease 0.3s;
            border-radius: 10px;

            @media only screen and (max-device-width: $small) {
              width: 100%;
              height: 126px;
            }

            @media only screen and (max-device-width: $x-small) {
              height: 100px;
            }
          }
        }
      }
    }
  }
}

.user-page-container {
  grid-template-columns: 15% calc(85% - 25px) !important;
  display: grid !important;
  gap: unset !important;
  max-width: 1280px !important;
  margin: 0 auto !important;

  @media only screen and (max-device-width: $small) {
    grid-template-columns: 1fr !important;
    padding: unset !important;

    &>div:first-child {
      border: none !important;
    }
  }
}

.user-profile-modal {
  height: auto;

  .build-followers {
    font-size: 15px;
    font-weight: 400;
    font-family: Inter;
    color: #f5f5f5;
    text-align: center;
  }
}

.crew-list-user-profile {
  height: auto !important;
}

.user-info-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  min-width: 260px;
}

/* Medium devices (landscape tablets, 768px and up) */

// .follow-button:hover{
//     background-color: transparent;
//     color:white;
//     border-color: white;

// }

//responsiveness code Danyal Hamid