.main-div {
    width: 400px;
    height: 770px;
    background-color: #121313;
    position: absolute;
    z-index: 9999;
    left: 25.75%;
    overflow-y: auto; // Add this line to enable vertical scroll if needed

    .wrapper-drawer {
        display: flex;
        width: 380px;
        justify-content: space-between;
        padding: 20px;
        border: 1px solid #212225;
    }

    .notifications {
        width: 380px;
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: space-between;
        

        .noti-img {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
                width: 40px;
                height: 40px;
            }

            p {
                font-weight: 400;
                font-size: 12px;
                letter-spacing: -0.08px;
            }
        }

        .arrow-down {
            p {
                font-weight: 400;
                font-size: 12px;
                color: c#F5F5F5;
                background-color: #3a9B58;
                padding: 2px 16px 2px 16px;
                border-radius: 50px;
            }
        }
    }
}