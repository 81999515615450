$small: 599px;
$tablet: 600px;
$landscapetab: 768px;
$largeTablet: 1023px;
.contact-page {
    .every-car {
      @media (max-width: $small) {
        align-items: center;
      }

      @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
        align-items: center;
      }

      .title {
        h1 {
          font-size: 48px;
          font-weight: 700;
          font-family: Inter;
          line-height: 58px;

          @media (max-width: $small) {
            text-align: center;
            font-size: 36px;
          }

          @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
            text-align: center;
            font-size: 36px;
          }
        }

        p {
          font-size: 20px;
          font-weight: 300;
          font-family: Inter;

          @media (max-width: $small) {
            text-align: center;
            font-size: 16px;
          }

          @media (min-device-width: $tablet) and (max-device-width: $largeTablet) {
            text-align: center;
            font-size: 16px;
          }
        }
      }

      // .watch-ved {
      //     .play-icon {
      //         background-color: #387AFF;
      //         padding: 10px 25px;
      //         font-size: 18px;
      //         font-family: Inter;
      //         font-weight: 500;
      //         border-radius: 10px;
      //         display: flex;
      //         align-items: center;
      //         gap: 5px;
      //     }

      //     // @media (max-width: $small) {
      //     //     // justify-content: center;
      //     // }
      // }
      .video-responsive {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 aspect ratio */
        height: 0;
        overflow: hidden;
        @media (max-width: $landscapetab) {
          position: unset;
          padding: unset;
          height: 100%;
          width: 100%;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @media (max-width: $landscapetab) {
            position: unset;
            // padding: unset;
          }
        }
      }
    }
  }

