// .for-video{
//     // video{
//     //     height: 853px;
//     // }
//     scroll-snap-align: start;
// }
.stories-container {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }

  .for-video {
    scroll-snap-align: start;
    height: 100vh;
    .muted-class {
      position: absolute;
    }
  }

  .custom-header {
    // position: relative;

    .wrap-stories {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      // margin-bottom: 20px;
      // position: absolute;
      // top: 10px;
      width: 100%;
      max-height: 100px;

      .image-stories {
        display: flex;
        align-items: center;

        .heading-stories {
          margin-left: 10px;

          h4 {
            font-size: 13px;
            font-weight: 400;
            font-family: Inter;
            color: #f5f5f5;
            margin: unset;
          }

          p {
            font-size: 13px;
            font-weight: 400;
            font-family: Inter;
            color: #f5f5f5;
          }
        }
      }

      button {
        padding: 8px 20px;
        font-size: 13px;
        background-color: transparent;
        font-weight: 400;
        font-family: Inter;
        color: #f5f5f5;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .wrap-footer {
      position: absolute;
      bottom: 0;

      width: 100%;
      display: flex;
      align-items: flex-end;
      // justify-content: space-around;
      gap: 30px;

      .custom-footer {
        background-color: #12131380;
        border-radius: 10px;
        max-width: 370px;
        padding: 0 10px;
        margin: 0 0 10px 10px;

        .model {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .image-year {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              border-radius: 50%;
            }

            .shared-vehicle {
              span {
                font-size: 11px;
                font-weight: 400;
                font-family: Inter;
                color: #8e8e93;
              }

              p {
                font-size: 13px;
                font-weight: 400;
                font-family: Inter;
                color: #f5f5f5;
              }
            }
          }
        }

        .elegance {
          p {
            font-size: 13px;
            font-weight: 400;
            font-family: Inter;
            color: #f5f5f5;
          }

          .more {
            padding-bottom: 5px;
            cursor: pointer;

            span {
              font-size: 11px;
              font-weight: 400;
              font-family: Inter;
              color: #8e8e93;
            }
          }
        }
      }

      .chevron-down {
        display: flex;
        flex-direction: column-reverse;

        .same-chevron {
          width: 50px;
          height: 50px;
          background-color: #12131380;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          cursor: pointer;

          span {
            font-size: 13px;
            font-weight: 400;
            font-family: Inter;
            color: #f5f5f5;
          }
        }
      }
    }
  }

  .comment-overlay {
    // margin: 0 10px;
    z-index: 9999;
    position: absolute;
    bottom: 30px;
    left: 10px;

    .overlay-content {
      height: 44px;

      .comment-input {
        position: relative;

        textarea {
          background-color: #ffffff05;
          border: 1px solid #f5f5f5;
          outline: none;
          border-radius: 6px;
          color: #f5f5f5;
          max-width: 450px;
          width: calc(100% - 0px);

          &::-webkit-input-placeholder {
            font-size: 15px;
            font-weight: 400;
            font-family: Inter;
            color: #8e8e93;
            padding: 10px 10px;
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .comm-send {
          // background-color: red;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }
  }
}
