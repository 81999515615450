$small: 599px;
$medium: 600px;
$tablet: 990px;

.car-banner {
  .stars {
    display: flex;
    font-size: 20px;

    i {
      color: #387aff;
      padding-right: 5px;
    }
  }

  p span {
    color: #387aff;
    cursor: pointer;
  }

  .rating-wrapper-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }

  .rating-wrapper-btns .action-btns .vehicleButton {
    @media only screen and (max-device-width: $tablet) {
      padding: 5px 12px; 
    }
  }

  h1 {
    font-weight: 300;
    font-size: 28px;
    line-height: 42px;
    margin: 15px 0px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;

    span {
      border: 1px solid #323334;
      border-radius: 8px;
      padding: 8px 16px;
      color: #ffffff;
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      @media only screen and (max-device-width: $tablet) {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

.banner-img {
  position: relative;

  img {
    max-height: 576px;
    object-fit: cover;
    width: 100%;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(
      180deg,
      rgba(24, 25, 26, 0) 0%,
      rgba(24, 25, 26, 0.9) 81.77%
    );
    bottom: 0;
  }

  .short-info {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    display: flex;
    z-index: 3;
    align-items: center;
    padding: 30px;

    @media only screen and (max-device-width: $small) {
      bottom: 0%;
      padding: 10px 20px;
    }

    .clm {
      padding: 0px 30px;
      position: relative;
      text-align: center;
      cursor: pointer;

      h3 {
        font-weight: 600;
        font-size: 20px;
        margin: 0px;
        line-height: normal;
      }

      p {
        font-weight: 300;
        font-size: 16px;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        width: 1px;
        height: 48px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.1;
        background: #fff;
      }
    }
  }
}

// .extra-info {
//     margin: 30px 0px;
//     display: flex;
//     gap: 15px;
//     flex-wrap: wrap;

//     .info-block {
//         padding: 5px;
//         display: flex;
//         flex-direction: column;
//         gap:20px;
//         background: #212225;
//         border-radius: 10px;
//         text-align: center;
//         flex: 0 0 calc(100% / 5 - 12px);
//         max-width: calc(100% / 5 - 12px);

//         h3 {
//             font-weight: 600;
//             font-size: 22px;
//             margin: 0px;
//             display: flex;
//             justify-content: center;
//             align-items: baseline;
//             max-width: 100px;
//             margin: 0 auto;
//             gap: 8px;

//             span {
//                 font-size: 15px;
//                 line-height: 20px;
//                 text-align: left;
//             }
//         }

//         p {
//             color: #8E8E93;
//             display: flex;
//             margin-top: 5px;
//             justify-content: center;
//             align-items: center;
//             font-size: 14px;

//             svg {
//                 margin-right: 10px;
//             }
//         }
//     }
// }

.two-clm-posts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @media only screen and (max-device-width: $small) {
    gap: 7px;
  }

  .img-holder {
    overflow: hidden;
    transition: all ease 0.3s;
    cursor: pointer;
    border-radius: 5px;
    position: relative;

    img {
      width: 100%;
      height: 220px;
      object-fit: cover;
      transition: all ease 0.3s;

      @media only screen and (max-device-width: $small) {
        height: 150px;
      }
    }

    .overlay-text {
      position: absolute;
      bottom: 0;
      // background: #387AFF;
      width: 100%;
      transition: 0.5s ease;
      opacity: 1;
      color: #f5f5f5;
      font-family: Inter;
      font-weight: 300;
      font-size: 18px;
      height: 220px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-device-width: $small) {
        height: 150px;
      }
    }

    &:hover img {
      transform: scale(1.1);
    }
  }
}

.show-more-p {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #387aff;
  margin-bottom: 30px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover {
    color: #fff;

    svg {
      transition: all ease 0.3s;
      transform: translateY(3px);
    }
  }
}

.name {
  word-break: break-all;
}
