$small: 599px;

.launches {
  @media (max-width: $small) {
    padding-top: unset !important;
  }

  .Contact-form {
    background-color: #212225;
    max-width: 400px;
    //  max-height: 465px;
    width: 100%;
    border-radius: 10px;

    .line-pass {
      h4 {
        font-size: 24px;
        font-weight: 700;
        font-family: Inter;
        text-align: center;
      }

      p {
        font-size: 18px;
        font-weight: 300;
        font-family: Inter;
        text-align: center;
      }
    }

    .pass-input {
      .form-data {
        text-align: center;

        .custom-input {
          border-color: #8e8e93;
          border-radius: 4px;
          color: #8e8e93;
          max-width: 360px;
          width: 100%;
          height: 49px;
          font-size: 12px;
          font-weight: 400;
          font-family: Inter;
        }
        .custom-textarea {
          border-color: #8e8e93;
          border-radius: 4px;
          color: #8e8e93;
          max-width: 360px;
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          font-family: Inter;
          position: relative;
          padding: 60px 13px;
          box-sizing: border-box;
          padding-top: 20px;
      

          &::-webkit-input-placeholder{
              position:absolute;
              left: 15px;
              top: 10px;
          }
        }

        .error {
          font-size: 12px;
          font-weight: 400;
          font-family: Inter;
          text-align: left;
          padding-left: 13px;
          padding-top: 5px;
          color: #ed4337;

          @media (max-width: $small) {
            padding-left: unset;
          }
        }
      }
    }

    .submit {
      p {
        font-size: 10px;
        font-weight: 300;
        font-family: Inter;
        color: #f5f5f5;
        //  letter-spacing: 0.37px;
        line-height: 17px;
        text-align: justify;
        span {
          color: #387aff;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .submit-btn {
      text-align: center;

      button {
        background-color: #387aff;
        max-width: 283px;
        width: 100%;
      }
    }
  }
}
