.home-banner {
    background: url(./../../assets/banner-bg.jpg) center center no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 200px;
    padding-bottom: 60px;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.8);
    }

    .container {
        position: relative;
        z-index: 2;
        max-width: 990px;
        margin: 0 auto;
        text-align: center;
    }

    h1 {
        font-weight: 600;
        // font-size: 3rem;
        line-height: 40px;
        margin-bottom: 20px;
    }

    form {
        display: flex;
        align-items: center;
        border: #ddd solid 1px;
        background: rgba(41, 41, 44, 0.5);
        border: 1px solid #8e8e93;
        box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(5px);
        border-radius: 4px;
        height: 49px;
        line-height: 49px;
        max-width: 900px;
        margin: 0 auto;

        .search {
            flex: auto;
            background: none;
            background: none;
            border: none;
            padding-left: 15px;
            outline: none;
            color: #fff;
            border-right: 1px solid #8e8e93;

            &:focus {
                outline: none;
            }
        }

        .selectOption {
            background: none;
            border: none !important;
            outline: non !important;
            color: #8e8e93;
            width: 180px;
            padding: 0px 15px;

            &:focus {
                outline: none;
                border: none;
            }
        }

        button {
            background: none;
            border: none;
            outline: none;
            color: #8e8e93;
            border-left: 1px solid #8e8e93;
            padding: 0px 30px;
            margin-left: 15px;
            text-align: end;
        }
    }

    p {
        max-width: 652px;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: #fcfcfe;
        margin: 20px auto 70px auto;
    }

    // .quick-search {
    //     max-width: 652px;
    //     margin: 0 auto;
    //     display: flex;
    //     flex-wrap: wrap;
    //     align-items: center;
    //     justify-content: space-evenly;

    //     h4 {
    //         color: #8e8e93;
    //         font-weight: 400;
    //         font-size: 15px;
    //         line-height: 20px;
    //         margin-bottom: 10px;
    //     }

    //     span {
    //         border: 1px solid rgba(80, 85, 92, 0.6);
    //         border-radius: 20px;
    //         padding: 10px 25px;
    //         font-size: 12px;
    //         line-height: 14px;
    //         font-size: 14px;
    //         cursor: pointer;
    //         margin-bottom: 10px;
    //     }
    // }
}
.specifications{
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 20px;
    span{
        padding: 12px;
        border-radius: 23px;
        border: 1px solid #8E8E93;
        margin: auto;
    }
}