$small: 599px;
$landscapetab: 801px;
/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (max-width: 1399px) {
    .cta-section .info {
        padding: 60px 60px 60px 60px;
    }

    .gallery-section .gallery-list .how-block .gallery-img {
        height: 240px;
    }

    .how-to-section .how-img {
        height: 260px;
    }

    .extra-info {
        grid-template-columns: repeat(2, 1fr);
    }

    .user-profile-section .default-btn {
        max-width: 150px;
    }
}

@media (max-width: 1279px) {}

@media (max-width: 991px) {
    .user-profile-section .user-data {
        text-align: center;
        display: block;
    }
    .user-profile-section .user-data .UserName {
        justify-content: center;
    }

    // .user-profile-section .userInfo .followers {
        // flex-direction: column;
    // }

    // .user-profile-section .userInfo .followers .clm {
    //     border-bottom: 0.5px solid rgba(245, 245, 245, 0.26);
    //     border-right: none;
    //     padding: 15px 0px;
    //     margin-right: 0;
    // }

    .garage-section .garage-row,
    .crews-section .crews-row {
        grid-template-columns: 1fr;
    }

    .page-container {
        // grid-template-columns: 1fr !important;
        gap: 0;
        row-gap: 30px;
    }

    .how-to-section {
        margin: 0px;
        padding: 15px;
    }

    .page-container>div:first-child {
        border: none;
    }

    .page-container>div:nth-child(2) {
        border: none;
        padding: 0px 15px;
    }

    .page-container>div:nth-child(3) {
        padding: 0px 15px;
    }

    .post-title {
        text-align: center;
       
        @media (min-device-width: $small) and (max-device-width: $landscapetab) {
            flex-direction: row;
        }

    }

    .post-title .avtar-info {
        margin-top: 10px;
    }

    .user-rating-list,
    .how-to-section .block-list,
    .gallery-section .gallery-list {
        grid-template-columns: 1fr;
    }

    // .modal {
    //     width: 450px;
    //     // border-radius: 22px;

    //     .login-wrapper-for-modal {
    //         .wrapper {
    //             flex: 0 0 100%;
    //         }
    //     }
    // }

  
}

// media query target tablet
@media (max-width: 990px) {
    .page-container {
        // grid-template-columns: 10% 80% calc(30% - 10px) !important;
        // grid-template-columns: 1fr;
        gap: 0;
        row-gap: 30px;
        @media only screen and (max-device-width: $small) {
                grid-template-columns: 100% !important;
                // padding: 0 10px;
            }
    }

    header .container {
        flex-direction: column;

        .rightbar {
            margin-top: 15px;
        }
    }

    .general-post-header .follow-btn {
        margin: 20px auto 0 auto;
    }

    .exploreParts .listing {
        grid-template-columns: 1fr;
    }

    .home-banner form .selectOption {
        display: none;
    }

    .home-banner form button {
        border-left: 0;
        margin-left: 0;
    }

    .cta-section .info {
        padding: 30px;
    }

    .exploreParts .search-filter {
        flex-direction: column;
    }

    .crews-builds {
        grid-template-columns: 1fr;
    }

    footer .copyright .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    footer h4 {
        // margin-top: 20px;
    }

    .extra-info {
        grid-template-columns: repeat(2, 1fr);
    }

    .banner-img {
        margin-top: 30px;
    }

    .banner-img .short-info {
        padding: 15px;
        padding-bottom: 37px;
    }

    .banner-img .short-info .clm {
        padding: 0px 15px;
    }
    .banner-img .short-info .clm h3 {
       font-size: 16px;
       font-weight: 600;
    }
    .banner-img .short-info .clm p {
       font-size: 12px;
       font-weight: 400;
    }

    .filters {
        grid-template-columns: repeat(2, 1fr);
    }

    .avg-rating {
        margin-top: 30px;
        padding: 30px;
    }

    .car-banner {
        padding-top: 30px;
    }

    .login-wrapper .wrapper {
        flex: 0 0 320px !important;
        max-width: 320px !important;
    }

    .login-wrapper-for-modal .wrapper {
        flex: 0 0 320px !important;
        max-width: 320px !important;
    }

    .posts-row {
        grid-template-columns: 1fr;
    }
}

/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 599px) {
//     .modal {
//         width: 356px;
//     }
// }

// media target small screen tablet
@media (min-width: 600px) and (max-width: 744px) {
    .page-container {
        grid-template-columns: 10% 80% calc(30% - 10px) !important;
        gap: 0;
        row-gap: 30px;
    }

    header .container {
        flex-direction: column;

        .rightbar {
            margin-top: 15px;
        }
    }

    .general-post-header .follow-btn {
        margin: 20px auto 0 auto;
    }

    .exploreParts .listing {
        grid-template-columns: 1fr;
    }

    .home-banner form .selectOption {
        display: none;
    }

    .home-banner form button {
        border-left: 0;
        margin-left: 0;
    }

    .cta-section .info {
        padding: 30px;
    }

    .exploreParts .search-filter {
        flex-direction: column;
    }

    .crews-builds {
        grid-template-columns: 1fr;
    }

    footer .copyright .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    footer h4 {
        // margin-top: 20px;
    }

    .extra-info {
        grid-template-columns: repeat(2, 1fr);
    }

    .banner-img {
        margin-top: 30px;
    }

    .banner-img .short-info {
        padding: 15px;
        padding-bottom: 40px;
    }

    .banner-img .short-info .clm {
        padding: 0px 15px;
    }

    .filters {
        grid-template-columns: repeat(2, 1fr);
    }

    .avg-rating {
        margin-top: 30px;
        padding: 30px;
    }

    .car-banner {
        padding-top: 30px;
    }

    .login-wrapper .wrapper {
        flex: 0 0 320px !important;
        max-width: 320px !important;
    }

    .login-wrapper-for-modal .wrapper {
        flex: 0 0 320px !important;
        max-width: 320px !important;
    }

    .posts-row {
        grid-template-columns: 1fr;
    }
}