  .message {
      color: var(--error);
      padding-top: 10px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;

      &.code {
          color: #387AFF;
          cursor: pointer;
      }
  }