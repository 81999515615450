$tablet: 992px;
$mobile: 599px;

.view-all-heading-build {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 30px;

  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;

    .location {
      font-size: 15px;
    }
  }

  a {
    color: #387aff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;

    &:hover {
      color: #fff;

      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
}
.garage-row-build {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  display: grid;
  gap: 8px;
  margin-bottom: 2%;
 

  @media only screen and (max-device-width: $tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-device-width: $mobile) {
    grid-template-columns: 1fr 1fr;
  }

  .garage-block {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    color: #fff;

    .stars {
        display: none;
      margin-bottom: auto;

    //   i {
    //     filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.25));
    //     font-size: 18px;
    //     @media only screen and (max-device-width: 600px) {
    //       font-size: 12px;
    //     }
    //   }
    }

    .model {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      padding-left: 3px;
    //   margin-bottom: 5px;
      @media only screen and (max-device-width: $mobile) {
        margin-bottom: 0;
      }
    }

    .name {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      padding-left: 3px;
    //   margin-bottom: 10px;
      @media only screen and (max-device-width: $mobile) {
        font-size: 12px !important;
        margin-bottom: 0;
      }
    }

    .current {
      background: #387aff;
      border-radius: 40px;
      color: #fff;
      display: inline-block;
      margin-bottom: 6px;
      line-height: 10px;
      margin-bottom: 10px;
      margin-right: auto;
      padding: 4px 6px;
      font-size: 10px;
      // width: 90px;
    }
    .previous {
      background: #e56424;
      border-radius: 40px;
      color: #fff;

      // line-height: 10px;
      padding: 2px 6px;
      font-size: 10px;
      display: inline-block;
      margin-bottom: 6px;
      // margin-right: auto;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(
        18.24deg,
        rgba(4, 5, 6, 0.8) 22.97%,
        rgba(4, 5, 6, 0) 71.94%
      );
      width: 100%;
      height: 50%;
    }

    img {
      width: 100%;
      max-width: 200px;
      height: 180px;
      object-fit: cover;
      transition: all ease 0.4s;
      border-radius: 8px;
    }

    .info {
      position: absolute;
      z-index: 2;
      bottom: 0;
      padding: 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @media only screen and (max-device-width: $mobile) {
        padding: 10px;
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  &.garage-row-two-clm {
    grid-template-columns: 1fr 1fr;
  }
}
