.user-detail-wrapper {
    background: #18191A;
    padding: 30px;
    margin-top: 30px;

    .avtar-in {
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        gap: 10px;

        img {
            width: 60px;
            height: 60px;
            border-radius: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 15px;
            border: 1px solid #000000;
        }

        .name {
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
        }

        .d-flex {
            color: #8E8E93;
        }
    }

    .follow-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;



        &>div {
            border-right: 1px solid rgba(245, 245, 245, 0.26);
            ;
        }

        &>div:last-child {
            border-right: none;
        }

        h6 {
            font-weight: 600;
            font-size: 18px;
            margin: 0px 0px 8px 0px;
        }

        p {
            font-weight: 300;
            font-size: 14px;
        }
    }

    .categories-component {
        display: flex;
        gap: 5px;

        .build-categories {
            p {
                font-size: 11px;
                font-weight: 400;
                color: #f5f5f5;
                border: 1px solid #323334;
                border-radius: 5px;
                padding: 5px 10px;
            }
        }
    }
}

.related {
    .post-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        .posts {
            p {
                font-size: 16px;
                font-weight: 500;
                color: #f5f5f5;
            }
        }
        .view {
            p {
                font-size: 13px;
                font-weight: 400;
                color: #387AFF;
            }
        }
    }
    .image-title{
        .build-image{
            width: 150px;
            img{
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: 5px;
            }
            h6{
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                margin: 10px 0;
            }
        }
    }
}

.user-personal-info {
    line-height: 26px;

    span {
        color: #387AFF;
        cursor: pointer;
    }
}

.activity {
    margin: 15px 0;
    background-color: #18191A;
    padding: 20px;

    h2 {
        font-weight: 300;
        font-size: 20px;
        color: #FFFFFF;
        padding-bottom: 10px;
    }

    .main-content {
        height: 613px;
        overflow: auto;

        .wrap-content {
            .main {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 15px;
                border-bottom: 1px solid #212225;
                padding: 10px 0;
                justify-content: space-between;

                .wrap-main-user {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .main-user {
                        position: relative;

                        .play {
                            position: absolute;
                            top: 20%;
                            left: 20%;
                        }

                        img {
                            width: 45px;
                            height: 45px;
                            object-fit: cover;
                        }

                        video {
                            width: 45px;
                            height: 45px;
                            object-fit: cover;
                        }
                    }

                    .title-text {
                        h6 {
                            margin: unset;
                            font-size: 14px;
                            font-weight: 400;
                            color: #f5f5f5;
                        }

                        p {
                            font-size: 10px;
                            font-weight: 400;
                            color: #387AFF;

                            span {
                                color: #8E8E93;
                            }
                        }
                    }
                }

            }


        }
    }


}

.follow-btn {
    width: 100%;
    text-align: center;
    border: 2px solid #FFFFFF;
    border-radius: 8px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    padding: 10px 0px;
    line-height: 22px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        background: #387AFF;
    }
}

h2 {
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    margin: 30px 0px;
}

.for-you-list {
    .foryou-user {
        border-bottom: 1px solid #323334;
        padding: 15px 0px;

        &:last-child {
            border-bottom: 0;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
        }

        .for-avtar {
            display: flex;
            margin-bottom: 15px;
            align-items: center;

            img {
                width: 36px;
                height: 36px;
                border-radius: 32px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 15px;
                border: 1px solid #000000;
            }

            .name {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
            }

            .d-flex {
                color: #8E8E93;
                font-weight: 300;
                font-size: 12px;
            }
        }
    }
}

.main-img {
    width: 100%;
}

@media screen and (max-width: 767px) {
    .crews-joined .crew-list-content {
        grid-template-columns: 1fr;
    }
}

.sticky-clm-2 {
    position: sticky;
    right: 0;
    top: 130px;

    .stick-clm-for-all {
        margin-top: 30px;
    }

    h2 {
        color: var(--themewhite);
        font-weight: 300;
        font-size: 20px;
        line-height: 25px;
        margin: 20px 0px 0px 0px;
    }
}