.story-skeleton {
    position: relative;
    .header-skeleton {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .footer-skeleton {
        // width: 100%;
        // position: absolute;
        // bottom: 0px;
        // text-align: center;
        position: absolute;
        bottom: 10px;
        left: 10px;

        .wrap-footer {
            position: relative;

            .content-footer {
                position: absolute;
                top: 10px;
                left: 10px;
            }
        }
    }
}