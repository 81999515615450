$small: 599px;
$medium: 600px;
/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
$landscapetab: 801px;

.page-container {
    display: grid;
    grid-template-columns: 15% 55% calc(30% - 75px);
    gap: 30px;
    max-width: 1280px;
    margin: 0 auto;

    &>div:first-child {
        border-right: 1px solid #323334;

        @media only screen and (max-device-width: $small) {
            border: none;
        }
    }

    .top-post {
        text-align: center;
        margin-top: 10px;

        h6 {
            font-size: 14px;
            font-weight: 400;
            color: #f5f5f5;
            margin: unset;
        }

        p {
            font-size: 12px;
            font-weight: 400;
            color: #387AFF;
        }
    }

    .related-post {
        display: flex;
        align-items: center;
    }
}

.sticky-clm .user-detail-wrapper-main {
    margin-top: 0;
    padding: 30px 0px 0px 0px;
}

.profile-links li a img {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.profile-links li a svg {
    width: 25px;
    height: 25px;
    object-fit: cover;
}


@media (max-width: 599px) {
    .page-container {
        grid-template-columns: 1fr;

    }
}