$tablet:990px;

.wrapper-data {
     display: flex;
     align-items: center;
     gap: 10px;
    //  margin-top: 10px;
     .name {
         .username-liked {
             font-size: 12px;
             font-weight: 400 !important;
             color: #8E8E93;
             @media only screen and (max-device-width: $tablet) {
                font-size: 10px;
             }
         }
     }
     .liked-in-page {
        display: flex;
        margin-left: 9px;
         .profile-link-image {
            overflow: hidden;
            margin-left: -10px;
             .car-image {
                 width: 26px;
                 height: 26px;
                 img{
                    width: 26px;
                    height: 26px;
                    object-fit: cover;
                 }
             }
         }
     }
 }
 .desc-text{
    margin-top: 1%;
    .show-more-desc{
        .text{
            color: #F5F5F5;
            font-size: 14px;
            font-weight: 400;
            font-family: Inter;
        }
    }
 }