.membershipDashboardHeader {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    position: sticky; 
    top: 0; 
    width: 100%; 
    z-index: 1000; 
    background-color: #18191A;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
    align-items: center;

    img button {
        width: 24px;
        height: 24px;
    }

    // p {
    //     width: 74px;
    //     height: 14px;
    // }

    .close-modal-button {
        background-color: #18191A;
        border: none;

    }
}


.memberLevel {
    background-color: #212225;
    padding: 20px 15px;
    border-radius: 10px;
    margin: 10px 0;
    height: fit-content;

    .memberLevelHeader {
        display: flex;
        justify-content: space-between;
        .image-username {
            display: flex;
            gap: 5px;
    
            img {
                width: 45px;
                border-radius: 50%;
            }
    
            .username {
                display: flex;
                flex-direction: column;
                max-width: 80%;
    
                p {
                    font-size: large;
                }
            }
        }

        .circularProgress {
            width: 40px;
        }
    }

    .memberLevelBody {
        padding: 20px 0;
        min-height: 50%;
        position: relative;

        .newbie {
            width: 100%;
            min-height: 200px;
            background-image: url('../../../assets/membershipDashboard/Group\ 41757.png');
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            .newbie-paragraph {
                margin-top: 45px;
                width: 150px;

                p {
                    font-size: small;
                    text-align: center;
                }

            }

            .newbie-button {
                display: flex;
                margin-bottom: 10px;

                button {
                    width: 140px;
                    background-color: #387AFF;
                    border-radius: 10px;
                    padding: 10px 15px;
                    border: none;
                    color: #FFFFFF;
                }
            }

            .learn-more {
                p {
                    font-size: small;
                    color: #387AFF;
                }
            }
        }

        .mySwiper {
            position: relative;
            width: 100%;

            .swiper-slide-next {
                border: none !important;
                left: 20% !important;
            }

            .swiper-slide-prev {

                right: 20% !important;

            }

            .swiper-slide-active {
                position: relative !important;
                // opacity: 1 !important;


            }

            .swiper-pagination {
                position: absolute;
                bottom: 20%;

            }

            .swiper-pagination-bullet {
                background: #121313;
                width: 5%;
                height: 5px;
                border-radius: 29px;
                margin: 0;
            }

            .swiper-pagination-bullet-active {
                background: white;
            }

            .swiper-pagination-bullet-inactive {
                opacity: 1;
                background: white;
            }

            .swiper-slide {
                img{
                    width: 100%;
                }
                cursor: pointer;
                position: relative;
                opacity: 0.6;
                transition: opacity 0.3s;
                .tier-information {
                    position: absolute;
                    bottom: 25%;
                    width: 100%;
                    p{
                        text-align: center ;
                        font-size: xx-small;
                        
                    }
                    .Bullets{
                        display: flex;
                        justify-content: center;
                        height: 15px;
                        align-items: center;
                        padding: 0px 7px;
                        gap: 2px;
                        .Bullet-Item{
                            width: 19px;
                            height: 5px;
                            border-radius: 29%;
                            
                        }
                    }
                    .lockedRanks{
                        display: flex; 
                        flex-direction: column;
                        align-items: center;
                        
                        img{width: 16px ;
                        height: 16px ;
                        }
                        p{
                            width: 80%;
                            font-size: 11px !important;
                            line-height: 14px !important;
                        }
                    }
                }
                
            }


        }



    }












    .memberFooter {
        display: flex;
        justify-content: center;
        width: 100%;
        button {
            
            display: flex;
            justify-content: center;
            padding: 0px 15px;
            gap: 15px;
            background-color: #212225;
            border: 1px solid #323334;
            border-radius: 10px;
            padding: 7px 10px;
            color: white;

            .connected {
                background-color: green;
            }

            img {
                margin-top: 2px;
            }

            p {
                text-align: start;
                font-size: x-small;
            }
        }


    }
}

.statistics {
    margin: 10px 0;

    .statistic-data {
        display: flex;
        gap: 10px;
        padding: 15px 0px;

        .cx-points {
            cursor: pointer;
            width: 50%;
            padding: 10px;
            background-color: #212225;
            gap: 20px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

        }

        .active-points {
            border: 2px solid #387aff
        }

    }

    .linkToSpeedSociety {
        gap: 15px;
        border-radius: 10px;

        .insideLinkToSpeedSociety {
            padding: 30px 15px 15px 15px;
            gap: 15px;
            border-radius: 15px;

            .logos {
                display: flex;
                gap: 12px;
                justify-content: center;
            }
        }

        .linkToSocietyBtn {
            display: flex;
            justify-content: center;

            button {
                background-color: #387AFF;
                border-radius: 10px;
                padding: 15px 10px;
                border: none;
                color: #FFFFFF;

            }
        }

        .speed-society-statistics {
            display: flex;
            justify-content: space-between;

            .connectionButton {
                border: none;
                background-color: #8E8E93;
                padding: 5px 10px;
                gap: 8px;
                display: flex;

                img {
                    margin-top: 6px;
                }

                p {
                    color: #F5F5F5;
                }

                border-radius: 6px;

                &.connected {
                    background-color: #3A9B5B;
                }

            }
        }

        .speedSocietyAffiliate {
            display: flex;
            justify-content: space-between;
            padding: 10px 0px;
        }

        .AboutSpeedSociety {
            display: flex;
            justify-content: space-between;
            padding: 10px 0px;
            color: #8E8E93;
        }
    }

    .speed-store {
        display: flex;
        padding: 15px;
        justify-content: center;
        gap: 10px;

        .speed {
            width: 50%;
            padding: 10px;
            background-color: #212225;
            gap: 20px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            img {
                width: 24px;
            }
        }

        .cart {
            width: 50%;
            padding: 10px;
            background-color: #212225;
            gap: 20px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            img {
                width: 24px;
            }
        }
    }
.connected-store{
   .spinner{
    height: 216px;
    display: flex;
    justify-content: center;
    align-items: center;
   }
    .connected-speed-store {
        display: flex;
        padding: 15px 0px;
        justify-content: center;
        gap: 10px;

        p {
            color: #8E8E93;
        }

        .total {
            width: 50%;
            padding: 10px;
            background-color: #121313;
            gap: 15px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            .entries-image {
                display: flex;
                gap: 5px;

                img {
                    width: 24px;
                }
            }

            .spent-image {
                display: flex;
                justify-content: center;
                gap: 5px;

                img {
                    width: 12px;
                }
            }

            .total-entries-data {
                gap: 5px;

                .entries-text {
                    font-size: xx-large;
                }

                .connected {
                    color: #ffffff; 
                }

                .disconnected {
                    color: #8E8E93; 
                }
            }

            .points-last-week {
                display: flex;

                p {
                    color: #52FF00;
                }
            }

        }
    }

}

    .total-carvonix-points {
        padding: 15px 0px;
        justify-content: center;
        gap: 10px;

        p {
            color: #8E8E93;
        }

        .total {
            padding: 10px;
            background-color: #121313;
            gap: 15px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            .carvonix-image {
                display: flex;
                gap: 5px;

                img {
                    width: 24px;
                }
            }

            h1 {
                font-size: x-large;
                line-height: normal;
            }

            .points-last-week {
                display: flex;

                p {
                    color: #52FF00;
                }
            }

        }

    }
}

.modal-warning{
    z-index: 1000;
    .warning-modal-body{
        padding: 15px 25px;
        margin: 20px 0px;
        p{
          text-align: center;
          margin: 10px 0px;
        }
        .warningImage{
          display: flex;
          justify-content: center;
        }
        .linkToSocietyBtn{
          display: flex;
          flex-direction: column;
          
          button{
              background-color:#212225;
              border-radius: 10px;
              padding: 15px 10px;
              border: none;
              color: #FFFFFF;
              margin: 5px 0px;
          }
      }
      }
}


@media (max-width: 1265px) {
    .membershipDashboardBody {
        p {
            font-size: small !important;
        }
    }
    .memberLevel{
        .memberLevelHeader{
            .image-username{
                .username{
                    p{
                        font-size: small;
                    }
                    
                }
            }
        }
        .memberLevelBody{
            // .tier-information {
            //     bottom: 25%;
            //     p{
            //         font-size: xx-small;
                    
            //     }
            //     .Bullets{
            //         display: flex;
            //         justify-content: center;
            //         height: 15px;
            //         align-items: center;
            //         .Bullet-Item{
            //             width: 15px;
            //             font-size: xx-large;
            //             border-radius: 29%;
            //         }
            //     }
            // }

            
        }
        .memberFooter {
        
            button{
                width: 100%;
                gap: 0px;
                p{
                    
                    font-size: x-small;
                }
                img{
                    margin-top: 0;
                }
            }
        }
}
}

@media (min-width:651px )and( max-width: 991px) {
    .membershipDashboardBody {
        p {
            font-size: xx-small !important;
        }
    }
    .memberLevel{
        .memberLevelHeader{
            .image-username{
                .username{
                    p{
                        font-size: x-small;
                    }
                    
                }
            }
        }
    .memberFooter {
       
        button{
            
            width: 90%;
            gap: 10px;
            p{
                text-align: start;
                font-size: large;
            }
            img{
                margin-top: 5px;
            }
        }
    }
    .memberLevelBody{
        .mySwiper {
          

            .swiper-slide {

                .tier-information {
                    position: absolute;
                    bottom: 25%;
                    width: 100%;
                    p{
                        text-align: center ;
                        font-size: large;
                        
                    }
                    .Bullets{
                        display: flex;
                        justify-content: center;
                        height: 15px;
                        align-items: center;
                        padding: 0px 7px;
                        gap: 2px;
                        .Bullet-Item{
                            width: 30px;
                            height: 7px;
                            border-radius: 29%;
                            
                        }
                    }
                    .lockedRanks{
                        img{width: 20px !important;
                        height: 20px !important;
                        }
                        p{
                            width: 70%;
                            font-size: 18px !important;
                            line-height: 20px !important;
                        }
                    }
                }
            }


        }

    }
}
}

@media (max-width: 650px) {
    .membershipDashboardBody {
        p {
            font-size: xx-small !important;
        }
    }
    .memberLevel{
        .memberLevelHeader{
            .image-username{
                img{
                    width: 33px;
                }
                
                .username{
                    p{
                        font-size: x-small;
                    }
                    
                }
            }
            .circularProgress{
                width: 33px;
            }
        }
    .memberFooter {
        width: 100%;
        button{
            width: 70%;
            gap: 0px;
            p{
                text-align: start;
                font-size: xx-small;
            }
            img{
                margin-top: 0;
            }
        }
    }
}
.statistics{
    .linkToSpeedSociety{
        .linkToSocietyBtn{
            button{
                
                    font-size: small;
            
            }
        }
        .AboutSpeedSociety{
            p{
                font-size: x-small;
            }
        }
        p{
            font-size: x-small;
        }
        .connected-speed-store{
            p{
                font-size: x-small;
            }
            .total img{
                width: 15px;
            }
            .total{
                .entries-image{
                    p{
                        font-size: x-small;
                    }
                }
                p{
                    font-size:x-small ;
                }

                .spent-image{
                    img{
                        width: 10px;
                    }
            } 
        }
        }
        .speed-society-statistics {
            p{
                margin-top: 6px;
                font-size: x-small;
            }

            .connectionButton {

                width: 35%;
                justify-content: center;
                img{
                    width: 10%;
                    margin-top: 4px;
                }
                p{
                    margin-top: 0px;
                    font-size: x-small;
                }
    }
}
    }
}
}

  