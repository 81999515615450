
body {
  background: #121313;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 16px;
  overflow: visible !important;
}

a {
  text-decoration: none;
  transition: all ease 0.4s;
  color: #387aff;
}

* {
  margin: 0px;
  padding: 0px;
}

ul,
li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

img {
  max-width: 100%;
}

p {
  margin-bottom: 0px;
}

h2 {
  font-weight: 700;
  font-size: 42px;
  line-height: 40px;
  letter-spacing: 0.374px;
}

.default-select {
  height: 40px;
  width: 100%;
  background: #1e1e1f;
  border: 1px solid #8e8e93;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  border-radius: 4px;
  color: #fff;
  padding: 0px 15px;
}

.default-btn {
  background: #387aff;
  color: #fff;
  display: inline-block;
  border-radius: 30px;
  padding: 10px 30px;
  text-decoration: none;
  border: #387aff solid 1px;
}

.default-btn:hover {
  background-color: #000;
  color: #fff;
}

.btn {
  background: #323334;
  border-radius: 8px;
  padding: 10px 40px;
  color: #f5f5f5;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
}

.btn:hover {
  background: #387aff;
  color: #fff;
}

.btn.btn-blue {
  background: #387aff;
  color: #fff;
}

.btn.btn-blue:hover {
  background: #000;
  color: #fff;
}
.profile-link {
  width: 60px;
  flex: 0 0 60px;
  color: var(--themeWhite);
}
.profile-link:hover {
  color: var(--themeWhite);
}
.user-profile-section .container {
  max-width: 100%;
}
.modal-backdrop {
  z-index: -1;
}
.modal-content{
  background:#212225;
  width: 95%;
}
.modal-body{
  padding: unset;
  overflow: visible !important;
}
@media  (max-width: 990px){
  .btn.btn-blue {
    padding: 7px;
  }
}
@media  (max-width: 376px){
  .btn.btn-blue {
    font-size: 12px;
  }
}
