.cta-section {
    margin: 70px 0px;

    .container {
        background: #18191A;
    }

    .row {
        justify-content: space-between;
        align-items: end;
    }

    h2 {
        font-weight: 700;
        font-size: 42px;
        line-height: 51px;
        letter-spacing: 0.374px;
        margin-bottom: 20px;
    }

    .info {
        padding: 60px 120px 60px 60px;

        p {
            font-weight: 300;
            font-size: 20px;
            line-height: 34px;
            letter-spacing: 0.374px;
        }

        a.d-flex {
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            color: #387AFF;
            display: inline-block;
            margin: 20px 0px;
            &:hover{
                color: #fff;
            }
        }
    }
}
