$small: 599px;
.privacy-policy{
    h1{
        font-size: 25px;
        font-weight: 400;
        text-align: start;
        margin-top: 40px;
        font-family: Inter;
        color: #F5F5F5;
        @media (max-device-width: $small) {
            font-size: 25px;
          }
    }
    h3{
        font-size: 25px;
        font-weight: 400;
        text-align: start;
        // margin-top: 40px;
        margin: 10px 0;
        font-family: Inter;
        color: #F5F5F5;
        @media (max-device-width: $small) {
            font-size: 25px;
          }
    }
    p{
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;
        font-family: Inter;
        color: #F5F5F5;
        text-align: start;
        @media (max-device-width: $small) {
            font-size: 14px;
          }
    }
    ul {
        padding-left: 2rem;
        li{
            list-style: disc;
           
        }
    }
}