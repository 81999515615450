.user-detail-wrapper-main {
  //   padding: 30px;
  //   margin-top: 30px;

  .avtar-in {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    gap: 10px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 15px;
      border: 1px solid #000000;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
    }

    .d-flex {
      color: #8e8e93;
    }
  }

  .follow-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;

    &>div {
      border-right: 1px solid rgba(245, 245, 245, 0.26);
    }

    &>div:last-child {
      border-right: none;
    }

    h6 {
      font-weight: 600;
      font-size: 18px;
      margin: 0px 0px 8px 0px;
    }

    p {
      font-weight: 300;
      font-size: 14px;
    }
  }
}

.user-personal-info {
  line-height: 26px;

  span {
    color: #387aff;
    cursor: pointer;
  }
}

.follow-btn {
  width: 100%;
  text-align: center;
  border: 2px solid #ffffff;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  padding: 10px 0px;
  line-height: 22px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background: #387aff;
  }
}

.for-you-list {
  .user-profile-suggest {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .foryou-user {
      border-bottom: 1px solid #323334;
      padding: 20px 0px;

      &:last-child {
        border-bottom: 0;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }

      .for-avtar {
        margin-bottom: unset;

        img {
          width: 36px;
          height: 36px;
          border-radius: 32px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 15px;
          border: 1px solid #000000;
        }

        .name {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }

        .d-flex {
          color: #8e8e93;
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
  }
}

// .suggest {
//     width: 100%;

// }

// .suggest:after {
//     content: '';
//     display: table;
//     clear: both;
// }

// .suggest div {
//     float: left;
// }

// .suggest button {
//     float: right;
// }

@media (min-width: 767px) and (max-width: 991px) {
  .crews-joined .crew-list-content {
    grid-template-columns: 1fr;
  }
}

// media target small screen tablet
@media (min-width: 600px) and (max-width: 744px) {
  .sticky-clm {
    h2 {
      font-size: smaller !important;
    }

    .profile-link {
      .user-detail-wrapper-main {
        .avtar-in {
          img {
            width: 36px;
            height: 36px;
            margin-right: 10px !important;
          }
        }
      }
    }

    .for-you-list {
      .user-profile-suggest {
        button {
          font-size: small;
        }

        .profile-link {
          .foryou-user {
            .for-avtar {
              .name {
                font-size: small;
              }

              img {
                margin-right: 7px;
              }
            }
          }
        }
      }
    }
  }
}

.sticky-clm {
  position: sticky;
  right: 0;
  top: 130px;
  // width: 330px;
  overflow-x: hidden;
  height: 470px;
  padding: 20px;
  border-radius: 5px;
  overflow-y: auto;
  background-color: #18191a;



  .for-you-list {
    h2 {
      color: var(--themewhite);
      font-weight: 300;
      font-size: 16px;
      line-height: 19.2px;
      margin: 0px 0px 20px 0px;
    }

    .user-profile-suggest {
      .foryou-user {
        padding: 8px 0;
        // .for-avtar {
        //     img{
        //         width: 30px;
        //         height: 30px;
        //     }
        //     .name {
        //         font-size: 10px;
        //     }
        // }
      }

      // button{
      //     font-size: 13px;
      // }
    }
  }


  &::-webkit-scrollbar-thumb {
    display: none;
  }

  .user-detail-wrapper-main {
    .avtar-in {
      gap: unset;

      img {
        width: 45px;
        height: 45px;
      }

      // .name {
      //     .profile-link {
      //         font-size: 12px;

      //     }
      // }
    }
  }
}

.upcomingEvents {
  width: 280px;
  height: 22px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 300;
  line-height: 21.6px;
  text-align: left;
  color: #ffffff;
}

.eventDetails {
  // width: 280px;
  height: auto;
  padding: 10px 20px;
  gap: 15px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid #212225;
  //   background-color: red;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-device-width: 1100px) {
    padding: 10px 5px;
  }
}

.namee {
  width: 100%;
  height: 12px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  // letter-spacing: -0.5px;
  text-align: left;
  color: #8e8e93;
  padding-bottom: 2px;
}

.eventTitle {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  // letter-spacing: -0.25px;
  text-align: left;
  color: #F5F5F5;
  padding: 3px 0;
}

.eventLocation {
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #8E8E93;
}

.eventAttendees {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 7px;
}

.attendeeImage1 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #000000;
}

.attendeeImage {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #000000;
  margin-left: -10px;
}

.eventAttendees span {
  font-family: Inter;
  font-size: 9px;
  font-weight: 400;
  line-height: 9px;
  text-align: left;
  color: #8E8E93;
  margin-left: 5px;
  /* Space between last image and text */

}


.date {
  display: flex;
  flex-direction: column;
  // width: 30px;
  height: auto;
  gap: 0px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}


.month {
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;

}

.datee {
  font-family: Inter;
  font-size: 25px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -1px;
  text-align: left;

}