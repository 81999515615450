$small: 599px;
.list-terms{
    h1 {
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        margin-top: 20px;
        font-family: Inter;
        color: #F5F5F5;
        @media (max-device-width: $small) {
            font-size: 25px;
          }
    }
    h2{
        font-weight: 300;
        font-size: 30px;
        line-height: 35px;
        margin: 20px 0px;
        @media (max-device-width: $small) {
            font-size: 25px;
          }
    }
    h3 {
        font-size: 24px;
        font-weight: 400;
        margin-top: 20px;
        font-family: Inter;
        color: #F5F5F5;
        @media (max-device-width: $small) {
            font-size: 20px;
          }
    }
    p{
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;
        font-family: Inter;
        color: #F5F5F5;
        text-align: start;
        @media (max-device-width: $small) {
            font-size: 14px;
          }
    }
    ol{
        padding: unset;
        // @media (max-width: $small) {
        //     font-size: 15px;
        //   }
        // h2{
        //     @media (max-width: $small) {
        //         font-size: 20px;
        //       }
        // }
    }
    ul {
        padding-left: 2rem;
        li{
            list-style: disc;
           
        }
    }
    .program-desc {
        padding-left: 2rem;
        li{
            list-style: unset;
        }
    }
   
}
