$small: 600px;
$medium: 600px;
$x-small: 400px;
/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
$landscapetab: 801px;

/* tablet, landscape iPad, lo-res laptops ands desktops */
.crew-post-wrapper {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 17px;

  .nav-pills {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-bottom: #323334 solid 1px;

    li {
      button {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        background: none;
        border-radius: 0;
        color: #8e8e93;
        display: block;
        width: 95%;
        text-align: center;
        padding: 10px 0px;

        &.active {
          border-bottom: #387aff solid 2px;
          background: none;
          color: #fff;
        }
      }
    }
  }

  .post-block {
    margin-bottom: 20px;
    border-bottom: #323334 solid 1px;
    padding-bottom: 20px;

    .header-crew-post {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .user-detail {
      margin: 20px 0px;
      display: flex;
      gap: 10px;
      align-items: center;

      img {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        object-fit: cover;
        border: 1px solid #898a8d;
      }

      .name {
        display: flex;
        gap: 10px;
        margin-bottom: 5px;
        align-items: center;

        h4 {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .rating {
        display: flex;
        gap: 5px;

        .number {
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 8px;
        }
      }

      .bullets {
        display: flex;

        span {
          border: 1px solid #323334;
          border-radius: 1px;
          width: 10px;
          height: 10px;
          margin-right: 0.1px;
          background: none;

          &:nth-child(1) {
            background: #1d9e26;
          }

          &:nth-child(2) {
            background: #61d62d;
          }

          &:nth-child(3) {
            background: #1d9e26;
          }

          &:nth-child(4) {
            background: #aedf21;
          }

          &:nth-child(5) {
            background: #dae61e;
          }

          &:nth-child(6) {
            background: #f8e101;
          }

          &:nth-child(7) {
            background: #ffc901;
          }
        }
      }

      .hour {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          color: #8e8e93;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14.4px;
          letter-spacing: -0.078px;
          display: flex;
          gap: 5px;
          align-items: center;

          &.location {
            position: relative;
            padding-left: 10px;

            &::before {
              content: "";
              position: absolute;
              background: #8e8e93;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              left: 0;
              top: 5px;
            }
          }
        }
      }
    }

    .name-post {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      margin: 20px 0px;
    }

    .post-img {
      width: 100%;
    }

    .post-p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      padding: 20px 0px;
    }

    .view-replies {
      color: #8e8e93;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      margin-top: 20px;

      &:hover {
        cursor: pointer;
        color: #fff;
      }
    }
  }

  .upcoming-events {
    margin-top: 35px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .coming-events {
    border-radius: 10px;
    padding: 10px;
    gap: 10px;
    display: grid;
    align-items: center;
    grid-template-columns: 30px auto 50px;
    border: 1px solid #323334;
    margin-bottom: 20px;

    &:hover {
      border-color: #fff;
    }

    .date {
      text-align: center;

      .month {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.078px;
        color: #f5f5f5;
      }

      .date-text {
        color: #f5f5f5;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .map-img {
      width: 70px;
    }

    .eve-info {
      border-left: 1px solid #323334;
      padding-left: 10px;

      .meet {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        color: #ffffff;
      }

      .loaction {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        padding: 5px 0px;
      }

      .attending-imgs {
        display: flex;
        align-items: center;

        .img-holder {
          display: flex;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: relative;

            &:nth-child(2) {
              z-index: 2;
              margin-left: -10px;
            }

            &:nth-child(3) {
              z-index: 3;
              margin-left: -10px;
            }
          }
        }

        .attending-text {
          color: #8e8e93;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          padding-left: 10px;
        }
      }
    }
  }

  .event-map {
    position: relative;
    color: #fff;

    .event-detail {
      background: #18191a;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 16px;
      border-radius: 10px;
      // display: flex;
      align-items: center;
      bottom: 20px;
      width: calc(100% - 40px);
      gap: 10px;

      .map-svg {
        border-radius: 5px;
        background: #3a9b5b;
        width: 50px;
        height: 50px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.ticket-detail {
        .map-svg {
          background: #387aff;
        }
      }

      .ev-info {
        flex: auto;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .e-title {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.408px;
          margin-bottom: 5px;
          display: flex;
          width: 100%;
          justify-content: space-between;

          .Recurring {
            color: #e56424;
            font-size: 16px;
            font-weight: 300;
            line-height: 16px;
          }
        }

        .more-info {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;

          .clm {
            display: flex;
            gap: 5px;
            align-items: center;

            span {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
      }

      .more-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;

        .clm {
          display: flex;
          align-items: center;
          gap: 15px;
          margin-right: 5px;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #323334;
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }

  .about-crew {
    h3 {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      margin: 20px 0px;
    }

    .crew-list {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      .user-detail {
        margin: 20px 0px;
        display: flex;
        gap: 10px;
        align-items: center;

        img {
          border-radius: 50%;
          width: 60px;
          height: 60px;
          object-fit: cover;
          border: 1px solid #8e8e93;
        }

        .name {
          display: block;
          gap: 10px;
          margin-bottom: 5px;
          align-items: center;

          h4 {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
          }
        }

        .name-text {
          color: #8e8e93;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    .rules-tag {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 30px;

      span {
        border: #323334 solid 1px;
        border-radius: 40px;
        padding: 8px 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}

.user-profile-crew {
  position: relative;
  .container {
    padding: 0 25px;

    @media only screen and (max-device-width: $small),
      (max-device-width: $landscapetab) {
      padding: unset;
    }
  }
  .banner-img {
    width: 100%;
    object-fit: cover;
    height: 385px;

    @media only screen and (max-device-width: $small),
      (max-device-width: $landscapetab) {
      margin-top: unset;
      height: 285px;
    }
  }

  .user-data {
    background: #18191a;
    border-radius: 10px 10px 0 0;
    padding: 40px 30px;
    position: relative;
    margin-top: -50px;
    display: flex;
    gap: 15px;
    .user-data-dot {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .avtar-img-crew {
      border: 2px solid var(--placeholder);
      border-radius: 10px;
      min-width: 150px;
      max-width: 150px;
      height: 150px;
      object-fit: cover;
      @media only screen and (max-device-width: $small) {
        min-width: 85px;
        max-width: 85px;
        // height: 100%;
        max-height: 85px;
        background-color: #18191a;
      }
    }
    @media only screen and (max-device-width: $small),
      (max-device-width: $landscapetab) {
      padding: 15px 10px;
      // flex-direction: column;
    }

    .avtar {
      // margin-right: 30px;
      @media only screen and (max-device-width: $small),
        (max-device-width: $landscapetab) {
        position: absolute;
        top: -50px;
      }

      .avtar-img {
        border: 2px solid #aedf21;
        border-radius: 10px;
        min-width: 150px;
        max-width: 150px;
        height: 150px;
        object-fit: cover;

        @media only screen and (max-device-width: $small),
          (max-device-width: $landscapetab) {
          min-width: 85px !important;
          max-height: 85px !important;
        }
      }
    }
    .dott {
      text-align: end;
      position: relative;
      cursor: pointer;
      .threeDotsModal {
        max-width: 100%;
        position: absolute;
        left: -250px;
        z-index: 1000;
      }
    }
    .helmat-row {
      display: flex;
      align-items: center;
    }

    span {
      @media only screen and (max-device-width: $small),
        (max-device-width: $landscapetab) {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .UserName {
      // margin-top: 16px;
      @media only screen and (max-device-width: $small),
        (max-device-width: $landscapetab) {
        position: relative;
        justify-content: flex-start;
        top: 30px;
        font-size: 15px;
      }
    }

    .shots {
      padding-left: 10px;

      p {
        font-weight: 400;
        color: #aedf21;
        font-size: 11px;
        line-height: 10px;
      }

      .bullets {
        display: flex;
        margin-top: 8px;

        span {
          border: 1px solid #323334;
          border-radius: 3px;
          width: 12px;
          height: 12px;
          margin-right: 1px;
          background: none;

          &:nth-child(1) {
            background: #1d9e26;
          }

          &:nth-child(2) {
            background: #61d62d;
          }

          &:nth-child(3) {
            background: #87db28;
          }

          &:nth-child(4) {
            background: #aedf21;
          }
        }
      }
    }

    .helmat {
      background: #aedf21;
      padding: 10px 8px;
      margin-left: 12px;
      text-align: center;

      p {
        font-weight: 600;
        font-size: 10px;
        line-height: 10px;
        color: #18191a;
      }
    }
  }

  .userInfo {
    flex: auto;
    // margin-top: 27px;
    .name {
      display: flex;
      // justify-content: space-between;
      text-align: start;

      word-break: break-all;
      font-weight: 300;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.374px;
      // margin-bottom: 5px;
      @media only screen and (max-device-width: $small),
        (max-device-width: $landscapetab) {
        //   text-align: justify;
        //   position: relative;
        //   top: 0;
        font-size: 20px;
        margin-top: 27px;
      }
      // .follow-button {
      //   background-color: #387aff;
      //   // height: 38px;
      //   width: 125px;
      //   padding: 3px 24px;
      //   font-size: 15px;
      //   border-radius: 8px;
      //   // margin-top: 15px;
      //   color: #fff;
      //   border: none;
      //   @media only screen and (max-device-width: $small),
      //     (max-device-width: $landscapetab) {
      //     position: absolute;
      //     right: 18px;
      //     height: 34px;
      //     width: 100px;
      //     top: 0px;
      //     font-size: 14px;
      //     font-weight: 500;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //   }
      // }
      .btn-join {
        @media only screen and (max-device-width: $small) {
          margin-top: -30px;
        }
        .default-btn {
          margin-left: auto;
          margin-bottom: auto;
          max-width: 300px;
          display: inline-block;
          flex: 0 0 300px;
          border-radius: 10px;
          font-size: medium;
          padding: 6px 40px !important;

          @media only screen and (max-device-width: $small) {
            padding: 6px 20px !important;
            // flex: 0 0 145px !important;
            font-size: small;
          }
        }
      }
    }

    .userName {
      color: #8e8e93;
      letter-spacing: 0.374px;
      font-weight: 300;
      font-size: 16px;
    }

    p {
      span {
        color: #387aff;
        cursor: pointer;
      }
    }

    .followers {
      position: absolute;
      top: -60px;
      right: 0px;
      gap: 8px;
      width: 100%;
      justify-content: flex-end;
      display: flex;
      @media only screen and (max-device-width: $landscapetab) {
        top: -50px;
        gap: 15px;
      }
      @media only screen and (max-device-width: $landscapetab) {
        gap: 0px;
      }

      .clm {
        border-right: 0.5px solid rgba(245, 245, 245, 0.26);
        padding-right: 30px;
        margin-right: 30px;
        text-align: center;
        cursor: pointer;
        //   @media only screen and (max-device-width: $landscapetab) {
        //     padding-right: 0px;
        //   }
        @media only screen and (max-device-width: $landscapetab) {
          margin-right: 20px;
          padding-right: 15px;
        }

        &:last-child {
          border-right: 0;
        }

        .number {
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 0px;
          @media only screen and (max-device-width: $landscapetab) {
            font-size: 16px;
          }
          @media only screen and (max-device-width: $landscapetab) {
            font-size: 14px;
          }
        }

        p {
          font-weight: 400;
          font-size: 16px;
          @media only screen and (max-device-width: $small) {
            font-size: 12px;
          }
          @media only screen and (max-device-width: $x-small) {
            font-size: 10px;
          }
        }
      }
    }
  }

  // .default-btn {
  //   margin-left: auto;
  //   margin-bottom: auto;
  //   max-width: 300px;
  //   display: inline-block;
  //   flex: 0 0 300px;
  //   border-radius: 10px;
  //   @media only screen and (max-device-width: $small) {
  //     padding: 8px 0 !important;
  //     flex: 0 0 145px !important;
  //     font-size: small;
  //   }
  // }
}

@media only screen and (max-device-width: $small) {
  .crew-post-wrapper {
    grid-template-columns: 100%;

    .post-block {
      .user-detail {
        .hour {
          span {
            font-size: 8px;
          }
        }
      }
    }

    .event-map {
      position: relative;
      color: #fff;

      .event-detail {
        .map-svg {
          width: 35px;
          height: 35px;
        }

        .ev-info {
          .e-title {
            font-size: 14px;
          }

          .more-info {
            .clm {
              display: flex;
              gap: 5px;
              align-items: center;

              span {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
              }
            }
          }
        }

        .more-info {
          .clm {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-right: 5px;

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #323334;
              width: 25px;
              height: 25px;
            }

            span {
              font-size: 9px;
            }
          }
        }
      }
    }
  }
}

@media (min-device-width: $medium) and (max-device-width: $landscapetab) {
  .crew-post-wrapper {
    grid-template-columns: 100%;

    .post-block {
      .user-detail {
        .hour {
          span {
            font-size: 10px;
          }
        }
      }
    }

    .event-map {
      position: relative;
      color: #fff;

      .event-detail {
        .map-svg {
          width: 35px;
          height: 35px;
        }

        .ev-info {
          .e-title {
            font-size: 14px;
          }

          .more-info {
            .clm {
              display: flex;
              gap: 5px;
              align-items: center;

              span {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
              }
            }
          }
        }

        .more-info {
          .clm {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-right: 5px;

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #323334;
              width: 25px;
              height: 25px;
            }

            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
