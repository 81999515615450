$small: 599px;
$medium: 600px;
/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
$landscapetab: 801px;

.list-warpper {
    background: #18191a;
    padding: 20px 40px;
    margin: 0;
    overflow: auto;

    .tabs-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 20px;
        background: #18191a;
        position: sticky;
        top: 0;

        li {
            border-bottom: #323334 solid 1px;
            color: #8e8e93;
            cursor: pointer;
            font-size: 16px;
            padding: 10px 5px;

            &.active {
                border-color: #387aff;
                color: #fff;
            }
        }
    }
}



.crew-list-content {
    height: 400px;
    overflow-y: scroll;
    padding: 0px 20px;

    // @media only screen and (max-device-width: $small) {
    //     height: 460px;
    // }
    .list-block{
        height: 100%;
        border-bottom: none !important;
    }
    .list-block-foll {
        color: #fff;
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 0px 0px 5px 0px;
        // border-bottom: 1px solid #212225;
        margin: 25px 0 5px 0;

        img {
            border-radius: 25px;
            border: 1px solid #323334;
            width: 45px;
            height: 45px;
            object-fit: cover;
        }

        .list-info {
            h4 {
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                margin-bottom: 0;
                display: flex;
                gap: 15px;
                align-items: self-start;
                white-space: nowrap;
                @media only screen and (max-device-width: $small) ,(max-device-width:$landscapetab){
                    font-size: 14px !important;
                }
            }

            p {
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
            }
        }
    }

}
.full-search {
    padding :15px 15px!important;

    .search-form {
        .search-fld {
            border-color: transparent;
            background: #323334;
            border-radius: 10px;
          
            justify-content: start !important;


            svg {
                margin: 0 5px;
            }

            .form-control {
                // padding-left: unset;
                height: 35px;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .list-warpper {
        padding: 15px;
    }
}