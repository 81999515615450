.feedback-container {
    max-width: 640px;
    padding: 60px 15px;
    margin: 0 auto;

    .logo {
        display: block;
        margin: 0 auto;
        text-align: center;
    }

    h2 {
        font-size: 30px;
        font-style: normal;
        font-weight: 300;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        margin-bottom: 30px;
    }

    .form-wrapper {
        .form-group {
            margin-bottom: 30px;

            label {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 10px;
            }

            .form-control {
                background: none;
                outline: none;
                border: none;
                border-radius: 0;
                padding: 0px;
                height: 42px;
                border-bottom: 1px solid #323334;
                color: #fff;
            }

            textarea.form-control {
                height: 100px;
                border: 1px solid #323334;
                padding: 10px;
                border-radius: 6px;
            }

            select.form-control {
                border: 1px solid #323334;
                background: rgba(41, 41, 44, 0.40);
                padding: 0px 15px;
                border-radius: 4px;
                color: #fff;
                background: url("../../assets/chevron-left.svg") no-repeat right 10px top 10px #1b1b1d;
            }
        }

        .multiple-checkbox {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            label {
                margin: 0px;
                position: relative;


                input {
                    position: absolute;
                    visibility: hidden;
                    opacity: 0;
                }

                span {
                    color: #989999;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    display: inline-block;
                    border-radius: 60px;
                    border: 1px solid #323334;
                    padding: 8px 26px;
                    cursor: pointer;
                }

                input:checked~span {
                    background: #387AFF;
                    color: #fff;
                }
            }

        }

        .multiple-radio {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 15px;

            label {
                margin: 0px;
                position: relative;

                input {
                    position: absolute;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                }

                span {
                    position: relative;
                    color: #989999;
                    font-size: 16px;
                    font-weight: 400;
                    padding-left: 25px;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 2px;
                        width: 16px;
                        height: 16px;
                        border: #323334 solid 1px;
                        border-radius: 50%;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 0px;
                        width: 16px;
                        border: #fff solid 1px;
                        height: 16px;
                        background: #387AFF;
                        border-radius: 50%;
                        display: none;
                    }
                }

                input:checked~span {
                    &:after {
                        display: block;
                    }
                }
            }
        }

        .upload-label {
            border-radius: 6px;
            border: 1px solid #323334;
            padding: 30px 15px;
            text-align: center;
            width: 100%;
            display: block;
            background: none;

            input {
                position: absolute;
                opacity: 0;
                visibility: hidden;
            }

            .click {
                color: #8E8E93;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                padding-bottom: 12px;
            }

            .up-button {
                border-radius: 30px;
                border: 1px solid #8E8E93;
                display: inline-block !important;
                padding: 8px 26px;
                display: flex;
                align-items: center;
                color: #fff;
            }
        }
    }

    .submit-btn {
        border-radius: 10px;
        background: #387AFF;
        height: 50px;
        text-align: center;
        border: none;
        color: #fff;
        width: 100%;
        &:hover{
            background: #323334;
        }
    }
}