.general-header-post {
  .avtar-in {
    display: flex;
    align-items: center;
    gap: 10px;
    .profile-link {
      flex: 0 0 40px;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 15px;
      border: 1px solid #898a8d;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
    }

    .d-flex {
      color: #8e8e93;
    }
  }

  .follow-btn {
    background: #387aff;
    border-radius: 10px;
    padding: 10px 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-left: auto;
    max-width: fit-content;
    border: none;
    outline: none;
    transition: all ease 0.4s;

    &:hover {
      background: #898a8d;
      color: #fff;
    }
  }

  .dropdown-menu-general {
    background-color: #18191a;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: unset;
    transform: translate(294px, 105px);
    z-index: 999999;
    background-color: #18191a;
    width: 400px;
    height: 244px;
    border-radius: 10px;
    padding: 20px;
    row-gap: 12px;
    display: flex;
    flex-direction: column;

    &:hover {
      background-color: #18191a;
    }

    .dropdown-item {
      color: #f5f5f5;

      &:hover {
        background-color: #18191a;
      }
    }
  }
}

.post-img {
  width: 100%;
}

.show-more-description {
  max-width: "100%";
  word-break: break-all;
  color: var(--themeWhite);

  p {
    font-size: 24px;
    line-height: 22px;
    font-weight: 500;

    span {
      color: #387aff;
      cursor: pointer;
    }
  }
}

.show-more-title {
  max-width: "100%";
  word-break: break-all;
  color: var(--themeWhite);

  p {
    font-size: 16px;
    line-height: 22px;

    span {
      color: #387aff;
      cursor: pointer;
    }
  }
}

.borderforAll {
  margin-top: 10px;
  // border: 1px solid var(--grey);
}

video {
  width: 100%;
}

.video-img-how {
  max-height: 610px;
  width: 100%;
  object-fit: contain;
}
