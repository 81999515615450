$small: 600px;

.parts-slider-container {
    display: grid;
    grid-template-columns: 70% 30%;
}

.post-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0px 0px 0px;
    position: relative;

    .component-page {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .username-component{
            .avtar-in {
                    align-items: center;
                    display: flex;
                    gap: 10px;
                    margin-bottom: 15px;
            
                    .profile-link {
                        .car-image {
                            position: relative;
            
                            .user-image {
                                position: absolute;
                                right: 5px;
                                top: 0;
                                .main-img{
                                    border-radius: 20px;
                                }
                            }
                        }
                    }
            
                    .name {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;
                    }
            
                    .d-flex {
                        color: #8E8E93;
                    }
                }
        }
    }

    .back-button,
    .three-dots {
        cursor: pointer;
    }

    h1 {
        word-break: break-word;
        font-weight: 700;
        font-size: 35px;
        line-height: 40px;
        letter-spacing: 0.374px;
        margin-bottom: 8px;
    }

    .tags {
        display: flex;
        gap: 15px;

        span {
            font-weight: 400;
            font-size: 15px;
            line-height: 15px;
            padding: 8px 16px;
            border: 1px solid #323334;
            border-radius: 8px;
        }
    }

    .avtar-info {
        display: flex;
        align-items: center;

        p {
            font-weight: 400;
            font-size: 15px;
            margin-right: 15px;
        }
    }
}

.slider-img {
    .image_under {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-device-width: $small) {
            padding: 0 5px;
        }

        .Borla {
            h6 {
                font-size: 26px;
                font-weight: 300;
            }
        }

        .right-section {
            display: flex;
            align-items: center;
            gap: 6px;

            .build-image {
                img {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: 30px;
                }
            }

            .text {
                p {
                    font-size: 13px;
                    font-weight: 400;
                    color: #f5f5f5;
                }

                .child {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                        font-size: 10px;
                        font-weight: 300;
                        color: #387AFF;
                    }

                    .dots-component {
                        width: 3px;
                        height: 3px;
                        border-radius: 15px;
                        background-color: #387AFF;
                    }
                }

            }
        }
    }

    .code-bar {
        p {
            font-size: 13px;
            font-weight: 400;
            color: #8E8E93;
        }
    }

    .link-url {
        display: flex;
        align-items: center;
        gap: 8px;

        a {
            font-size: 13px;
            font-weight: 400;
            color: #8E8E93;
            cursor: pointer;
            font-family: Inter;
        }
    }

    .error-case {
        color: #8E8E93;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
        /* 218.75% */
    }

    h2 {
        color: #FFF;

        /* H2 Headline */
        font-size: 30px;
        font-style: normal;
        font-weight: 300;
        line-height: 35px;
        /* 116.667% */
    }

    .owl-carousel {
        .owl-dots {
            display: none;
        }

        .owl-nav {
            position: absolute;
            z-index: 9;
            display: flex;
            justify-content: space-between;
            align-items: center;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 0px 10px;

            .owl-next,
            .owl-prev {
                border-radius: 50px;
                background: rgba(18, 19, 19, 0.4);
                width: 50px;
                height: 50px;
                font-size: 32px;

                &:hover {
                    background: #387AFF;
                }
            }
        }
    }

    .main-img {
        width: 100%;
    }


}

.install-info {
    display: flex;
    gap: 16px;
    margin-bottom: 10px;

    li {
        color: #8E8E93;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14.4px;
        letter-spacing: -0.078px;
        position: relative;

        &:first-child {
            &::before {
                display: none;
            }
        }

        &::before {
            content: "";
            left: -9px;
            top: 5px;
            width: 5px;
            height: 5px;
            background: #8E8E93;
            position: absolute;
            border-radius: 50%;
        }
    }
}

.actions-btns {
    margin-top: 20px;
    display: flex;
    gap: 10px;

    svg {
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    span {
        color: #8E8E93;
        font-size: 14px;
    }
}

.rating-stars {
    color: #387AFF;
    padding: 15px 0px;
}

.users-review-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    margin-top: 30px;
}

.average-rating {
    &>p {
        color: #8E8E93;
        font-size: 14px;
        line-height: 18px;
    }

    .rating-stars {
        display: flex;
        align-items: center;

        span {
            font-weight: 600;
            font-size: 22px;
            margin-right: 10px;
        }
    }
}

.user-rating-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    .user-rating-block {
        .img-holder {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 42%, rgba(0, 0, 0, 0.2) 100%);
            }

            .bg-img {
                width: 100%;
                height: 250px;
                object-fit: cover;
            }

            .info {
                position: absolute;
                left: 15px;
                top: 15px;
                display: flex;
                z-index: 5;
                align-items: center;

                .avtar-img {
                    margin-right: 10px;
                    border: 2px solid #898A8D;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }

                p {
                    font-weight: 300;
                    font-size: 14px;
                }
            }
        }
    }
}

h2 {
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    margin: 20px 0px;
}

.how-to-section {
    background: #18191A;
    padding: 30px;
    margin-bottom: 20px;

    .block-list {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr;
    }

    .how-img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        margin-bottom: 15px;
    }

    .how-block {
        overflow: hidden;
        transition: all ease 0.3s;
        position: relative;

        h4 {
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
        }

        i {
            filter: drop-shadow(2px 2px 4px rgba(88, 87, 87, 0.836));
            font-size: 20px;
        }

        p {
            color: #8E8E93;
            font-weight: 400;
            font-size: 15px;
        }
    }
}

.gallery-section {
    .gallery-list {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr;

        .how-block {
            position: relative;
            overflow: hidden;
            transition: all ease 0.3s;

            img {
                transition: all ease 0.3s;
            }

            .gallery-img {
                width: 100%;
                height: 320px;
                object-fit: cover;
            }

            &:hover img {
                transform: scale(1.1);
            }
        }
    }
}

.show-more {
    display: flex;
    align-items: center;
    color: #387AFF;
    font-weight: 400;
    font-size: 20px;
    margin-top: 15px;
    cursor: pointer;
    transition: all ease 0.3s;

    svg {
        margin-left: 8px;
    }

    svg {
        transition: all ease 0.3s;
    }

    &:hover {
        color: #fff;

        svg {
            transform: translateY(3px);
        }
    }
}

.play-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 4;
}
@media (min-width: 767px) and (max-width: 991px) {
    .actions-btns{
        svg{
            width: 20px;
            height: 20px;

        }
    }
}