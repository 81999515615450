$small: 599px;

.posts-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-bottom: 30px;

    .img-holder {
        overflow: hidden;
        transition: all ease 0.3s;
        position: relative;

        .play-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 4;
        }

        i {
            filter: drop-shadow(2px 2px 4px rgba(88, 87, 87, 0.836));
            font-size: 20px;
        }

        img {
            width: 100%;
            height: 320px;
            object-fit: cover;
            transition: all ease 0.3s;
        }



        &:hover img {
            transform: scale(1.1);
        }
    }
}

.overlay-text {

    width: 100%;
    height: 100%;
    font-family: Inter;
    font-weight: 300;
    font-size: 18px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: #f5f5f5;
    background-color: #387AFF;
    justify-content: center;
    text-align: center;
    padding: 50px;

    @media only screen and(max-device-width:$small) {
        font-size: 11px;
        font-weight: 400;
        padding: 10px;

    }
}

.load-more {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #387AFF;
    margin-bottom: 60px;
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 10px;
    transition: all ease 0.3s;

    @media only screen and(max-device-width:$small) {
        font-weight: 500;
        font-size: 12px;
    }

    svg {
        transition: all ease 0.3s;
    }

    &:hover {
        color: #466ab3;

        svg {
            transform: translateX(3px);
        }
    }
}

.heading-posts-section {
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;

    @media only screen and(max-device-width:$small) {
        font-size: 16px;
        font-weight: 500;
    }
}