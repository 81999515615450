.dot-flashing {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #141416;
    color: #8d8c92;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #050505bb;
    color: #cecdd3;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #16151bac;
    color: #5c5c5f;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0.5s;
}

@keyframes dot-flashing {
    0% {
        background-color: #0a0a0c;
    }

    50%,
    100% {
        background-color: rgba(213, 213, 227, 0.96);
    }
}