$small: 600px;
$tablet: 990px;

.general-post-header {
    .avtar-in {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            width: 60px;
            height: 60px;
            border-radius: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 15px;
            border: 1px solid #898A8D;

           
            @media only screen and (max-device-width: $tablet) {
                width: 40px;
                height: 40px;
                margin-right: -20px;
            }
        }

        .name {
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            @media only screen and (max-device-width: $tablet) {
                font-size: 12px;
            }
        }

        .d-flex {
            color: #8E8E93;
            @media only screen and (max-device-width: $tablet) {
                font-size: 10px;
            }
        }
    }

    .follow-btn {
        background: #387AFF;
        border-radius: 10px;
        padding: 10px 60px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-left: auto;
        max-width: fit-content;
        border: none;
        outline: none;
        transition: all ease 0.4s;

        &:hover {
            background: #898A8D;
            color: #fff;
        }
    }
}

.post-img {
    width: 100%;
}

.show-more-description {
    max-width: "100%";
    word-break: break-all;
    color: var(--themeWhite);

    p {

        font-size: 24px;
        line-height: 22px;
        font-weight: 500;
        @media only screen and (max-device-width: $tablet) {
            font-size: 18px;
        }


        span {
            color: #387AFF;
            cursor: pointer;
        }
    }
}

.show-more-title-nested-replies {
    max-width: 100%;
    word-break: break-all;
    color: var(--themeWhite);
    background-color: #323334;
    border-radius: 0px 10px 10px 10px;
    padding: 12px;
    margin-left: 14%;

    p {

        font-size: 16px;
        line-height: 22px;

        span {
            color: #387AFF;
            cursor: pointer;
        }
    }
}

.borderforAll {
    margin-top: 10px;
    // border: 1px solid var(--grey);
}

video {
    width: 100%;
}

.video-img-review {
    max-height: 610px;
    width: 100%;
    object-fit: cover;
}

.show-more-title-review {
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;
    /* 116.667% */
}

.slick-slider {
    button {
        // position: absolute;
        z-index: 4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0px 10px;
    }

    .slick-next,
    .slick-prev {
        border-radius: 50px;
        background: rgba(42, 43, 46, 0.575);
        width: 50px;
        height: 50px;
        font-size: 32px;


    }

}

.show-more-paragraph {
    color: #FFF;

    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
}

/* Medium devices (landscape tablets, 600px and up) */
@media (min-width: 600px) and (max-width: 991px) {
    .show-more-title-nested-replies p {
        font-size: x-small;
        margin-top: unset !important;
    }
}