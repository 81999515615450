$tablet:992px;
$mobile:700px;
// .default-btn {
//     background: #387aff;
//     color: #fff;
//     display: inline-block;
//     border-radius: 5px;
//     padding: 10px 30px;
//     text-decoration: none;
//     border: #387aff solid 1px;
//      width: 125px ;
//     @media only screen and (max-device-width: $tablet) {
//         padding: 8px 12px ;
//         width: 80px ;
//     }
// }

// .default-btn:hover {
//     background-color: #000;
//     color: #fff;
// }

.explore-container {
    display: grid;
    grid-template-columns: 15% calc(85% - 25px) ;
    // gap: 30px;
    max-width: 1280px;
    margin: 0 auto;

    @media only screen and (max-device-width: 600px) {
        grid-template-columns: 1fr !important;
        // position: relative;
    }

    &>div:first-child {
        border-right: 1px solid #323334;
        @media only screen and (max-device-width: 600px) {
            // position: absolute;
            width: 100%;
            border-right: none;        }
    }
        .profile-links-side li a {
            display: flex;
            align-items: center;
            color: #8e8e93;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            padding: 10px 0;
            &:hover {
                color: #fff;
            }
            img {
                // border-radius: 20px;
                margin-right: 10px;
            }
        }
        .profile-links-side {
            padding: 5px 15px;
            position: sticky;
            top: 110px;
            left: 0;
        }
}
.explore-container {
    grid-template-columns: 15% calc(85% - 25px) 25%;
    display: grid;
    gap: unset;
    max-width: 1280px;
    margin: 0 auto;
    // height: 100vh;
    @media only screen and (max-device-width: 650px) {
      grid-template-columns: 1fr !important;
      padding: unset;
  
      & > div:first-child {
        border: none;
      }
    }
  }

.home-banner {
    background: url(./../../../assets/exploreBnner.svg) center center no-repeat;
    background-size: cover;
    position: relative;
    padding: 70px 70px;
    height: 400px;


    // &::before {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: rgba($color: #000000, $alpha: 0.8);
    // }

    .container {
        position: relative;
        z-index: 2;
        max-width: 990px;
        margin: 0 auto;
        // text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        height: 100%;
        h5 {
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 20px;
            @media only screen and (max-device-width: $tablet) {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 15px;
            }
            @media only screen and (max-device-width: $mobile) {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 15px;
            }
        }
    
        h1 {
            font-weight: bold;
            letter-spacing: 0.37px;
            font-size: 40px;
            line-height: 40px;
            margin-bottom: 20px;
            @media only screen and (max-device-width: $tablet) {
                font-size: 30px;
                line-height: 20px;
                margin-bottom: 15px;
            }
            @media only screen and (max-device-width: $mobile) {
                font-size: 25px;
            }
        }
    }



    form {
        display: flex;
        align-items: center;
        border: #ddd solid 1px;
        background: rgba(41, 41, 44, 0.5);
        border: 1px solid #8e8e93;
        box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(5px);
        border-radius: 4px;
        height: 49px;
        line-height: 49px;
        // max-width: 460px;
        margin: 0 auto;

        .search {
            flex: auto;
            background: none;
            background: none;
            border: none;
            padding-left: 15px;
            outline: none;
            color: #fff;
            border-right: 1px solid #8e8e93;

            &:focus {
                outline: none;
            }
        }

        .selectOption {
            background: none;
            border: none !important;
            outline: non !important;
            color: #8e8e93;
            width: 180px;
            padding: 0px 15px;

            &:focus {
                outline: none;
                border: none;
            }
        }

        button {
            background: none;
            border: none;
            outline: none;
            color: #8e8e93;
            border-left: 1px solid #8e8e93;
            padding: 0px 30px;
            margin-left: 15px;
            text-align: end;
        }
    }

    p {
        max-width: 652px;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: #fcfcfe;
        // margin: 20px auto 70px auto;
    }
    .heading-search{
    display: flex;
    gap: 10px;
    h4 {
        align-self: center;
        color: #8e8e93;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 10px;
        @media only screen and (max-device-width: $tablet) {
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
        }
    }
    .quick-search {
        display: flex;
        align-items: center;
        gap: 10px;



        span {
            border: 1px solid rgba(80, 85, 92, 0.6);
            border-radius: 20px;
            padding: 8px 25px;
            font-size: 12px;
            line-height: 14px;
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 10px;
            @media only screen and (max-device-width: $tablet) {
                font-size: 11px;
                padding: 10px 15px;
            }
        }
    }
    @media only screen and (max-device-width: $mobile) {
        flex-direction: column;
        h4{
            margin-bottom: 0px;
        }
        .quick-search {
            gap: 8px;
            span {
                padding: 10px 12px;
                font-size: 8px;
                line-height: 10px;
                cursor: pointer;
                margin-bottom: 10px;

            }
        }
    }
    }
    @media only screen and (max-device-width: $tablet) {
        padding: 30px 30px;
    }

}