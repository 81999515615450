.page-container {
    // grid-gap: 30px;
    // display: grid;
    // gap: 30px;
    // grid-template-columns: 15% 55% calc(30% - 75px);
    // margin: 0 auto;
    // max-width: 1280px;
        min-height: 100vh;


    .profile-links-side {
        padding: 5px 15px;
        // padding: 30px 15px;
        position: sticky;
        top: 110px;
        left: 0;

        .svg-container:hover path {
            fill: #fff;
            /* Change the fill color on hover */
        }

        li a {
            display: flex;
            align-items: center;
            padding: 10px 0px;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            color: #8E8E93;

            &:hover {
                color: #fff;
            }

            img {
                margin-right: 10px;
                // width: 25px;
                // border-radius: 20px;
            }

            svg {
                margin-right: 9px;
            }

        }
    }

    .sideBarMain {
        transition: transform 0.3s ease-in-out;
        transform: translateX(10px);
        position: fixed;
        top: 0;
        left: -10px;
        height: 100%;
        width: 250px;
        background-color: #121313; // adjust as necessary
        z-index: 1000;
    }
}

