$small: 600px;

.Trendingparts {
    padding-bottom: 60px;

    h3 {
        font-weight: 400;
        font-size: 24px;
        line-height: 100%;
        margin-bottom: 30px;
    }

    .part-block-trends {
        margin-bottom: 20px;

        .name {
            margin-top: 10px;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #F5F5F5;
            letter-spacing: -0.408px;
            // height: 45px;
        }

        a {
            color: #fff;

            &:hover {
                color: #fff;
            }
        }

        .img-holder {
            overflow: hidden;
            position: relative;

            .play-btn {
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 4;

                &:hover {
                    color: grey;
                }
            }

            i {
                filter: drop-shadow(2px 2px 4px rgba(136, 41, 41, 0.836));
            }
        }

        .main-img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 10px;


        }

        .info {
            // padding: 10px 0px;
            margin-bottom: 20px;
            // border-bottom: 1px solid #444546;



            .h4 {
                font-weight: 400;
                font-size: 14px;
                line-height: 25px;
                margin: 8px 0px;
                height: 30px;
                word-break: break-word;
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
            }

            .total-coments {
                display: flex;
                align-items: center;
                gap: 10px;

                .share-btn {
                    background-color: #212225;
                    border-radius: 30px;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    svg {
                        width: 20px;
                        height: 20px;
                    }

                    span {
                        font-size: 12px;
                    }
                }
            }

            .post-submission-info {
                display: flex;
                align-items: center;
                gap: 10px;

                span {
                    position: relative;
                    color: #8E8E93;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    &::after {
                        background: #8E8E93;
                        width: 4px;
                        height: 4px;
                        right: -6px;
                        top: 50%;
                        transform: translateY(-50%);
                        border-radius: 50%;
                        content: "";
                        position: absolute;
                    }

                    &:first-child {
                        color: var(--greenNotify);
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .ext-info {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            span {
                border: 1px solid #444546;
                border-radius: 5px;
                padding: 5px 10px;
                font-weight: 400;
                font-size: 12px;
                line-height: 10px;
                letter-spacing: -0.078px;
            }
        }
    }
}

.avtar-in {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    gap: 10px;

    .profile-link {
        width: 100px;
        flex: 0 0 60px;
        color: var(--themeWhite);

        @media only screen and (max-device-width: $small) {
            font-size: 12px;
            text-align: left;
        }
    }

    .car-image {
        position: relative;
    }

    .user-image {
        position: absolute;
        top: 0;
        right: -9px;
    }

    img {
        width: 40px;
        height: 40px;
        border-radius: 32px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 15px;
        border: 1px solid #000000;
    }

    .name {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
    }

    .d-flex {
        color: #8E8E93;
        @media only screen and (max-device-width: $small) {
            font-size: 10px;
        }
    }
}

.liked-in {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    gap: 10px;
    padding-top: 15px;

    .profile-link {
        width: 60px;
        flex: 0 0 70px;
        color: var(--themeWhite);
    }

    .car-image {
        position: relative;
    }

    .user-image {
        position: absolute;
        top: 0;
        left: 20px;
    }

    .user-liked-image {
        position: absolute;
        top: 0;
        left: 40px;
    }

    img {
        width: 30px;
        height: 30px;
        border-radius: 32px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 15px;
        border: 1px solid #000000;
    }

    .name {
        .username-liked {
            font-size: 12px;
            font-weight: 700;
            color: #8E8E93;
        }
    }

    .d-flex {
        color: #8E8E93;
    }
}

@media (min-width: 600px) and (max-width: 991px) {
    .avtar-in {
        .name {
            font-size: smaller;
        }

        .d-flex {
            font-size: small;
        }
    }

    .feed-block {
        .username {
            h5 {
                font-size: smaller !important;
            }
        }
    }
}