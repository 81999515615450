.user-profile-modal {
  .follow-followers {
    .nav-pills {
      margin-top: 3rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      border-bottom: #323334 solid 1px;
      margin-bottom: 1rem !important;

      li {
        button {
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          background: none;
          border-radius: 0;
          color: #8e8e93;
          display: block;
          width: 95%;
          text-align: center;
          padding: 10px 0px;

          &.active {
            border-bottom: #387aff solid 2px;
            background: none;
            color: #fff;
          }
        }
      }
    }
  }
}
