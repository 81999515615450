$small: 480px;
$tablet: 810px;
header {
  background: #18191a;
  padding: 20px 0px;
  position: sticky !important;
  z-index: 999999;
  top: 0;

  .rightbar {
    display: flex;
    align-items: center;
    float: right;

    a,
    button {
      color: #fff;
      margin-left: 20px;
      display: inline-block;
      border-radius: 30px;
      padding: 5px 20px;
      text-decoration: none;
      border: #387aff solid 1px;
      font-size: 12px;
      font-weight: 400;
      @media only screen and(max-device-width:$tablet) {
        font-size: 8px;
        margin-left: 0px;
      }

      &:first-child {
        background: none;
      }

      &:hover {
        background: #000;
        color: #fff;
      }
    }

    .hamburger-menu-button {
      display: none;
      background: none;
      border: none;
      color: white;
      font-size: 20px;
      font-weight: 300 !important;
      cursor: pointer;
      padding: 10px;
      margin-left: 15px;
    }

    .menu-items {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 100%;
      right: 0;
      background-color: #333;
      padding: 10px;
      z-index: 1;
    }

    .menu-items.open {
      display: flex;
    }

    .menu-items a {
      color: white;
      text-decoration: none;
      padding: 5px 0;
    }

    .search {
      // flex: auto;
      // background: none;
      background: #212225;
      // border: none;
      padding: 10px;
      outline: none;
      // width: 100%;
      min-width: 372px;
      color: #fff;
      border: 1px solid #323334;
      border-radius: 5px;
      // -webkit-border-radius: 5px;
      // -moz-border-radius: 5px;
      // -ms-border-radius: 5px;
      // -o-border-radius: 5px;

      &:focus {
        outline: none;
      }
    }

    .searchBtn {
      background: none;
      border: none;
      outline: none;
      color: white;
      padding: 10px;
      margin-left: 15px;
      position: absolute;
      border-radius: 0;
      border-left: 1px solid #323334;
      right: 2%;
      top: 3px;
      // -webkit-border-radius: 0;
      // -moz-border-radius: 0;
      // -ms-border-radius: 0;
      // -o-border-radius: 0;
    }

    .searchBtn:hover {
      background: none;
    }

    .userProfileDropdown {
      display: none !important;
    }

    .dropdown {
      // border-left: 1px solid gray;
      margin-left: 20px !important;
      padding: 0;
      text-align: initial;
      @media only screen and(max-device-width:$tablet) {
        margin-left: 0px !important;
      }

      a,
      ul {
        border: none !important;
        background: none;
        color: white;
        padding: 10px 0;
        margin-left: 20px;
        @media only screen and(max-device-width:$tablet) {
          margin-left: 10px !important;
        }

        svg {
          font-size: 39px;
        }
      }

      a:hover,
      ul:hover {
        border: none !important;
        background: none;
        color: white;
      }

      .avtar-img {
        border: 2px solid #898a8d;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-left: 20px;
        @media only screen and(max-device-width:$tablet) {
          margin-left: 10px !important;
          width: 35px;
          height: 35px;
        }
      }

      .dropdown-menu {
        background: #18191a;
        border-radius: 0;
        // margin-top: 21px !important;
        padding: 8px 20px;

        a {
          color: white;
          margin-left: 0px;
        }
      }

      .dropdown-menu:hover {
        background: #18191a;

        a {
          color: white;
          margin-left: 0px;
        }
      }
    }
    .headerRightCorner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 15px;
      .search-input {
        display: flex;
        align-items: center;
        max-width: 80px;
        @media only screen and (max-device-width: 350px) {
          max-width: 65px;
        }
        input {
          width: 100%;
          font-size: x-small;
          background-color: #18191a;
          border-radius: 20px;
          border: none;
          color: #fff;
          padding: 0px 5px;
          &:focus {
            outline: none; /* Ensures no outline on focus */
            box-shadow: none; /* Removes any focus shadow */
          }
        }
      }
      .searchIcon {
        width: 15px;
        background-color: #18191a;
        border: none;
        cursor: pointer;
        padding: 0;
      }
      .downloadBtn {
        text-align: center;
        background-color: #387aff;
        padding: 5px 10px;
        border-radius: 20px;
        cursor: pointer;
        @media only screen and (max-device-width: "350px") {
          padding: 5px 5px;
          font-size: 8px;
        }
      }
    }
  }
}

footer {
  background: #18191a;
  // position: fixed;
  // bottom: 0;
  // width: 100%;

  .footer {
    padding: 30px 10px !important;

    .footerContent {
      margin-left: 0% !important;

      li {
        margin-top: 15px;
      }
    }

    a {
      color: white;
      font-weight: 100;
    }
  }

  .copyRights {
    background: #121313 !important;
    color: #8e8e93;
    padding: 30px 0px !important;
    .copyRightContent {
      @media only screen and(max-device-width:$tablet) {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        padding-bottom: 10px;
      }
    }

    .termConditions {
      li {
        margin-left: 20px;

        a {
          color: #8e8e93;
          font-size: 14px;
        }
      }
      @media only screen and(max-device-width:$tablet) {
        font-size: 12px;
        font-weight: 400;
        justify-content: center !important;
        float: none !important;
      }
    }
  }
}

.headerContent {
  max-width: 1280px;
  // padding: 10px 0;
  @media only screen and(max-device-width:$small) {
    padding: 10px 0;
  }
}
