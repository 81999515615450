.sticky-clm-2 {
    .text-wrapper {
        color: #ffffff;
        font-size: 28px;
        font-weight: 300;
        white-space: nowrap;
    }

    .para-wrapper {
        color: #ffffff;
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 21.6px;
        // width: 280px;
        margin-block: 10px;
    }

    .event-tile {
        align-items: flex-start;
        border: 1px solid #212225;
        border-radius: 8px;
        display: inline-flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        margin-block: 10px;
        max-width: 280px;
        max-height: 200px;
        @media only screen and (max-device-width: 1200px) {
            max-width: 240px;
            max-height: 200px;
        }

        .rectangle {
            height: 120px;
            object-fit: cover;
            position: relative;
            width: 280px;
        }

        .frame {
            align-items: center;
            align-self: stretch;
            background-color: var(--dark-bg);
            display: flex;
            flex: 0 0 auto;
            gap: 20px;
            padding: 10px 20px;
            position: relative;
            width: 100%;
            @media only screen and (max-device-width: 1200px) {
                gap: 10px;
            }
            .div {
                align-items: center;
                display: inline-flex;
                flex: 0 0 auto;
                flex-direction: column;
                position: relative;

                .sept {
                    color: #ffffff;
                    font-size: 8px;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 9.6px;
                    margin-top: -1px;
                    position: relative;
                    white-space: nowrap;
                    width: fit-content;
                }

                .text-wrapper {
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 300;
                    letter-spacing: -1px;
                    line-height: 24px;
                    position: relative;
                    white-space: nowrap;
                    width: fit-content;
                }
            }

            .line {
                position: relative;
                width: 1px;
                top: 10px;
            }

            .group {
                height: 60px;
                position: relative;
                width: 148px;

                .text-wrapper-2 {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 600;
                    left: 0;
                    letter-spacing: 0;
                    line-height: 19.2px;
                    position: absolute;
                    top: 0;
                    white-space: nowrap;
                }

                .p {
                    color: #ffffff;
                    font-size: 10px;
                    font-weight: 300;
                    left: 0;
                    letter-spacing: 0;
                    line-height: 12px;
                    position: absolute;
                    top: 20px;
                    // white-space: nowrap;
                }

                .group-2 {
                    height: 20px;
                    left: 0;
                    position: absolute;
                    top: 40px;
                    width: 146px;

                    .text-wrapper-3 {
                        color: var(--darkened-text);
                        font-size: 9px;
                        font-weight: 300;
                        left: 25px;
                        letter-spacing: 0;
                        line-height: 9px;
                        position: absolute;
                        top: 5px;
                        white-space: nowrap;
                    }

                    .multiple-users-instance {
                        left: 0 !important;
                        position: absolute !important;
                        top: 0 !important;
                    }
                }
            }
        }

        .frame-2 {
            align-items: center;
            display: inline-flex;
            gap: 6px;
            left: 10px;
            position: absolute;
            top: 10px;

            .rectangle-2 {
                height: 30px;
                position: relative;
                width: 30px;
                border-radius: 4px;
                border: 0.75px solid #8E8E93
            }

            .text-wrapper-4 {
                color: #000;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: -0.5px;
                line-height: 18px;
                position: relative;
                white-space: nowrap;
                width: fit-content;
            }
        }
    }
}