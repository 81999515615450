$tablet: 990px;
$small: 650px;

.feeds-tabs {
  display: flex;
  margin-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: solid 1px #212225;
  gap: 15px;
  flex-wrap: wrap;

  li {
    display: flex;
    border-radius: 50px;
    border: 1px solid #8e8e93;
    padding: 8px 16px 8px 8px;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;

    &.active {
      border: 1px solid #387aff;
      background: #387aff;

      svg {
        rect {
          fill: #fff;
        }
      }
    }
  }
}
.latest {
  position: sticky;
  top: 8%;
  z-index: 2;
  text-align: center;
  span {
    background-color: #387aff;
    padding: 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #f5f5f5;
    border-radius: 10px;
    cursor: pointer;
  }
}
.feed-block {
  // padding-bottom: 30px;
  margin: 20px 55px 30px 0;
  // border-bottom: #323334 solid 1px;
  // padding-top: 20px;
  position: relative;

  .wrapper-data {
    display: flex;
    align-items: center;
    position: relative;
    // padding: 12px 0;

    .car-image {
      width: 26px;
      height: 26px;
    }

    .username-liked {
      color: #8e8e93;
      font-size: 12px;
      font-weight: 700;
    }

    .liked-in-page {
      display: flex;
      align-items: center;
    }
  }

  .dropdown-menu-feed {
    background-color: #18191a;
    position: absolute;
    // inset: 0px auto auto 0px;
    margin: unset;
    // transform: translate(652px, 72px);
    z-index: 1;
    background-color: #18191a;
    width: 400px;
    height: auto;
    border-radius: 10px;
    padding: 20px;
    row-gap: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    right: 0;
    top: 7%;
  }

  .feed-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-device-width: $small) {
      padding: 10px 15px;
    }

    .header-btn {
      display: flex;
      align-items: center;
      gap: 15px;

      .drop-wrapper {
        .three-dots {
          background-color: transparent;
          padding: 0;
          outline: none;
          border: none;

          &::after {
            display: none;
          }
        }

        .wrapp-list {
          background-color: #18191a;
          border-radius: 10px;

          .dropdown-item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            .dropdown-label {
              font-weight: 400;
              font-size: 14px;
              color: #f5f5f5;
            }
          }
        }
      }

      .default-btn {
        background: #121313;
        color: #fff;
        display: inline-block;
        border-radius: 8px;
        padding: 8px 20px;
        text-decoration: none;
        border: #f5f5f5 solid 1px;
        font-size: 14px;
        font-weight: 500;
        font-family: Inter;
      }
    }
  }

  .username {
    display: flex;
    align-items: center;
    gap: 10px;

    .avtar-in {
      align-items: center;
      display: flex;
      gap: 10px;
      margin-bottom: 15px;

      .profile-link {

        // flex: 0 0 60px;
        .car-image {
          position: relative;

          .user-image {
            position: absolute;
            // right: 5px;
            left: 18px;
            top: 0;
          }
        }
      }

      .name {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }

      .d-flex {
        color: #8e8e93;
      }
    }

    .carname {
      position: relative;
      margin-left: -30px;
    }

    img {
      border: 1px solid #898a8d;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      object-fit: cover;
    }

    h5 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0;
      padding-bottom: 3px;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 2px;

      li:first-child {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
      }

      li:not(:first-child) {
        border-radius: 1px;
        background: #1d9e26;
        width: 10px;
        height: 10px;
      }

      li:nth-child(3) {
        background-color: #61d62d;
      }

      li:nth-child(4) {
        background-color: #87db28;
      }

      li:nth-child(5) {
        background-color: #aedf21;
      }

      li:nth-child(6) {
        background-color: #dae61e;
      }

      li:nth-child(7) {
        background-color: #f8e101;
      }

      li:nth-child(8) {
        background-color: #ffc901;
      }
    }
  }

  .feed-img {
    width: 100%;
    margin: 15px 0px;
  }

  .post-submission-info-2 {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      position: relative;
      color: #8e8e93;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      gap: 5px;
      align-items: center;

      &::after {
        background: #8e8e93;
        width: 4px;
        height: 4px;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        content: "";
        position: absolute;
      }

      &:first-child {
        color: #387aff;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .post-submission-info {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      position: relative;
      color: #8e8e93;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      gap: 5px;
      align-items: center;

      &::after {
        background: #8e8e93;
        width: 4px;
        height: 4px;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        content: "";
        position: absolute;
      }

      &:first-child {
        color: var(--greenNotify);
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .actions-btns {
    margin: 15px 0px;
  }

  .spotlight {
    margin-bottom: 25px;

    .spotlight-header {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-bottom: 20px;
    }

    .spotlight-items {

      // gap: 20px;
      .spotlight-item {
        padding: 0px 5px !important;
        position: relative;
        // padding-right: 10px;
        cursor: pointer;

        .thumbnail {
          border-radius: 10px;
          width: 130px;
          width: 100%;
          height: 230px;
        }

        .user {
          width: 100%;
          gap: 5px;
          position: absolute;
          display: flex;
          align-items: center;
          padding-left: 5px;
          bottom: 10px;

          img {
            width: 25px;
            border-radius: 50%;
          }

          p {
            font-size: 11px;
          }
        }
      }
    }
  }
}

.show-more {
  max-width: "100%";
  word-break: break-all;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 137.5% */
  color: var(--themeWhite);

  p {
    span {
      color: #387aff;
      cursor: pointer;
    }
  }
}

.show-more-title-post {
  max-width: "100%";
  word-break: break-all;
  color: var(--themeWhite);

  p {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;

    span {
      color: #387aff;
      cursor: pointer;
    }
  }
}

.show-more-title {
  max-width: "100%";
  word-break: break-all;

  p {
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    color: var(--themeWhite);

    @media only screen and (max-device-width: $tablet) {
      font-size: 14px;
    }

    span {
      color: #387aff;
      cursor: pointer;
    }
  }
}

.slick-slider {
  button {
    // position: absolute;
    z-index: 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px 10px;
  }

  .slick-next,
  .slick-prev {
    border-radius: 50px;
    background: rgba(42, 43, 46, 0.575);
    width: 50px;
    height: 50px;
    font-size: 32px;
  }
}

.borderforAll {
  margin-top: 10px;

  // border: 1px solid var(--grey);
  .carousel-root {
    .carousel {
      .thumbs-wrapper {
        display: none;
      }
    }
  }

  .swiper {

    // height: 100%;
    .swiper-slide {
      // min-height: 700px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper-button-next,
    .swiper-button-prev {
      padding: 0px 10px;
      color: #fff;
      background-color: rgba(42, 43, 46, 0.575);
      width: 50px;
      height: 50px;
      border-radius: 50%;

      &:hover {
        background-color: transparent; // Use a different background color, or...
        // background-color: transparent; // To make it transparent
      }
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
      font-size: large;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      display: none !important;
    }

    .swiper-pagination-bullets {
      //   transition: opacity .25s ease-in;
      //   opacity: .3;
      //   filter: alpha(opacity=30);
      //   box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      //   background: #fff;
      //   border-radius: 50%;
      //   width: 8px;
      //   height: 8px;
      //   cursor: pointer;
      //   display: inline-block;
      //   margin: 0 8px;
      bottom: 22px;
      color: #fff;
    }

    .swiper-pagination-bullet {
      background-color: #fff;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background-color: #387aff;
    }
  }
}

.vid-img-how {
  max-height: 725px;
  width: 100%;
  // height: auto;
  object-fit: cover;
  --ratio: 4/3;
  aspect-ratio: var(--ratio);
}

video {
  // max-height: 725px;
  // width: 100%;
  // height: auto;
  // --ratio: 16/9;
  // aspect-ratio: var(--ratio);
}

.story-container {
  padding: 0px 0px 15px 15px;
  width: 100%;
  max-width: 700px;
  height: 262px;
  // height: 177px;
  margin-top: 30px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: flex-start;
  scrollbar-width: none;
  border-bottom: 1px solid #323334;
}

.story-container::-webkit-scrollbar {
  display: none;
}

.story-div {
  min-width: 147px;
  // height: 157px;
  height: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 14px;
  padding-bottom: 20px;
}

.story-media {
  // width: 100%;
  // height: 262px;
  // border-radius: 12px;
  overflow: hidden;
  // background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 12px;

  @media only screen and (max-device-width: $small) {
    border-radius: 12px;
  }

  img {
    max-width: 100%;
    width: 147px;
    object-fit: cover;
    height: 262px;
  }
}

// .user-profile-pic {
//     width: 34px;
//     height: 38px;
//     border-radius: 25px;
//     border: 2px solid;
//     margin-top: -4px;
//     overflow: hidden;
//     position: relative;
//     top: -15px;
//     background-image: linear-gradient(to top, #387AFF 33%, #E56424 67%);

//     img {
//         object-fit: cover;
//     }
// }

.user-profile-pic {
  width: 34px;
  height: 38px;
  border-radius: 50%;
  margin-top: -4px;
  overflow: hidden;
  position: relative;
  top: -15px;
  padding: 1px;

  // background: linear-gradient(to top right, #387aff, #e56424);
  border: 1px solid transparent;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    background-color: #fff;
  }
}

.user-name {
  width: 100%;
  height: 18px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  text-align: center;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  top: -13px;
}

@media (max-width: 600px) {
  .story-div {
    min-width: 80px;
  }

  .user-profile-pic,
  .user-name {
    top: -10px;
  }
}

@media (max-width: 400px) {
  .story-div {
    min-width: 60px;
  }

  .story-media {
    height: auto;
  }

  // .user-profile-pic {
  //     width: 25px;
  //     height: 25px;
  // }

  .user-name {
    font-size: 10px;
    top: -8px;
  }
}

@media (max-width: 1100px) {
  .spotlight {
    p {
      font-size: 9px !important;
    }

    .spotlight-items {
      .spotlight-item {
        .user {
          img {
            width: 20px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .spotlight {
    p {
      font-size: 9px !important;
    }
  }
}

@media (max-width: 750px) {
  .spotlight {
    .spotlight-header {
      padding-left: 10px;
    }

    padding: 15px;
  }
}

//   Uncomment when work on reels
// .popup {
//     position: absolute;
//     top: 111px;
//     padding-top: 20px;
//     left: 191px;
//     width: 85%;
//     background-color: rgb(6, 5, 5);
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     z-index: 1000;
// }

// .popup-content {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     width: 410px;
//     overflow-y: auto;
// }

// .story-content {
//     width: 100%;
//     height: 500px;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: center;
//     margin-bottom: 30px;
//     background: #fff;
//     border-radius: 12px;
//     overflow: hidden;
// }

// .story-header {
//     width: 100%;
//     height: 55px;
//     display: flex;
//     align-items: center;
//     padding: 10px 10px;
//     background-color: gray;
//     box-sizing: border-box;
//     position: relative;
//     top: 20px;
//     z-index: 999;
// }

// .header-profile-backPic {
//     width: 35px;
//     height: 35px;
//     border-radius: 50%;
//     border: 1px solid black;
// }

// .header-profile-pic {
//     width: 35px;
//     height: 35px;
//     border-radius: 50%;
//     margin-right: 10px;
//     position: relative;
//     left: -25px;
//     border: 1px solid black;
// }

// .header-username {
//     color: white;
//     margin-right: 15px;
//     position: relative;
//     left: -25px;
// }

// .header-follow-btn {
//     background-color: transparent;
//     color: white;
//     border: 1px solid white;
//     border-radius: 5px;
//     cursor: pointer;
//     position: relative;
//     left: -25px;
//     width: 70px;
//     height: 25px;
// }

// .story-body {
//     width: 100%;
//     height: 100%;
//     position: relative;
//     top: -55px;
// }

// .close-btn {
//     position: fixed;
//     top: 124px;
//     z-index: 99999;
//     right: 252px;
//     width: 30px;
//     height: 30px;
//     background: black;
//     border: none;
//     font-size: 24px;
//     color: white;
//     cursor: pointer;
// }

// @media screen and (max-width: 1176px) {
//     .close-btn {
//         right: 190px;
//     }
// }

// @media screen and (max-width: 1080px) {
//     .close-btn {
//         right: 130px;
//     }
// }

// @media screen and (max-width: 986px) {
//     .popup {
//         padding-top: 50px;
//         width: 60%;
//         left: 100px;
//     }

//     .popup-content {
//         width: 60%;
//     }

//     .close-btn {
//         right: 310px;
//         top: 150px;
//     }
// }

// @media screen and (max-width: 865px) {
//     .popup {
//         left: 50px;
//     }
// }

/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
//     video {
//         width: 500;
//     }
// }

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//     video {
//         width: 500;
//     }
// }

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
//     video {
//         width: 500;
//     }
// }

// /* Large devices (laptops/desktops, 992px and up) */
// // @media only screen and (min-width: 992px) {
// //     video {
// //         width: 500;
// //     }
// // }
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 599px) {
  .feed-block {
    padding-bottom: 15px;
    padding: 15px 0;
    margin: unset;

    .dropdown-menu-feed {
      top: 10%;
      right: 3%;
      // inset: -20px auto auto 0px;
      // transform: translate(250px, 72px);
      width: 170px;

      .dropdown-item {
        font-size: smaller;
      }
    }
  }

  .show-more-title-post {
    p {
      font-size: smaller;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 600px) and (max-width: 991px) {
  .feed-block {
    padding-bottom: 15px;

    .dropdown-menu-feed {
      top: 10%;
      right: 0;
      width: 170px;

      .dropdown-item {
        font-size: 15px;
      }
    }
  }

  .show-more-title-post {
    p {
      font-size: 18px;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1665) {
  video {
    width: 700;
  }
}