.login-wrapper {
  min-height: 100vh;
  // background: url(@assets/authImgs/login-bg.jpg) no-repeat center center;
  background-size: cover;

  &::before {
    content: "";
    left: 0;
    opacity: 0.3;
    top: 0;
    width: 100%;
    // background: linear-gradient(180deg,
    //         rgba(4, 5, 6, 0.6) 0%,
    //         rgba(4, 5, 6, 0.8) 57.81%,
    //         #040506 91.67%),
    //     lightgray 50% / cover no-repeat;
    height: 100%;
  }

  .wrapper-parent {
    display: flex;
    background-color: var(--background);
    height: 600px;
    width: 700px;
    flex-direction: row;

    a {
      text-align: right;
    }

    a > svg {
      margin: 20px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 40px;

      .reset-sec {
        color: var(--themewhite);
        font-size: 30px;
        font-style: normal;
        font-weight: 300px;
        line-height: 35px;
        margin-bottom: 10px;
      }
    }
  }

  .find-text {
    color: #8e8e93;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }

  .logo {
    margin-bottom: 30px;
  }
  .login-info {
    label {
      width: 100%;
      text-align: start;
      font-family: Inter;
      font-size: 15px;
      color: #f5f5f5;
      //   padding-left: 16px;
    }
  }

  .form-control {
    background: #323334;
    height: 44px;
    border: none;
    color: #fff;
    padding: 12px 0;
    align-items: flex-start;
    &:focus {
      border: none;
      border-color: transparent;
      box-shadow: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
    &[data-autocompleted] {
        background-color: transparent !important;
    }
  }

  .btn-pass {
    text-align: end;
    margin: 2% 0;
    .btn-forgot {
      background-color: transparent;
      border: none;
      height: 0px;
      text-align: end;
    }

    .forgot {
      display: flex;
      justify-content: end;
      margin-top: 10px;
      color: #387aff;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      cursor: pointer;
      margin-bottom: 30px;
    }
  }

  .login-btn {
    width: 100%;
    display: block;
    background: #387aff;
    border-radius: 10px;
    line-height: 45px;
    border: none;
    color: #fff;
    transition: all ease 0.4s;
    border: none;
    cursor: pointer;

    // &:hover {
    //     background: #8e8e93;
    //     color: #fff;
    // }

    .login-btn:disabled {
      background: #8e8e93;
      cursor: not-allowed;
    }
  }
}

.error-message {
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  //   padding-left: 16px;
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

/* Style the password requirements list */
.password-requirements {
  list-style: none;
  padding: 0;
  display: inline;
  margin: 0;
}

/* Style each individual requirement item */
.password-requirements li {
  display: inline-table;
  width: 50%;
  text-align: left;
  font-size: 14px;
  color: var(--icon);
}

/* Add custom bullet points using pseudo-elements */
.password-requirements li::before {
  content: "\25CF";
  /* Use a solid bullet point (●) */
  font-size: 14px;
  /* Adjust the size of the bullet point as needed */
  margin-right: 5px;
  /* Add a small space between the bullet point and text */
  color: var(--green);
  /* Green color for fulfilled requirements */
}

/* Style the unfulfilled requirements with a different color */
.password-requirements li:not(.fulfilled)::before {
  color: var(--icon);
  /* Red color for unfulfilled requirements */
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 3;
  /* Add a higher z-index value */
}
