$small: 599px;
$medium: 600px;
/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
$landscapetab: 801px;

/* tablet, landscape iPad, lo-res laptops ands desktops */
.banner-header {
  .wrap-banner {
    position: relative;

    img {
      width: 1280px;
      height: 453px;
      object-fit: cover;
    }

    .section {
      width: 95%;
      background-color: #18191a;
      border-radius: 10px;
      padding: 40px;
      position: absolute;
      bottom: -35%;
      left: 25px;

      @media only screen and (max-device-width: $small) {
        padding: 10px;
        left: 0;
        width: 100%;
        bottom: -11%;
      }

      @media (min-device-width: $medium) and (max-device-width: $landscapetab) {
        padding: 10px;
        left: 0;
        width: 100%;
        bottom: -11%;
      }

      .wrapSection {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .weekend {
          display: flex;
          justify-content: space-between;
          @media only screen and (max-device-width: $small) {
            display: block;
          }

          .meetup {
            // width: 70%;
            .wrap-btn {
              display: flex;
              justify-content: space-between;
              h4 {
                font-size: 24px;
                font-weight: 300;
                color: #ffffff;
                margin-bottom: unset;

                @media only screen and (max-device-width: $small) {
                  font-size: 18px;
                }
              }

              p {
                font-size: 16px;
                font-weight: 300;
                color: #e56424;

                @media only screen and (max-device-width: $small) {
                  font-size: 13px;
                }
              }
              .month {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .event-month {
                  float: right;
                  border: 1px solid #8e8e93;
                  padding: 20px 30px;
                  border-radius: 8px;

                  @media only screen and (max-device-width: $small) {
                    padding: 5px 20px;
                  }

                  p {
                    text-align: center;
                    color: #f5f5f5;
                    @media only screen and (max-device-width: $small) {
                      font-size: 10px;
                    }
                  }

                  h3 {
                    text-align: center;

                    @media only screen and (max-device-width: $small) {
                      font-size: 15px;
                      margin-bottom: unset;
                    }
                  }
                }
              }
            }

            h4 {
              font-size: 24px;
              font-weight: 300;
              color: #ffffff;
              margin-bottom: unset;

              @media only screen and (max-device-width: $small) {
                font-size: 18px;
              }
            }

            p {
              font-size: 16px;
              font-weight: 300;
              color: #e56424;

              @media only screen and (max-device-width: $small) {
                font-size: 13px;
              }
            }

            .locationInfo {
              display: flex;
              align-items: center;
              gap: 30px;
              margin-top: 20px;

              @media only screen and (max-device-width: $small) {
                margin-top: 10px;
              }

              .clm {
                display: flex;
                align-items: center;
                gap: 8px;

                span {
                  @media only screen and (max-device-width: $small) {
                    font-size: 10px;
                  }
                }

                .icon {
                  padding: 3px 8px;
                  border-radius: 2px;
                  border: 1px solid #323334;

                  @media only screen and (max-device-width: $small) {
                    padding: 1px 3px;
                  }
                }

                .multi-location {
                  .drop-menu-event{
                   padding: 10px;
                  }
                  .list-flex {
                    display: flex;
                    align-items: flex-end;
                    // .custom-item {
                    //   padding-left: 5px;
                    // }
                  }
                  .imageIcon {
                    min-width: 30px;
                    max-width: 30px;
                    height: 30px;
                    border: 1px solid #387aff;
                    border-radius: 50%;
                    padding: 3px 5px;
                    text-align: center;
                    color: #387aff !important;
                  }
                  img {
                    width: 30px;
                    height: 30px;
                  }
                }
                .accordion {
                  width: 300px;
                  max-width: 300px;

                  .accordion-item {
                    background-color: transparent;
                    padding-bottom: 10px;
                    .accordion-header {
                      border-radius: 10px;
                      background-color: #212225;
                      display: flex;
                      align-items: center;
                      gap: 10px;
                      padding: 10px;
                      img {
                        width: 40px;
                        height: 40px;
                        // width: 30%;
                      }
                      p {
                        color: white;
                        font-size: small;
                        width: 100%;
                      }
                    }
                    .accordion-button {
                      background-color: #212225;
                      height: 30px;
                      gap: 10px;
                      width: 24px;
                    }
                    .accordion-button.collapsed::after {
                      background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
                      transform: rotate(0deg);
                    }
                    .accordion-button:not(.collapsed) {
                      box-shadow: none;
                    }

                    .accordion-button::after {
                      background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-chevron-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 11.354a.5.5 0 0 1 .708 0L8 5.707l5.646 5.647a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1-.708 0l-6 6a.5.5 0 0 1 0 .708z"/></svg>');
                      transform: rotate(180deg);
                    }
                    .accordion-button:focus {
                      box-shadow: none;
                    }

                    .accordion-body {
                      display: flex;
                      gap: 10px;
                      padding: 0px 10px;
                      .imageIcon {
                        min-width: 30px;
                        max-width: 30px;
                        height: 30px;
                        border: 1px solid #387aff;
                        border-radius: 50%;
                        padding: 5px;
                        text-align: center;
                        color: #387aff !important;
                      }
                      p {
                        width: 100%;
                        color: #ffffff !important;
                        border-bottom: 3px solid #212225;
                        padding: 4px;
                        font-size: small;
                        align-self: end;
                      }
                    }
                  }
                }
                .accordion:focus {
                  background-color: transparent;
                  border: none;
                }
              }
            }

            .description {
              margin: 20px 0;

              p {
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }

          .month {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .event-month {
              float: right;
              border: 1px solid #8e8e93;
              padding: 20px 30px;
              border-radius: 8px;

              @media only screen and (max-device-width: $small) {
                padding: 5px 20px;
              }

              p {
                text-align: center;

                @media only screen and (max-device-width: $small) {
                  font-size: 10px;
                }
              }

              h3 {
                text-align: center;

                @media only screen and (max-device-width: $small) {
                  font-size: 15px;
                  margin-bottom: unset;
                }
              }
            }
          }
        }

        .last-section {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .button-check {
            button {
              padding: 5px 40px;
              border-radius: 10px;
              color: #f5f5f5;
              font-size: 16px;
              font-weight: 500;

              // width: 200px;
              // height: 40px;
              @media only screen and (max-device-width: $small) {
                font-size: smaller;
              }
            }
          }

          .attending {
            cursor: pointer;

            p {
              font-size: 16px;
              font-weight: 500;
              color: #8e8e93;
              text-align: end;

              @media only screen and (max-device-width: $small) {
                font-size: 12px;
              }
            }

            .attending-avatar {
              display: flex;

              img {
                width: 40px;
                height: 40px;
                overflow: hidden;
                margin-left: -15px;
                border-radius: 25px;

                @media only screen and (max-device-width: $small) {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
}
